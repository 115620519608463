import moment from 'moment-timezone';
import Swal from 'sweetalert2';

function getFormattedDate(date) {
  try {
    let formattedDate = date;
    if (formattedDate) {
      formattedDate = moment(formattedDate).tz("America/New_York").format("YYYY-MM-DD HH:mm:ss");
    }
    return formattedDate;
  } catch (error) {
    console.log(error);
  }
}

function redirectUrl(roles) {
  try {
    let url = '/';
    if (roles?.length > 1) {
      url = '/approval-queue/';
    }
    return url;
  } catch (error) {
    console.log(error);
  }
}

const cancelRequest = async (requestData) => {
  const reqId = requestData.requestId;
  const formName = requestData.formName;
  if (reqId != null && reqId != "") {
    return Swal.fire({
      html: `Are you sure you want to cancel ${formName} Request ${reqId}?`,
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: "No"
    }).then(async (result) => {
      if (result.isConfirmed) {
        let callUrl = process.env.REACT_APP_ESF_TERMINATION;
        let body = {}
        switch (formName) {
          case 'Add Program':
            callUrl = process.env.REACT_APP_ESF_ADDPROGRAM;
            body.AddProgramData = requestData;
            break;
          case 'Compensation Change':
            callUrl = process.env.REACT_APP_ESF_COMPENSATIONCHANGE;
            body.CompensationChangeData = requestData;
            break;
          case 'Transfer':
            callUrl = process.env.REACT_APP_ESF_TRASFER;
            body.TransferData = requestData;
            break;
          case 'Position Change':
            callUrl = process.env.REACT_APP_ESF_POSITIONCHANGE;

            body.PositionChangeData = requestData;
            break;
          case 'Supervisor Change':
            callUrl = process.env.REACT_APP_ESF_SUPERVISORCHANGE;
            body.SupervisorChangeData = requestData;
            break;
          default:
            body.TerminationData = requestData;
            break;
        }
        body.Method = "CANCEL";
        body.requestID = reqId ? reqId : "";
        const response = await fetch(callUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        });
        return await response.json();
      }
    });
  }
};

export { getFormattedDate, redirectUrl, cancelRequest };
