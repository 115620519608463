import React from "react";
import "./styles.css";
import HomeIcon from "../../assets/images/home.svg";
import { Link } from "react-router-dom";
import Logo from "../Logo";
import Nav from "../Nav";

const Header = () => {
  return (
    <header style={{
      gridTemplateColumns: '35px 85% 65px 25px'
    }}>
      <Link to="/">
        <img className="homeIcon" src={HomeIcon} alt="Home" />
      </Link>
      <Logo />
      <a href="/" class="logoContainer"><span>ESF 3.0</span></a>
      <Nav />
    </header>
  );
};

export default Header;
