import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {  postApi } from "../../utils/fetchApi";
import Tabs from "../../components/Tabs";
import "./styles.css";
import Grid from "../../components/Grid";
import { approvalQueues } from "./constants";
import { userRoles } from "../../utils/constants";

const ApprovalQueue = () => {
  const navigate = useNavigate();
  const { userRole, form } = useParams();
  const [tabHeaders, setTabHeaders] = useState(approvalQueues[userRole].forms);
  const [tabData, setTabData] = useState(
    approvalQueues[userRole].forms.find(
      (filteredForm) => filteredForm.name === form
    )
  );
  const [currentForm, setCurrentForm] = useState(form);
  const [approvalQueue, setApprovalQueue] = useState(null);

  useEffect(() => {
    setTabHeaders(approvalQueues[userRole].forms);
    if (!form) {
      goToForm();
    } else {
    }
  }, [userRole]);

  useEffect(() => {
    if (form) {
      refreshTabData();
    }
  }, [form]);

  const handleErrorFetchingData = () => {
    const updatedTabData = { ...tabData };
    updatedTabData.content = <span>No record found</span>;
    setTabData(updatedTabData);
  };

  const goToForm = () => {
    navigate(
      `/approval-queue/${userRole}/${approvalQueues[userRole].forms[0].name}`
    );
  };

  const refreshTabData = () => {
    const updatedTabData = {
      ...approvalQueues[userRole].forms.find(
        (filteredForm) => filteredForm.name === form
      ),
    };
    updatedTabData.content = <span>loading</span>;
    setTabData(updatedTabData);
    setCurrentForm(null);
  };

  useEffect(() => {
    if (!currentForm) {
      setCurrentForm(form);
    } else {
      const fetchData = async () => {
        try {
          let finalRole = userRole;
          if (userRole === userRoles.accounting || userRole === userRoles.finance) {
            const userAccess = JSON.parse(localStorage.getItem("UserAccess"));
            if (userAccess && userAccess.roles?.length > 0) {
              finalRole = userAccess.roles.map((role) => {
                return role.name;
              });
              finalRole = finalRole.join(";");
            }
          }
          const response = await postApi(
            process.env.REACT_APP_ESF_APPROVAL_QUEUE,
            {
              formName: currentForm,
              requestStatus: approvalQueues[userRole].requestStatus,
              userRole: finalRole,
              submitterEmail: ''
            }
          );
          const data = await response;
          if (!data.ResultSets || data.ResultSets.Table1.length === 0) {
            handleErrorFetchingData();
            return;
          }
          setApprovalQueue(data.ResultSets.Table1);
        } catch (error) {
          handleErrorFetchingData();
          console.error("There was a problem with the fetch operation:", error);
        }
      };

      fetchData();
    }
  }, [currentForm]);

  useEffect(() => {
    if (approvalQueue) {
      const updatedTabData = { ...tabData };
      updatedTabData.content = (
        <Grid rowData={approvalQueue} form={tabData.url}></Grid>
      );
      setTabData({ ...updatedTabData });
    }
  }, [approvalQueue]);

  return (
    <div>
      {tabData?.content && (
        <Tabs data={tabData} tabHeaders={tabHeaders} userRole={userRole} />
      )}
    </div>
  );
};

export default ApprovalQueue;
