import React, { useState, useEffect, useCallback } from "react";
import "./styles.css";

const Toggle = ({ label, name, value, onChange, onText, offText }) => {
  const [isChecked, setIsChecked] = useState(value);

  useEffect(() => {
    setIsChecked((prevState) => {
      if (prevState !== value) {
        return value;
      }
      return prevState;
    });
  }, [value]);

  useEffect(() => {
    if (onChange) {
      onChange(isChecked);
    }
  }, [isChecked]);

  const handleClick = useCallback(() => {
    setIsChecked(!isChecked);
  }, [isChecked]);

  return (
    <div className="toggle-container">
      <label htmlFor={name}>{label}</label>
      <div
        className={`toggle ${isChecked ? "on" : "off"}`}
        onClick={handleClick}
        role="button"
        tabIndex="0"
        aria-pressed={isChecked}
      >
        <div className="toggle-circle" />
        <span className="toggle-on-text">{onText}</span>
        <span className="toggle-off-text">{offText}</span>
      </div>
      <input
        id={name}
        name={name}
        type="checkbox"
        className="visually-hidden"
        checked={isChecked}
        onChange={handleClick}
      />
    </div>
  );
};

export default Toggle;
