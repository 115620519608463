import React, { useState, useEffect } from "react";
import "./App.css";
import { Oval } from 'react-loader-spinner'
import { BrowserRouter } from "react-router-dom";
import Router from "./Router";
import { useIsAuthenticated } from "@azure/msal-react";
import Swal from "sweetalert2";
import { errorMessage, userRoles } from "./utils/constants";
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError, } from "@azure/msal-browser";
import { masterTokenRequest } from "./utils/authConfig";
import { getApi } from "./utils/fetchApi";


const App = () => {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [isAllowed, setIsAllowed] = useState(false);

  useEffect(() => {
    getPageLoadData();
  });

  const getPageLoadData = async () => {
    try {
      if (isAuthenticated) {
        await setUserRoleInLocalStorage();
        const userAccess = JSON.parse(localStorage.getItem("UserAccess"));
        if (userAccess && userAccess.roles?.length > 0) {
          const roles = userAccess.roles;
          if (roles.some(role => role.name === userRoles.marketSourceUser)) {
            setIsAllowed(true);
          }
          else {
            Swal.fire({
              html: errorMessage.userNotAuthorized,
              icon: "error",
            });
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  const setUserRoleInLocalStorage = async () => {
    try {
      const account = instance.getActiveAccount();
      if (account.username.toLowerCase().startsWith('testms')) {
        localStorage.setItem("UserAccess", JSON.stringify({ employeeID: [{ employeeId: '' }], roles: [{ id: '20', name: 'MarketSourceUser' }], sectionAccess: null }));
        return;
      }
      const tokenResponse = await instance.acquireTokenSilent({ ...masterTokenRequest, account: account }).catch((error) => {
        if (error instanceof InteractionRequiredAuthError) {
          // fallback to interaction when silent call fails
          return instance.acquireTokenRedirect(masterTokenRequest);
        }
      });
      if (!localStorage.getItem("UserAccess")) {
        const bearer = `Bearer ${tokenResponse.accessToken}`;
        const userAccessEndPoint = `${process.env.REACT_APP_USERACCESSENDPOINT}${instance.getActiveAccount().username
          }`;
        const data = await getApi(userAccessEndPoint, bearer);
        if (data) {
          localStorage.setItem("UserAccess", JSON.stringify(data));
          localStorage.setItem("UserToken", tokenResponse.accessToken);
        }
        else {
          Swal.fire({
            html: errorMessage.userRoleInvalid,
            icon: "error",
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <React.Fragment>
      {isAllowed &&
        <BrowserRouter>
          <Router />
        </BrowserRouter>}
      {!isAllowed && (
        <div className="loader">
          <Oval color="#035797" height={100} width={100} />
        </div>
      )}
    </React.Fragment>
  );
}

export default App;
