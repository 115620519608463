import React from "react";
import "./styles.css";
import { Link } from "react-router-dom";

const Tile = ({ title, color, url, icon }) => {
  return (
    <Link className="tile" to={url}>
      <section style={{ backgroundColor: color }}>
        <img src={icon} alt="" />
        <h1>{title}</h1>
      </section>
    </Link>
  );
};

export default Tile;
