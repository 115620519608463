import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import Title from "../../components/Title";
import Note from "../../components/Note";
import EmployeeSearch from "../../components/EmployeeSearch";
import {
  approvalOptions,
  approvalSectionTitle,
  bgColorsTitle,
  compensationType,
  employeeCompanyLocations,
  employeeSearchText,
  formRoutes,
  requestStatus,
  userRoles,
  workflowHistory,
} from "../../utils/constants";
import EsfInput from "../../components/EsfInput";
import User from "../../components/User";
import Toggle from "../../components/Toggle";
import SubmitButton from "../../components/SubmitButton";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import {
  getTemporaryEmpId,
  postApi,
  postValuesApi, getImpersonatedUserRoles
} from "../../utils/fetchApi";
import { uniqBy } from "lodash";
import {
  getEmployee,
  getEmployeesFromSearch,
  getJobTitle,
  getReportees,
} from "../../utils/employeeService";
import Grid from "../../components/Grid";
import { Oval } from "react-loader-spinner";
import { cancelRequest, getFormattedDate, redirectUrl } from "../../utils/globalUtils";
import { appInsights } from "../../components/AppInsights";
import LoginAs from "../LoginAs";

const PositionChange = ({ title, note }) => {
  const [optionsActionType, setOptionsActionType] = useState([
    { key: "Choose an Action", text: "Choose an Action" },
  ]);
  const [optionsActionReason, setOptionsActionReason] = useState([
    { key: "Choose Reason", text: "Choose Reason" }
  ]);
  const [optionsEmpLocation, setOptionsEmpLocation] = useState([
    { key: "Choose a Location", text: "Choose a Location" }
  ]);
  const [optionsJobTitle, setOptionsJobTitle] = useState([
    { key: "Choose Job Title", text: "Choose Job Title" },
  ]);
  const [pageLoader, setPageLoader] = useState(false);
  const [gridRows, setGridRows] = useState([]);
  const [gridColumns, setGridColumns] = useState([]);
  const [allOptionsActionReason, setAllOptionsActionReason] = useState([]);
  const [showPayrateError, setShowPayrateError] = useState(false);
  const [showBonusPotentialError, setShowBonusPotentialError] = useState(false);
  const [disableSection, setDisableSection] = useState({
    commercialHR: true,
    billableHR:true,
    finance: true,
    accounting: true,
    esg: true,
    payroll: true,
  });
  const { instance } = useMsal();
  const { requestId } = useParams();
  const navigate = useNavigate();
  const previousController = useRef();
  const [loadingSupervisor, setLoadingSupervisor] = useState(false);
  const [isCancelVisible, setIsCancelVisible] = useState(false);
  const [employeeData, setEmployeeData] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(undefined);
  const [searchQuery, setSearchQuery] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const [supervisorData, setSupervisorData] = useState({
    searchResults: [],
    selectedSupervisor: null,
  });

  const [user, setUser] = useState({
    userName: "",
    userEmail: "",
    roles: ""
  });

  const [showBonusPotential, setShowBonusPotential] = useState(false);
  const [showTotalPercentChange, setShowTotalPercentChange] = useState(false);
  const [showCommercialHRSection, setShowCommercialHRSection] = useState(false);
  const [showBillableHRSection, setShowBillableHRSection] = useState(false);
  const [showAccountingSection, setShowAccountingSection] = useState(false);
  const [showFinanceSection, setShowFinanceSection] = useState(false);
  const [showESGSection, setShowESGSection] = useState(false);
  const [showPayrollSection, setShowPayrollSection] = useState(false);

  const [state, setState] = useState({
    requestStatus: "",
    effectiveDate: null,
    dateSubmitted: null,
    newJobTitle: "",
    newLocation: "",
    actionType: "",
    actionReason: "",
    positionChangeReason: "",
    submitterPhone: "",
    submitterEmail: "",
    actionComments: "",
    compensationType: true,
    bonusFrequency: "",
    bonusPotential: "",
    payRate: "",
    businessTitle: "",
    lblSunday: "labelError",
    // Added enhancment changes
    currentAnnualSalary:"",
    currentAnnualVariablePotential:"",
    totalPercentChange:"",
    justificationForPromotion:"",
    alignmentToOtherSimilarEmployees:"",
    // Approval section state variables

    commercialhrAssignedTo: "",
    commercialhrAssignedToEmail: "",
    commercialhrAssignedToEmailDisplay: "",
    commercialhrApproval: "Pending Review",
    commercialhrComments: "",

    billablehrAssignedTo: "",
    billablehrAssignedToEmail: "",
    billablehrAssignedToEmailDisplay: "",
    billablehrApproval: "Pending Review",
    billablehrComments: "",


    accountingAssignedTo: "",
    accountingAssignedToEmail: "",
    accountingAssignedToEmailDisplay: "",
    accountingApproval: "Pending Review",
    accountingComments: "",

    financeAssignedTo: "",
    financeAssignedToEmail: "",
    financeAssignedToEmailDisplay: "",
    financeLevel: "1",
    financeApproval: "Pending Review",
    financeComments: "",
    dateApprovedByAccountingFinance: null,

    esgAssignedTo: "",
    esgAssignedToEmail: "",
    esgAssignedToEmailDisplay: "",
    esgApproval: "Pending Review",
    esgComments: "",
    dateApprovedByESG: null,

    payrollAssignedTo: "",
    payrollAssignedToEmail: "",
    payrollAssignedToEmailDisplay: "",
    payrollApproval: "Pending Review",
    payrollComments: "",

    completedDate: null,
    submitButtonText: (
      <span>
        Please complete all required fields.<br></br>Please choose a Sunday for
        Effective date.
      </span>
    ),
    submitButtonColor: ''
  });
  const optionsBonusFrequency = [
    { key: "Choose Frequency", text: "Choose Frequency" },
    { key: "Annual", text: "Annual" },
    { key: "Quarterly", text: "Quarterly" },
    { key: "Monthly", text: "Monthly" },
    { key: "Weekly", text: "Weekly" },
    { key: "Not Eligible", text: "Not Eligible" },
  ];

  const [loggedInAs, setLoginAs] = useState({ EmpId: '', DisplayName: '', Email: '' });
  const [loading, setLoading] = useState(false);
  useEffect(
    () => {
      getPageLoadData();
    },
    [loggedInAs]
  );
  const [isESFOwner, setIsESFOwner] = useState(false);
  const [LoginAsError, setLoginAsError] = useState(false);
  const [isLoginAsVisible, setIsLoginAsVisible] = useState(false);

  useEffect(() => {
    getPageLoadData();
  }, []);

  useEffect(() => {
    if (!searchQuery || searchQuery.length <= 2) {
      if (previousController.current) {
        previousController.current.abort();
      }
      setSupervisorData({ ...supervisorData, searchResults: [] });
      setLoadingSupervisor(false);
    }
    if (searchQuery?.length === 3) {
      const companyId = employeeCompanyLocations.filter(item => item.text === state.supervisorCompany)[0]?.key;
      if (companyId) {
        getSupervisors(searchQuery, companyId);
      }
    }
  }, [searchQuery]);

  useEffect(() => {
    handleValidation();
  }, [state, selectedEmployee, supervisorData]);

  const getPageLoadData = async () => {
    try {
      
      const start = new Date().getTime();
      const account = instance.getActiveAccount();
      if (account) {
        let employeeId = ""; // Non-83 03806371   BU-83 02507999
        const userAccess = JSON.parse(localStorage.getItem("UserAccess"));
        if (userAccess && userAccess.roles?.length > 0) {
          const roles = userAccess.roles;
          setUser({ ...user, userName: account.name, userEmail: account.username, roles: roles });
          employeeId = userAccess.employeeID[0].employeeId;
          if (getTemporaryEmpId(account.username)) {
            employeeId = getTemporaryEmpId(account.username);
          }
          var isESFOwner = false;
          if (userAccess.roles.some(role => (role.name === userRoles.esfOwners))) { isESFOwner = true; }
          setIsESFOwner(isESFOwner);
          if (loggedInAs && loggedInAs.EmpId) {
            employeeId = loggedInAs.EmpId;
          }

          const result = await getDropdownOptions();
          const actionTypes = result.ResultSets.Table1?.map((val) => {
            return { key: val.ActionType, text: val.ActionType };
          });
          const locations = result.ResultSets.Table2?.map((val) => {
            return { key: val.locationID, text: val.LocationDescription };
          });
          setOptionsActionType([
            ...[{ key: "Choose an Action", text: "Choose an Action" }],
            ...uniqBy(actionTypes, "key"),
          ]);
          setOptionsEmpLocation([...[{ key: "Choose a Location", text: "Choose a Location" }],
          ...locations]);
          setAllOptionsActionReason(result.ResultSets.Table1);

          if (requestId) {
            setPageLoader(true);
            const positionChangeHistory = postApi(
              process.env.REACT_APP_ESF_WORKFLOW_HISTORY,
              {
                formName: "PositionChange",
                requestID: requestId,
              }
            );
            const requestDetails = postApi(
              process.env.REACT_APP_ESF_POSITIONCHANGE,
              {
                Method: "READ",
                requestId,
              }
            );
            Promise.all([positionChangeHistory, requestDetails]).then(
              ([resHistory, resRequestDetails]) => {
                if (
                  !Object.keys(resHistory).includes("error") &&
                  !Object.keys(resRequestDetails).includes("error")
                ) {
                  if (resRequestDetails.ResultSets.Table1?.[0]) {
                    const jsonValue = resRequestDetails.ResultSets.Table1[0];
                    cancelVisibility(roles, jsonValue.requestStatus);
                    setSupervisorInfo(jsonValue.newSupervisorId).then(() => {
                      const historyResult = resHistory.ResultSets.Table1;
                      if (historyResult && historyResult.length > 0) {
                        const headers = Object.keys(historyResult[0]).map((col) => {
                          return {
                            field: col,
                            filter: true,
                            floatingFilter: true,
                            sortable: true,
                          };
                        });
                        setGridRows(historyResult);
                        setGridColumns(headers);
                      }
                      if (
                        jsonValue.actionType &&
                        jsonValue.actionType ==="Promotion"){
                          setShowTotalPercentChange(true);                          
                      }
                      if (
                        jsonValue.bonusPotential &&
                        jsonValue.bonusPotential !== "Not Eligible"
                      ) {
                        setShowBonusPotential(true);
                      }
                      setActionReasonOptions(
                        jsonValue.actionType,
                        result.ResultSets.Table1
                      );
                      setJobTitle(jsonValue.empBusinessUnit);
                      setState({
                        ...state,
                        requestStatus: jsonValue.requestStatus,
                        actionType: jsonValue.actionType,
                        actionReason: jsonValue.actionReason,
                        compensationType:
                          jsonValue.compensationType === "Salary" ? true : false,
                        newJobTitle: jsonValue.newJobTitle,
                        newLocation: jsonValue.newLocation,
                        payRate: jsonValue.payRate,
                        bonusFrequency: jsonValue.bonusFrequency,
                        bonusPotential: jsonValue.bonusPotential,
                        businessTitle: jsonValue.businessTitle,
                        submitterPhone: jsonValue.submitterPhone,
                        submitterName: jsonValue.submitterName,
                        submitterEmail: jsonValue.submitterEmail,
                        actionComments: jsonValue.actionComments,
                        newLocation: jsonValue.newLocation,
                        dateSubmitted: jsonValue.dateSubmitted,
                        // Total Percent Change 
                        currentAnnualSalary: jsonValue.currentAnnualSalary,
                        currentAnnualVariablePotential: jsonValue.currentAnnualVariablePotential,
                        totalPercentChange: jsonValue.totalPercentChange,
                        justificationForPromotion: jsonValue.justificationForPromotion,
                        alignmentToOtherSimilarEmployees: jsonValue.alignmentToOtherSimilarEmployees,

                        // end 
                        effectiveDate: moment(jsonValue.effectiveDate).format(
                          "YYYY-MM-DD"
                        ),
                        dateApprovedByAccountingFinance:
                          jsonValue.dateApprovedByAccountingFinance,
                        dateApprovedByESG: jsonValue.dateApprovedByESG,
                        completedDate: jsonValue.completedDate,
                        lblSunday:
                          moment(jsonValue.effectiveDate).day() === 0
                            ? "labelNormal"
                            : "labelError",
                        submitButtonText:
                          moment(jsonValue.effectiveDate).day() === 0 ? (
                            "Please complete all required fields."
                          ) : (
                            <span>
                              Please complete all required fields.<br></br>Please
                              choose a Sunday for Effective date.
                            </span>
                          ),

                        commercialhrAssignedTo: jsonValue.commercialhrAssignedTo,
                        commercialhrAssignedToEmail:
                          jsonValue.commercialhrAssignedToEmail,
                        commercialhrAssignedToEmailDisplay:
                          jsonValue.commercialhrAssignedToEmail,
                        commercialhrApproval: jsonValue.commercialhrApproval
                          ? jsonValue.commercialhrApproval
                          : state.commercialhrApproval,
                        commercialhrComments: jsonValue.commercialhrComments,
                        // soma
                        billablehrAssignedTo: jsonValue.billablehrAssignedTo,
                        billablehrAssignedToEmail:
                          jsonValue.billablehrAssignedToEmail,
                        billablehrAssignedToEmailDisplay:
                          jsonValue.billablehrAssignedToEmail,
                        billablehrApproval: jsonValue.billablehrApproval
                          ? jsonValue.billablehrApproval
                          : state.billablehrApproval,
                        billablehrComments: jsonValue.billablehrComments,

                        accountingAssignedTo: jsonValue.accountingAssignedTo,
                        accountingAssignedToEmail:
                          jsonValue.accountingAssignedToEmail,
                        accountingAssignedToEmailDisplay:
                          jsonValue.accountingAssignedToEmail,
                        accountingApproval: jsonValue.accountingApproval
                          ? jsonValue.accountingApproval
                          : state.accountingApproval,
                        accountingComments: jsonValue.accountingComments,

                        financeAssignedTo: jsonValue.financeAssignedTo,
                        financeAssignedToEmail: jsonValue.financeAssignedToEmail,
                        financeAssignedToEmailDisplay: jsonValue.financeAssignedToEmail,
                        financeLevel: jsonValue.financeLevel
                          ? jsonValue.financeLevel
                          : state.financeLevel,
                        financeApproval: jsonValue.financeApproval
                          ? jsonValue.financeApproval
                          : state.financeApproval,
                        financeComments: jsonValue.financeComments,

                        esgAssignedTo: jsonValue.esgAssignedTo,
                        esgAssignedToEmail: jsonValue.esgAssignedToEmail,
                        esgAssignedToEmailDisplay: jsonValue.esgAssignedToEmail,
                        esgApproval: jsonValue.esgApproval
                          ? jsonValue.esgApproval
                          : state.esgApproval,
                        esgComments: jsonValue.esgComments,

                        payrollAssignedTo: jsonValue.payrollAssignedTo,
                        payrollAssignedToEmail: jsonValue.payrollAssignedToEmail,
                        payrollAssignedToEmailDisplay: jsonValue.payrollAssignedToEmail,
                        payrollApproval: jsonValue.payrollApproval
                          ? jsonValue.payrollApproval
                          : state.payrollApproval,
                        payrollComments: jsonValue.payrollComments,
                      });
                      setSelectedEmployee({
                        ProductID: jsonValue.empBusinessUnit,
                        EmployeeClass: jsonValue.empClass,
                        CompanyID: jsonValue.empCompany,
                        HireDate: jsonValue.empHireDate,
                        EmployeeID: jsonValue.empId,
                        Name: jsonValue.empName,
                        JobDescription: jsonValue.empPosition,
                        HomeState: jsonValue.empState,
                        SupervisorID: jsonValue.empSupervisor,
                        EmailPreferred: jsonValue.empEmail,
                        OfficeLocationID: jsonValue.empLocation,
                        DepartmentDescription: jsonValue.empProgram.split(" - ")?.[0],
                        DepartmentID: jsonValue.empProgram.split(" - ")?.[1],
                      });

                      if (loggedInAs.EmpId !== jsonValue.loginAsEmpId) {
                        setLoginAs(
                          {
                            EmpId: jsonValue.loginAsEmpId,
                            DisplayName: jsonValue.loggedInAsDisplayName,
                            Email: jsonValue.loggedInAsEmail
                          }
                        );
                      }


                      payRateValidation(jsonValue.compensationType, jsonValue.payRate);
                      bonusPotentialValidation(jsonValue.bonusPotential);
                      enableDisableApprovalSection(jsonValue, roles);
                      showHideApprovalSection(jsonValue);
                      setPageLoader(false);
                    });
                  }

                } else {
                  setPageLoader(false);
                  Swal.fire({
                    title: "Something went wrong...",
                    icon: "error",
                  });
                }
              }
            );
          } else {
            if (employeeId) {
              setPageLoader(true);
              await getEmployeeData(employeeId);
              setPageLoader(false);
            } else {
              setPageLoader(false);
              Swal.fire({
                title: "Employee ID is not valid...",
                icon: "error",
              });
            }
          }
        }
      }
      if (appInsights) {
        appInsights.trackEvent({
          name: `Page Load Position Change`,
          properties: {
            TimeTaken: `${(new Date().getTime() - start) / 1000} s`,
          },
        });
      }

      if (requestId && loggedInAs.EmpId) {
        setIsLoginAsVisible(true);
      }
      else if (isESFOwner && (requestId === null || requestId === undefined || requestId === "")) {
        setIsLoginAsVisible(true);
      }
      else {
        setIsLoginAsVisible(false);
      }

    } catch (error) {
      setPageLoader(false);
      console.error(error);
    }
  }

  const showHideApprovalSection = (jsonValue) => {
    const status = jsonValue.requestStatus;
    const empBU = jsonValue.empBusinessUnit;
    const empClass = jsonValue.empClass;
    const empCompany = jsonValue.empCompany;
    if (status === requestStatus.pendingCommercialHR ||
      jsonValue.commercialhrApproval) {
      setShowCommercialHRSection(true);
    }
    if (status === requestStatus.pendingHr ||
      jsonValue.billablehrApproval) {
      setShowBillableHRSection(true);
    }

    if (status === requestStatus.pendingAccounting ||
      jsonValue.accountingApproval) {
      setShowAccountingSection(true);
    }

    if (status === requestStatus.pendingFinance ||
      jsonValue.financeApproval) {
      setShowFinanceSection(true);
      if (empBU === "83") {
        setShowCommercialHRSection(true);
      }
      else if (empBU !== "83") 
      {
        setShowBillableHRSection(true);
      }
    }

    if (status === requestStatus.pendingESG ||
      jsonValue.esgApproval) {
      setShowESGSection(true);
      if (empClass === "INT") {
        setShowAccountingSection(true);
      } else {
        setShowFinanceSection(true);
        if (empBU === "83") {
          setShowCommercialHRSection(true);
        } else if(empBU !== "83") {
          setShowBillableHRSection(true);
        }
      }
    }

    if (status === requestStatus.pendingPayroll ||
      jsonValue.payrollApproval) {
      setShowPayrollSection(true);
      if (empCompany !== "MSC") {
        setShowESGSection(true);
      }
      if (empClass === "INT") {
        setShowAccountingSection(true);
      } else {
        setShowFinanceSection(true);
        if (empBU === "83") {
          setShowCommercialHRSection(true);
        } else if (empBU !== "83") {
          setShowBillableHRSection(true);
        }
      }
    }
  }

  const enableDisableApprovalSection = (jsonValue, roles) => {
    const status = jsonValue.requestStatus;
    const empClass = jsonValue.empClass;
    const empCompany = jsonValue.empCompany;
    if (status === requestStatus.pendingCommercialHR && (
      roles.some(role => role.name === userRoles.esfOwners) ||
      roles.some((role) => role.name === userRoles.commercialHR))) {
      setDisableSection({ ...disableSection, commercialHR: false });
    } else if (status === requestStatus.pendingHr && (
      roles.some(role => role.name === userRoles.esfOwners) ||
      roles.some((role) => role.name === userRoles.hrBillable)||
      roles.some((role) => role.name === userRoles.hrInternal))) {
      setDisableSection({ ...disableSection, billableHR: false });
    } else if (status === requestStatus.pendingFinance && (
      roles.some(role => role.name === userRoles.esfOwners) ||
      roles.some(role => role.name === userRoles.adminBillable) ||
      roles.some(role => role.name === userRoles.adminInternal) ||
      roles.some((role) => role.name === userRoles.finance))) {
      setDisableSection({ ...disableSection, finance: false });
    } else if (status === requestStatus.pendingAccounting && (
      roles.some(role => role.name === userRoles.esfOwners) ||
      roles.some(role => role.name === userRoles.adminBillable) ||
      roles.some(role => role.name === userRoles.adminInternal) ||
      roles.some((role) => role.name === userRoles.accounting))) {
      setDisableSection({ ...disableSection, accounting: false });
    } else if (status === requestStatus.pendingESG) {
      if (empClass === "INT") {
        if (roles.some(role => role.name === userRoles.esfOwners) ||
          roles.some(role => role.name === userRoles.adminInternal) ||
          roles.some((role) => role.name === userRoles.esgInternal)) {
          setDisableSection({ ...disableSection, esg: false });
        }
      } else {
        if (roles.some(role => role.name === userRoles.esfOwners) ||
          roles.some(role => role.name === userRoles.adminBillable) ||
          roles.some((role) => role.name === userRoles.esgBillable)) {
          setDisableSection({ ...disableSection, esg: false });
        }
      }
    } else if (status === requestStatus.pendingPayroll) {
      if (empCompany === "MSC") {
        if (roles.some(role => role.name === userRoles.esfOwners) ||
          roles.some(role => role.name === userRoles.payrollCanada)) {
          setDisableSection({ ...disableSection, payroll: false });
        }
      } else if (empClass === "INT") {
        if (roles.some(role => role.name === userRoles.esfOwners) ||
          roles.some(role => role.name === userRoles.adminInternal) ||
          roles.some((role) => role.name === userRoles.payrollInternal)) {
          setDisableSection({ ...disableSection, payroll: false });
        }
      } else {
        if (roles.some(role => role.name === userRoles.esfOwners) ||
          roles.some(role => role.name === userRoles.adminBillable) ||
          roles.some((role) => role.name === userRoles.payrollBillable)) {
          setDisableSection({ ...disableSection, payroll: false });
        }
      }
    }
  }

  const handleValidation = () => {
    setIsFormValid(false);
    const userAccess = JSON.parse(localStorage.getItem("UserAccess"));
    if (state.requestStatus === requestStatus.cancelled) {
      return;
    }
    if (userAccess.roles.length === 1 && userAccess.roles.some(role => role.name === userRoles.marketSourceUser) &&
      state.requestStatus && !(state.requestStatus === requestStatus.rejectToSubmitter)
    ) {
      return;
    }
    let isFormValid = false;
    isFormValid = isDefaultValidationPassed();
    if (requestId) {
      if (showCommercialHRSection) {
        isFormValid = isFormValid && isCommercialHRValidationPassed();
      }
      if (showBillableHRSection) {
        isFormValid = isFormValid && isBillableHRValidationPassed();
      }
      if (showAccountingSection) {
        isFormValid = isFormValid && isAccountingValidationPassed();
      }
      if (showFinanceSection) {
        isFormValid = isFormValid && isFinanceValidationPassed();
      }
      if (showESGSection) {
        isFormValid = isFormValid && isESGValidationPassed();
      }
      if (showPayrollSection) {
        isFormValid = isFormValid && isPayrollValidationPassed();
      }
      if (state.requestStatus === requestStatus.rejectToSubmitter) {
        if (!userAccess.roles.some(role => role.name === userRoles.esfOwners) &&
          state.submitterEmail !== user.userEmail) {
          isFormValid = false;
        }
      }
    }
    setIsFormValid(isFormValid);
  };  
  const payRateValidation = (compType, payRate) => {
    if (payRate) {
      if (compType === compensationType.salary) {
        if (
          parseFloat(payRate) >= parseFloat("10000.00") &&
          parseFloat(payRate) <= parseFloat("999999.99")
        ) {
          setShowPayrateError(false);
        }
        else {
          setShowPayrateError(true);
        }
      } else {
        if (
          parseFloat(payRate) >= parseFloat("1") &&
          parseFloat(payRate) <= parseFloat("99.99")
        ) {
          setShowPayrateError(false);
        }
        else {
          setShowPayrateError(true);
        }
      }
    }
  };

  const bonusPotentialValidation = (bonusPotential) => {
    if (bonusPotential) {
      if (parseFloat(bonusPotential) <= parseFloat("9999999.99")) {
        setShowBonusPotentialError(false);
      }
      else {
        setShowBonusPotentialError(true);
      }
    }
  };

  const setTotalPercChange =()=>{
    let totPercChange = 0;
    if (state.actionType === "Promotion" && state.compensationType)
    { 
      totPercChange= Math.round((((Number(state.payRate)+Number(state.bonusFrequency==="Not Eligible"?0:state.bonusPotential)) - 
          (Number(state.currentAnnualSalary) +Number(state.currentAnnualVariablePotential)))/
          (Number(state.currentAnnualSalary) +Number(state.currentAnnualVariablePotential)))*100)
          
    }
    else if (state.actionType === "Promotion" && !state.compensationType){
      totPercChange= Math.round(((((Number(state.payRate)*2080)+Number(state.bonusFrequency==="Not Eligible"?0:state.bonusPotential)) -((Number(state.bonusFrequency==="Not Eligible"?0:state.currentAnnualSalary) *2080)
            +Number(state.currentAnnualVariablePotential)))/
            ((Number(state.currentAnnualSalary) *2080)+Number(state.currentAnnualVariablePotential)))*100)
    }
    return totPercChange;
    
  };
  const isDefaultValidationPassed = () => {
    let isValid = false;
    if (
      selectedEmployee &&
      state.actionType &&
      state.actionType !== "Choose an Action" &&
      state.actionReason &&
      state.actionReason !== "Choose Reason" &&
      state.effectiveDate &&
      moment(state.effectiveDate).day() === 0 &&
      state.newJobTitle &&
      state.newJobTitle !== "Choose Job Title" &&
      supervisorData.selectedSupervisor &&
      state.newLocation &&
      state.newLocation !== "Choose a Location" &&
      state.payRate &&
      !showPayrateError &&
      state.bonusFrequency &&
      state.bonusFrequency !== "Choose Frequency" 
      
    ) {
      if (showBonusPotential) {
        if (state.bonusPotential && !showBonusPotentialError) {
          isValid = true;
        }
      } else {
        isValid = true;
      }
      if (state.actionType === "Promotion" && (!state.currentAnnualSalary)) {//|| !state.currentAnnualVariablePotential //S-423337 optional
        isValid= false;
      } 
    }
    return isValid;
  };

  const isCommercialHRValidationPassed = () => {
    let isValid = false;
    if (state.commercialhrAssignedTo) {
      isValid = true;
    }
    return isValid;
  };
  const isBillableHRValidationPassed = () => {
    let isValid = false;
    if (state.billablehrAssignedTo) {
      isValid = true;
    }
    return isValid;
  }; 
  const isAccountingValidationPassed = () => {
    let isValid = false;
    if (state.accountingAssignedTo) {
      isValid = true;
    }
    return isValid;
  };

  const isFinanceValidationPassed = () => {
    let isValid = false;
    if (state.financeAssignedTo) {
      isValid = true;
    }
    return isValid;
  };

  const isESGValidationPassed = () => {
    let isValid = false;
    if (state.esgAssignedTo) {
      isValid = true;
    }
    return isValid;
  };

  const isPayrollValidationPassed = () => {
    let isValid = false;
    if (state.payrollAssignedTo) {
      isValid = true;
    }
    return isValid;
  };

  const handleChange = (event) => {
    switch (event.target.name) {
      case "effectiveDate":
        let lblClass = "labelError";
        let submitButtonText = (
          <span>
            Please complete all required fields.<br></br>Please choose a Sunday
            for Effective date.
          </span>
        );
        if (moment(event.target.value).day() === 0) {
          lblClass = "labelNormal";
          submitButtonText = "Please complete all required fields.";
        }
        setState({ ...state, lblSunday: lblClass, submitButtonText });
        break;
      case "actionType":        
        let showTotalPercentChange=true;
        setState({ ...state, actionReason: "" });
        const action = event.target.value;
        setActionReasonOptions(action, allOptionsActionReason);
        if (event.target.value !=="Promotion"){
          showTotalPercentChange=false;
          setState({ ...state, TotalPercentChange: "" });
        }
        setShowTotalPercentChange(showTotalPercentChange);
        break;
      case "bonusFrequency":
        let showBonusPotential = true;
        if (
          event.target.value === "Not Eligible" ||
          event.target.value === "Choose Frequency"
        ) {
          showBonusPotential = false;
          setState({ ...state, bonusPotential: "0" });
        } 
        setShowBonusPotential(showBonusPotential);
        break;
      case "payRate":
        const compType = state.compensationType
          ? compensationType.salary
          : compensationType.hourly;
        payRateValidation(compType, event.target.value);
        break;
      case "bonusPotential":
        bonusPotentialValidation(event.target.value);
        break;
      case "supervisorCompany":
        setSupervisorData({ searchResults: [], selectedSupervisor: null });
        break;
      default:
        break;
    }
    setState({ ...state, totalPercentChange: setTotalPercChange() });
    
    setState((state) => ({
      ...state,
      [event.target.name]: event.target.value,
    }));
  };

  const setActionReasonOptions = (action, allOptions) => {
    const actionReasons = allOptions.filter(
      (item) => item.ActionType === action
    );
    if (actionReasons?.length > 0) {
      const reasonOptions = actionReasons.map((val) => {
        return { key: val.ActionReason, text: val.ActionReason };
      });
      setOptionsActionReason([
        { key: "Choose Reason", text: "Choose Reason" },
        ...reasonOptions,
      ]);
    } else {
      setOptionsActionReason([{ key: "Choose Reason", text: "Choose Reason" }]);
    }
  };

  const handleToggleChange = (checked) => {
    const compType = checked
      ? compensationType.salary
      : compensationType.hourly;
    payRateValidation(compType, state.payRate);
    setState((state) => ({
      ...state,
      compensationType: checked,
    }));
  };

  const onChangeUser = (e) => {
    try {
      const name = e.target.name;
      const displayName = e?.detail?.[0]?.displayName;
      if (displayName) {
        const email = e?.detail?.[0]?.userPrincipalName;
        setState({ ...state, [name]: displayName, [`${name}Email`]: email });
      } else {
        setState({ ...state, [name]: "", [`${name}Email`]: "", [`${name}EmailDisplay`]: "" });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSelectedEmployee = (value) => {
    try {
      if (value && Object.keys(value).length > 0) {
        setSelectedEmployee(value);
        setJobTitle(value.ProductID);
      } else {
        setSelectedEmployee(null);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSelectedSupervisor = (value) => {
    try {
      if (value && Object.keys(value).length > 0) {
        const selectedSupervisor = value;
        setSupervisorData({ ...supervisorData, selectedSupervisor });
      } else {
        setSupervisorData({
          ...supervisorData,
          selectedSupervisor: null,
          searchResults: [],
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getSupervisors = async (searchTerm, companyId) => {
    try {
      setLoadingSupervisor(true);
      const controller = new AbortController();
      const { signal } = controller;
      previousController.current = controller;
      const empResponse = await getEmployeesFromSearch(searchTerm, companyId, signal);
      if (empResponse) {
        setSupervisorData({
          ...supervisorData,
          searchResults: [...empResponse]
        });
      }
      setLoadingSupervisor(false);
    } catch (error) {
      console.error(error);
      setLoadingSupervisor(false);
    }
  };

  const setJobTitle = async (productId) => {
    try {
      const response = await getJobTitle(productId);
      if (response.JobCodes?.length > 0) {
        const jobs = response.JobCodes.map((job) => {
          return { key: job.JobCode, text: job.DescriptionJobCode };
        });
        setOptionsJobTitle([
          ...[{ key: "Choose Job Title", text: "Choose Job Title" }],
          ...jobs.sort((a, b) => (a.text > b.text ? 1 : -1)),
        ]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getDropdownOptions = async () => {
    return postValuesApi(
      process.env.REACT_APP_ESF_GETCHOICEVALUES,
      {
        RelatedProcess: "Position Change",
      }
    );
  };

  const getEmployeeData = async (employeeId) => {
    try {
      const reportees = getReportees(employeeId);
      const supervisor = getEmployee(employeeId);
      return Promise.all([reportees, supervisor]).then(
        ([employeesAndSubordinates, supervisorInfo]) => {
          if (employeesAndSubordinates) {
            setEmployeeData(employeesAndSubordinates);
            setSupervisorData({
              ...supervisorData,
              selectedSupervisor: supervisorInfo,
            });
          }
          else {
            setState({ ...state, submitButtonText: 'No direct reports returned.', submitButtonColor: 'red' });
          }
        }
      );
    } catch (error) {
      console.error(error);
    }
  };

  const setSupervisorInfo = async (employeeId) => {
    try {
      return getEmployee(employeeId).then((empInfo) => {
        if (empInfo) {
          setSupervisorData({
            ...supervisorData,
            selectedSupervisor: empInfo,
          });
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const prepareBody = () => {
    try {
      let method = "CREATE";
      let reqId = requestId;
      let reqStatus = "";
      const paramPositionChangeData = {
        actionType: state.actionType,
        actionReason: state.actionReason,
        compensationType: state.compensationType
          ? compensationType.salary
          : compensationType.hourly,
        newJobTitle: state.newJobTitle,
        newLocation: state.newLocation,
        payRate: state.payRate,
        bonusFrequency: state.bonusFrequency,
        bonusPotential: state.bonusPotential,
        businessTitle: state.businessTitle,
        submitterPhone: state.submitterPhone,
        actionComments: state.actionComments,
        Modified: getFormattedDate(new Date()),
        ModifiedBy: user.userName,
        effectiveDate: moment(state.effectiveDate).format("M/D/YYYY"),
        dateApprovedByAccountingFinance: state.dateApprovedByAccountingFinance,
        dateApprovedByESG: state.dateApprovedByESG,
        completedDate: state.completedDate,
        // Total Percent Change
        currentAnnualSalary: state.currentAnnualSalary ,
        currentAnnualVariablePotential: state.currentAnnualVariablePotential ,
        totalPercentChange:  state.totalPercentChange ,
        justificationForPromotion: state.justificationForPromotion ,
        alignmentToOtherSimilarEmployees: state.alignmentToOtherSimilarEmployees,
        // Employee information
        empBusinessUnit: selectedEmployee.ProductID,
        empClass: selectedEmployee.EmployeeClass,
        empCompany: selectedEmployee.CompanyID,
        empHireDate: selectedEmployee.HireDate,
        empId: selectedEmployee.EmployeeID,
        empName: selectedEmployee.Name,
        empPosition: selectedEmployee.JobDescription,
        empState: selectedEmployee.HomeState,
        empSupervisor: selectedEmployee.SupervisorID,
        empEmail: selectedEmployee.EmailPreferred,
        empLocation: selectedEmployee.OfficeLocationID,
        empProgram: `${selectedEmployee.DepartmentDescription} - ${selectedEmployee.DepartmentID}`,

        // Supervisor information
        newSupervisorId: supervisorData.selectedSupervisor.EmployeeID,
        newSupervisorName: supervisorData.selectedSupervisor.Name,

        loginAsEmpId: loggedInAs.EmpId,
        loggedInAsDisplayName: loggedInAs.DisplayName,
        loggedInAsEmail: loggedInAs.Email
      };
      if (requestId) {
        method = "UPDATE";
        paramPositionChangeData.submitterName = state.submitterName;
        paramPositionChangeData.submitterEmail = state.submitterEmail;
        paramPositionChangeData.dateSubmitted = state.dateSubmitted;
        if (showCommercialHRSection) {
          paramPositionChangeData.commercialhrApproval =
            state.commercialhrApproval;
          paramPositionChangeData.commercialhrAssignedTo =
            state.commercialhrAssignedTo;
          paramPositionChangeData.commercialhrAssignedToEmail =
            state.commercialhrAssignedToEmail;
          paramPositionChangeData.commercialhrComments =
            state.commercialhrComments;
        }
        if (showBillableHRSection) {
          paramPositionChangeData.billablehrApproval =
            state.billablehrApproval;
          paramPositionChangeData.billablehrAssignedTo =
            state.billablehrAssignedTo;
          paramPositionChangeData.billablehrAssignedToEmail =
            state.billablehrAssignedToEmail;
          paramPositionChangeData.billablehrComments =
            state.billablehrComments;
        }
        if (showAccountingSection) {
          paramPositionChangeData.accountingApproval = state.accountingApproval;
          paramPositionChangeData.accountingAssignedTo =
            state.accountingAssignedTo;
          paramPositionChangeData.accountingAssignedToEmail =
            state.accountingAssignedToEmail;
          paramPositionChangeData.accountingComments = state.accountingComments;
        }
        if (showFinanceSection) {
          paramPositionChangeData.financeLevel = state.financeLevel;
          paramPositionChangeData.financeApproval = state.financeApproval;
          paramPositionChangeData.financeAssignedTo = state.financeAssignedTo;
          paramPositionChangeData.financeAssignedToEmail =
            state.financeAssignedToEmail;
          paramPositionChangeData.financeComments = state.financeComments;
        }
        if (showESGSection) {
          paramPositionChangeData.esgApproval = state.esgApproval;
          paramPositionChangeData.esgAssignedTo = state.esgAssignedTo;
          paramPositionChangeData.esgAssignedToEmail = state.esgAssignedToEmail;
          paramPositionChangeData.esgComments = state.esgComments;
        }
        if (showPayrollSection) {
          paramPositionChangeData.payrollApproval = state.payrollApproval;
          paramPositionChangeData.payrollAssignedTo = state.payrollAssignedTo;
          paramPositionChangeData.payrollAssignedToEmail =
            state.payrollAssignedToEmail;
          paramPositionChangeData.payrollComments = state.payrollComments;
        }

        paramPositionChangeData.requestStatus = state.requestStatus;
        reqStatus = getNextStatus(state.requestStatus, paramPositionChangeData);
      } else {
        reqId = Math.floor(Math.random() * 1677721555).toString(16);
        reqStatus = requestStatus.pendingFinance;
        if (selectedEmployee.EmployeeClass === "INT") {
          reqStatus = requestStatus.pendingAccounting;
        }
        else if (selectedEmployee.ProductID === "83") {
          reqStatus = requestStatus.pendingCommercialHR;
        }
        else if (selectedEmployee.ProductID !== "83") {
          reqStatus = requestStatus.pendingHr; //som
        }
        paramPositionChangeData.dateSubmitted = getFormattedDate(new Date());
        paramPositionChangeData.submitterName = user.userName;
        paramPositionChangeData.submitterEmail = user.userEmail;
      }
      if (reqStatus) {
        paramPositionChangeData.requestStatus = reqStatus;
      }
      paramPositionChangeData.requestId = reqId;
      const body = {
        Method: method,
        PositionChangeData: paramPositionChangeData,
        requestID: reqId,
      };
      return body;
    } catch (error) {
      console.error(error);
    }
  };

  const onClickCancel = async () => {
    const effectiveDateValue = state.effectiveDate?.replace('Z', '');
    const dateSubmittedValue = state.dateSubmitted?.replace('Z', '');
    const request = {
      requestId: requestId,
      actionType: state.actionType,
      requestStatus: state.requestStatus,
      dateSubmitted: dateSubmittedValue ? moment(dateSubmittedValue).format(
        "MM/DD/YY hh:mm A"
      ) : "",
      effectiveDate: effectiveDateValue ? moment(effectiveDateValue).format(
        "MM/DD/YY"
      ) : "",
      submitterName: state.submitterName,
      submitterEmail: state.submitterEmail,
      empName: selectedEmployee.Name,
      empId: selectedEmployee.EmployeeID,
      empName: selectedEmployee.Name,
      empPosition: selectedEmployee.JobDescription,
      empSupervisor: selectedEmployee.SupervisorID,
      empProgram: `${selectedEmployee.DepartmentDescription} - ${selectedEmployee.DepartmentID}`,
      formName: 'Position Change'
    };
    const response = await cancelRequest(request);
    if (response) {
      navigate(`/approval-queue/`);
    }
  }

  const cancelVisibility = async (roles, status) => {
    setIsCancelVisible(false);
    if (status !== requestStatus.cancelled && status !== requestStatus.complete) {
      const rolesAllowed = process.env.REACT_APP_CANCELLATION_ROLES_ALLOWED;
      if (roles.some(role => rolesAllowed.includes(role.name))) {
        setIsCancelVisible(true);
      }
    }
  }

  const onClickSubmit = async () => {
    try {
      const start = new Date().getTime();
      setPageLoader(true);
      const body = prepareBody();
      postApi(
        process.env.REACT_APP_ESF_POSITIONCHANGE,
        body
      ).then((response) => {
        if (appInsights) {
          appInsights.trackEvent({
            name: `Page ${requestId ? 'Update' : 'Submit'} Position Change`,
            properties: {
              TimeTaken: `${(new Date().getTime() - start) / 1000} s`,
            },
          });
        }
        setPageLoader(false);
        if (response.ResultSets?.Table1?.[0]?.CREATEspRESULT) {
          Swal.fire({
            title: "Something went wrong...",
            html: response.ResultSets?.Table1?.[0]?.CREATEspRESULT,
            icon: "error",
          });
        } else {
          if (!Object.keys(response).includes("error")) {
            Swal.fire({
              title: "Submitted Successfully",
              icon: "success",
              html: `<a href="${redirectUrl(user.roles)}" target="_blank">Go to Home</a>`,
              confirmButtonText: "View Details",
            }).then(async (result) => {
              setIsFormValid(false);
              if (result.isConfirmed) {
                navigate(`/${formRoutes.positionChange}/view/${body.requestID}`);
              }
            });
          }
          else {
            console.error(response.error);
          }
        }
      });
    } catch (error) {
      setPageLoader(false);
      console.error(error);
    }
  };

  const getNextStatus = (status, paramPositionChangeData) => {
    let reqStatus = "";
    switch (status) {
      case requestStatus.pendingCommercialHR:
        if (state.commercialhrApproval === "Approve") {
          reqStatus = requestStatus.pendingFinance;
        } else if (state.commercialhrApproval === "Reject to Submitter") {
          reqStatus = requestStatus.rejectToSubmitter;
        }
        break;

      case requestStatus.pendingHr:
        if (state.billablehrApproval === "Approve") {
          reqStatus = requestStatus.pendingFinance; //Soma
        } else if (state.billablehrApproval === "Reject to Submitter") {
          reqStatus = requestStatus.rejectToSubmitter;
        }
        break;

      case requestStatus.pendingAccounting:
        if (state.accountingApproval === "Approve") {
          paramPositionChangeData.dateApprovedByAccountingFinance =
            getFormattedDate(new Date());
          reqStatus = requestStatus.pendingESG;
          if (selectedEmployee.CompanyID === "MSC") {
            reqStatus = requestStatus.pendingPayroll;
          }
        } else if (state.accountingApproval === "Reject to Submitter") {
          reqStatus = requestStatus.rejectToSubmitter;
        }
        break;

      case requestStatus.pendingFinance:
        if (state.financeApproval === "Approve") {
          paramPositionChangeData.dateApprovedByAccountingFinance =
            getFormattedDate(new Date());
          reqStatus = requestStatus.pendingESG;
          if (selectedEmployee.CompanyID === "MSC") {
            reqStatus = requestStatus.pendingPayroll;
          }
        } else if (state.financeApproval === "Reject to Submitter") {
          reqStatus = requestStatus.rejectToSubmitter;
        }
        break;

      case requestStatus.pendingESG:
        if (state.esgApproval === "Approve & Complete") {
          paramPositionChangeData.dateApprovedByESG = getFormattedDate(new Date());
          paramPositionChangeData.completedDate = getFormattedDate(new Date());
          reqStatus = requestStatus.complete;
        } else if (state.esgApproval === "Approve & Submit to Payroll") {
          paramPositionChangeData.dateApprovedByESG = getFormattedDate(new Date());
          reqStatus = requestStatus.pendingPayroll;
        }
        break;

      case requestStatus.pendingPayroll:
        if (state.payrollApproval === "Approve") {
          paramPositionChangeData.completedDate = getFormattedDate(new Date());
          reqStatus = requestStatus.complete;
        }
        break;

      case requestStatus.rejectToSubmitter:
        resetApproval(paramPositionChangeData);
        reqStatus = requestStatus.pendingFinance;
        if (selectedEmployee.EmployeeClass === "INT") {
          reqStatus = requestStatus.pendingAccounting;
        }
        else if (selectedEmployee.ProductID === "83") {
          reqStatus = requestStatus.pendingCommercialHR;
        }
        else if (selectedEmployee.ProductID !== "83") { //soma
          reqStatus = requestStatus.pendingHr;
        }
        break;

      default:
        break;
    }
    return reqStatus;
  };

  const resetApproval = (paramPositionChangeData) => {

    paramPositionChangeData.commercialhrApproval = null;
    paramPositionChangeData.commercialhrAssignedTo = null;
    paramPositionChangeData.commercialhrAssignedToEmail = null;

    paramPositionChangeData.billablehrApproval = null;
    paramPositionChangeData.billablehrAssignedTo = null;
    paramPositionChangeData.billablehrAssignedToEmail = null;

    paramPositionChangeData.accountingApproval = null;
    paramPositionChangeData.accountingAssignedTo = null;
    paramPositionChangeData.accountingAssignedToEmail = null;

    paramPositionChangeData.financeApproval = null;
    paramPositionChangeData.financeAssignedTo = null;
    paramPositionChangeData.financeLevel = null;
    paramPositionChangeData.financeAssignedToEmail = null;

  }

  const payrollSection = (
    <div className="outerDiv">
      <div>
        <p>{approvalSectionTitle.payroll}</p>
        <EsfInput
          label="Approval"
          name="payrollApproval"
          type="select"
          value={state.payrollApproval}
          options={approvalOptions.pendingApprove}
          onChange={handleChange}
          required
          disabled={disableSection.payroll}
        />
        <User
          label="Assigned To"
          name="payrollAssignedTo"
          onChange={onChangeUser}
          defaultSelectedUser={
            state.payrollAssignedToEmailDisplay ? [state.payrollAssignedToEmailDisplay] : []
          }
          required
          disabled={disableSection.payroll}
        />
        <EsfInput
          placeHolder="Comments"
          name="payrollComments"
          type="textarea"
          value={state.payrollComments}
          onChange={handleChange}
          disabled={disableSection.payroll}
        />
      </div>
    </div>
  );

  const esgSection = (
    <div className="outerDiv">
      <div>
        <p>{approvalSectionTitle.esg}</p>
        <EsfInput
          label="Approval"
          name="esgApproval"
          type="select"
          value={state.esgApproval}
          options={approvalOptions.pendingApproveSubmitPayroll}
          onChange={handleChange}
          required
          disabled={disableSection.esg}
        />
        <User
          label="Assigned To"
          name="esgAssignedTo"
          onChange={onChangeUser}
          defaultSelectedUser={
            state.esgAssignedToEmailDisplay ? [state.esgAssignedToEmailDisplay] : []
          }
          required
          disabled={disableSection.esg}
        />
        <EsfInput
          placeHolder="Comments"
          name="esgComments"
          type="textarea"
          value={state.esgComments}
          onChange={handleChange}
          disabled={disableSection.esg}
        />
      </div>
    </div>
  );

  const financeSection = (
    <div className="outerDiv">
      <div>
        <p>{approvalSectionTitle.finance}</p>
        <EsfInput
          label="Approval"
          name="financeApproval"
          type="select"
          value={state.financeApproval}
          options={approvalOptions.pendingApproveReject}
          onChange={handleChange}
          required
          disabled={disableSection.finance}
        />
        <EsfInput
          label="Finance Level"
          name="financeLevel"
          type="select"
          value={state.financeLevel}
          options={approvalOptions.financeLevel}
          onChange={handleChange}
          required
          disabled={disableSection.finance}
        />
        <User
          label="Assigned To"
          name="financeAssignedTo"
          onChange={onChangeUser}
          defaultSelectedUser={
            state.financeAssignedToEmailDisplay ? [state.financeAssignedToEmailDisplay] : []
          }
          required
          disabled={disableSection.finance}
        />
        <EsfInput
          placeHolder="Comments"
          name="financeComments"
          type="textarea"
          value={state.financeComments}
          onChange={handleChange}
          disabled={disableSection.finance}
        />
      </div>
    </div>
  );

  const accountingSection = (
    <div className="outerDiv">
      <div>
        <p>{approvalSectionTitle.accounting}</p>
        <EsfInput
          label="Approval"
          name="accountingApproval"
          type="select"
          value={state.accountingApproval}
          options={approvalOptions.pendingApproveReject}
          onChange={handleChange}
          required
          disabled={disableSection.accounting}
        />
        <User
          label="Assigned To"
          name="accountingAssignedTo"
          onChange={onChangeUser}
          defaultSelectedUser={
            state.accountingAssignedToEmailDisplay
              ? [state.accountingAssignedToEmailDisplay]
              : []
          }
          required
          disabled={disableSection.accounting}
        />
        <EsfInput
          placeHolder="Comments"
          name="accountingComments"
          type="textarea"
          value={state.accountingComments}
          onChange={handleChange}
          disabled={disableSection.accounting}
        />
      </div>
    </div>
  );

  const commercialHRSection = (
    <div className="outerDiv">
      <div>
        <p>{approvalSectionTitle.commercialHR}</p>
        <EsfInput
          label="Approval"
          name="commercialhrApproval"
          type="select"
          value={state.commercialhrApproval}
          options={approvalOptions.pendingApproveReject}
          onChange={handleChange}
          required
          disabled={disableSection.commercialHR}
        />
        <User
          label="Assigned To"
          name="commercialhrAssignedTo"
          onChange={onChangeUser}
          defaultSelectedUser={
            state.commercialhrAssignedToEmailDisplay
              ? [state.commercialhrAssignedToEmailDisplay]
              : []
          }
          required
          disabled={disableSection.commercialHR}
        />
        <EsfInput
          placeHolder="Comments"
          name="commercialhrComments"
          type="textarea"
          value={state.commercialhrComments}
          onChange={handleChange}
          disabled={disableSection.commercialHR}
        />
      </div>
    </div>
  );

  const billableHRSection = (
    <div className="outerDiv">
      <div>
        <p>{approvalSectionTitle.hr}</p>
        <EsfInput
          label="Approval"
          name="billablehrApproval"
          type="select"
          value={state.billablehrApproval}
          options={approvalOptions.pendingApproveReject}
          onChange={handleChange}
          required
          disabled={disableSection.billableHR}
        />
        <User
          label="Assigned To"
          name="billablehrAssignedTo"
          onChange={onChangeUser}
          defaultSelectedUser={
            state.billablehrAssignedToEmailDisplay
              ? [state.billablehrAssignedToEmailDisplay]
              : []
          }
          required
          disabled={disableSection.billableHR}
        />
        <EsfInput
          placeHolder="Comments"
          name="billablehrComments"
          type="textarea"
          value={state.billablehrComments}
          onChange={handleChange}
          disabled={disableSection.billableHR}
        />
      </div>
    </div>
  );

  const getImpersonated = (e) => {
    if (e !== null && e.detail !== null && e.detail.length > 0 && e.detail[0].userPrincipalName !== null &&
      (requestId == '' || requestId == undefined || requestId == null)) {
      setLoading(true);
      getImpersonatedUserRoles(e.detail[0].userPrincipalName).then(() => {
        var temp = localStorage.getItem("LoggedInAsUserAccess");
        if (temp) {
          temp = JSON.parse(temp);
          console.log(temp);
          setLoginAs(
            { EmpId: temp.employeeID[0].employeeId, DisplayName: e.detail[0].displayName, Email: e.detail[0].userPrincipalName, LoginAsError: false }
          );
          setLoading(false);
          //LoadPage();
        } else {
          setLoginAsError(true);
          setIsLoginAsVisible(true);
          localStorage.removeItem("LoggedInAsUserAccess");
          setLoginAs({
            EmpId: '', DisplayName: '', Email: ''
          });
          setLoading(false);
          //LoadPage();
        }


      });

    } else if (!requestId) {
      localStorage.removeItem("LoggedInAsUserAccess");
      setLoginAs({ EmpId: '', DisplayName: '', Email: '' });
      setLoading(false);
    }


  }

  const clearImpersonation = (e) => {
    localStorage.removeItem("LoggedInAsUserAccess");
    setLoginAs({ EmpId: '', DisplayName: '', Email: '', });
    //LoadPage();
    /*this.setState({
      loggedInAs: { EmpId: '', DisplayName: '', Email: '', },
      Employees: [],
      selectedEmployee: null
    }, () => { this.componentDidMount() });*/

  }

  return (
    <main>
      {pageLoader && <div className="loader">
        <Oval color="#035797" height={100} width={100} />
      </div>}
      <Title title={title} color={bgColorsTitle["position-change"]} />
      <Note note={note} />
      <section>
        {isCancelVisible &&
          <>
            <br />
            <SubmitButton label="Cancel This Form" onClick={onClickCancel} color={"#A51D25"} />
          </>
        }
        {
          isLoginAsVisible && (
            <LoginAs
              onChange={(e) => getImpersonated(e)}
              clearLoginAs={(e) => clearImpersonation(e)}
              showError={LoginAsError}
              empId={loggedInAs.EmpId}
              displayName={loggedInAs.DisplayName}
              email={loggedInAs.Email}
              requestId={requestId}
              loading={loading}
            //defaultSelectedUser={this.state.loggedInAs.DisplayEmail}
            //loginAsThisUser={() => this.loginAsOtherUser()}

            ></LoginAs>)}
        <EmployeeSearch
          employees={employeeData}
          selectedEmployee={selectedEmployee}
          setSelectedEmployee={handleSelectedEmployee}
          disabled={requestId ? true : false}
        />
        <EsfInput
          label="Action"
          name="actionType"
          type="select"
          value={state.actionType}
          options={optionsActionType}
          onChange={handleChange}
          required
        />
        <EsfInput
          label="Reason for Position Change"
          name="actionReason"
          type="select"
          value={state.actionReason}
          options={optionsActionReason}
          onChange={handleChange}
          required
        />

        <EsfInput
          type="date"
          name="effectiveDate"
          label="Effective Date"
          required
          value={state.effectiveDate}
          onChange={handleChange}
        />
        <label className={state.lblSunday}>Sundays only</label>

        {selectedEmployee && (
          <EsfInput
            type="select"
            name="newJobTitle"
            label="New Job Title"
            required
            value={state.newJobTitle}
            onChange={handleChange}
            options={optionsJobTitle}
          />
        )}

        {!requestId && <EsfInput
          label="Supervisor's Location"
          name="supervisorCompany"
          type="select"
          value={state.supervisorCompany}
          options={employeeCompanyLocations}
          onChange={handleChange}
          required
          disabled={requestId ? true : false}
        />
        }

        <EmployeeSearch
          employees={supervisorData.searchResults}
          selectedEmployee={supervisorData.selectedSupervisor}
          setSelectedEmployee={handleSelectedSupervisor}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          loading={loadingSupervisor}
          supervisor
          disabled={requestId ? true : false}
        />
        {!supervisorData.selectedSupervisor && (
          <label className="labelNormal">{employeeSearchText.message}</label>
        )}

        <EsfInput
          type="select"
          name="newLocation"
          label="Employee's Future Location"
          required
          value={state.newLocation}
          onChange={handleChange}
          options={optionsEmpLocation}
        />

        <Toggle
          label="How is pay entered?"
          name="compensationType"
          onText="Salary"
          offText="Hourly"
          value={state.compensationType}
          onChange={handleToggleChange}
        />

        <EsfInput
          type="currency"
          label="New Pay Rate"
          required
          name="payRate"
          value={state.payRate}
          onChange={handleChange}
        />
        {showPayrateError && (
          <label className="labelError">
            {state.compensationType
              ? "Pay rate entered must have a minimum of 5 and a maximum of 6 digits before the decimal (10,000.00 - 999,999.99)."
              : "Pay rate entered may have up to 2 digits before and after the decimal (1 - 99.99)."}
          </label>
        )}
        <EsfInput
          type="select"
          label="New Bonus Frequency"
          required
          name="bonusFrequency"
          value={state.bonusFrequency}
          onChange={handleChange}
          options={optionsBonusFrequency}
        />

        {showBonusPotential && (
          <div>
            <EsfInput
              type="currency"
              label="New Annualized Bonus Potential"
              name="bonusPotential"
              id="bonusPotential"
              required
              value={state.bonusFrequency=="Not Eligible"?0: state.bonusPotential}
              onChange={handleChange}
            />
            {showBonusPotentialError && (
              <>
                <label className="labelError">
                  Bonus Potential cannot be greater than seven digits (before
                  the decimal place).
                </label>
                <br />
                <br />
              </>
            )}

            <label className="labelNormal">
              Enter annualized bonus potential amount regardless of payout
              frequency.
            </label>
          </div>
        )}
        {showTotalPercentChange && 
        <EsfInput
          type="currency"
          label="Current Annual Salary"
          required
          name="currentAnnualSalary"
          value={state.currentAnnualSalary}
          onChange={handleChange}
        />
        }
        {showTotalPercentChange && 
        <EsfInput
          type="currency"
          label="Current Annual Variable Potential"
            //required //1824
          name="currentAnnualVariablePotential"
          value={state.currentAnnualVariablePotential}
          onChange={handleChange}
        />
       }
        {showTotalPercentChange && 
        <EsfInput
          type="text"
          label="Total Percent Change"
          name="totalPercentChange"
          value={state.actionType === "Promotion"? ( (state.compensationType ? Math.round((((Number(state.payRate)+Number(state.bonusFrequency==="Not Eligible"?0:state.bonusPotential)) - 
          (Number(state.currentAnnualSalary) +Number(state.currentAnnualVariablePotential)))/
          (Number(state.currentAnnualSalary) +Number(state.currentAnnualVariablePotential)))*100)
          : Math.round(((((Number(state.payRate)*2080)+Number(state.bonusFrequency==="Not Eligible"?0:state.bonusPotential)) -((Number(state.currentAnnualSalary) *2080)
          +Number(state.currentAnnualVariablePotential)))/
          ((Number(state.currentAnnualSalary) *2080)+Number(state.currentAnnualVariablePotential)))*100)))+"%"
          :""}
          onChange={handleChange}
        />
        }
        {showTotalPercentChange && 
        <div>
          <EsfInput
                    type="text"
                    label="Justification for Promotion"
                    name="justificationForPromotion"
                    value={state.justificationForPromotion}
                    onChange={handleChange}
                placeHolder=""
                  />
            <label className="labelNormal">
                        Enter justification for promotion including information like accomplishments, performance, years of experience, special skills, or knowledge..
                      </label>
          </div>	
        }
        {showTotalPercentChange && 
          <EsfInput
            type="text"
            label="Alignment to other similar employees holding the same role"
            name="alignmentToOtherSimilarEmployees"
            value={state.alignmentToOtherSimilarEmployees}
            onChange={handleChange}
          />
        }
        
        <EsfInput
          label="Client Billing Title, if different from Job Title"
          name="businessTitle"
          type="text"
          value={state.businessTitle}
          onChange={handleChange}
          placeHolder="Client Billing Title"
        />

        <EsfInput
          label="Your Phone Number:"
          name="submitterPhone"
          type="text"
          value={state.submitterPhone}
          onChange={handleChange}
        />
        <EsfInput
          placeHolder="Add any additional comments..."
          name="actionComments"
          type="textarea"
          value={state.actionComments}
          onChange={handleChange}
        />
        <>
          {isFormValid && state.requestStatus === requestStatus.rejectToSubmitter && state.requestStatus !== requestStatus.complete && (
            <SubmitButton label="Submit" onClick={onClickSubmit} />
          )}
          <br />
        </>
        {showCommercialHRSection && commercialHRSection}

        {showBillableHRSection && billableHRSection}

        {showAccountingSection && accountingSection}

        {showFinanceSection && financeSection}

        {showESGSection && esgSection}

        {showPayrollSection && payrollSection}

        {!isFormValid &&
          (!requestId || state.requestStatus !== requestStatus.complete) && (
            <SubmitButton label={state.submitButtonText} color={state.submitButtonColor} />
          )}
        {isFormValid && state.requestStatus !== requestStatus.rejectToSubmitter && state.requestStatus !== requestStatus.complete && (
          <SubmitButton label="Submit" onClick={onClickSubmit} />
        )}
      </section>
      <br />
      <br />
      {gridRows.length > 0 && (
        <div className="outerDiv">
          <div>
            <p>{workflowHistory.title}</p>
            <Grid rowData={gridRows} columnDefs={gridColumns} />
          </div></div>
      )}
    </main>
  );
};

export default PositionChange;
