import React from "react";
import "./styles.css";

const SubmitButton = ({ label, onClick, color }) => {
  let btnBackground = "bgDisable";
  let btnShowCursor = "";
  if (onClick) {
    btnBackground = "bgEnable";
    btnShowCursor = "showCursor";
  }
  return (
    <div className={`submitButton ${btnBackground}`} style={{ backgroundColor: color }}>
      <button className={btnShowCursor} type="submit" onClick={onClick}>
        {label}
      </button>
    </div>
  );
};

export default SubmitButton;
