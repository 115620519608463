import "./styles.css";
import { PeoplePicker } from "@microsoft/mgt-react";

const User = (props) => {
  return (
    <div className="esfInput">
      <div className="labelInput">
        <label htmlFor={props.name}>
          {props.label} {props.required && <span className="required">*</span>}
        </label>
        <div className='peoplePicker'>
          <PeoplePicker
            name={props.name}
            selectionMode="single"
            userType="user"
            selectionChanged={props.onChange}
            defaultSelectedUserIds={props.defaultSelectedUser}
            disabled={props.disabled}
          />
        </div>
      </div >
    </div >
  );
};

export default User;
