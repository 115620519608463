import React from "react";
import "./styles.css";

const Note = ({ note }) => {
  return (
    <div className="note">
      <p>
        <span className="red">Note:</span> {note}
      </p>
    </div>
  );
};

export default Note;
