async function postValuesApi(callURL, data) {
  const postsettings = {
    method: "POST",
    crossDomain: true,
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
      "cache-control": "no-cache",
    },
    body: JSON.stringify(data),
  };

  try {
    const response = await fetch(callURL, postsettings);
    if (response.status === 200) {
      return response.json();
    }
  } catch (err) {
    console.error(
      "Error : " + err + " the values from API - " + callURL
    );
  }
}

async function getApi(callURL, bearerToken = null, apiKey = null) {
  const headers = {
    accept: "application/json",
    "cache-control": "no-cache",
  };
  if (bearerToken) {
    headers["authorization"] = bearerToken;
  }
  if (apiKey) {
    headers["api-key"] = apiKey;
  }
  try {
    const response = await fetch(callURL, {
      method: "GET",
      crossDomain: true,
      headers: headers,
    });
    if (response.status === 200) {
      return response.json();
    }
  } catch (err) {
    console.error(
      "Error : " + err + " the values from API - " + callURL
    );
  }
}

async function postApi(callURL, body = null, signal = null) {
  const headers = {
    accept: "application/json",
    "Content-Type": "application/json",
    "cache-control": "no-cache",
  };
  const postsettings = {
    method: "POST",
    crossDomain: true,
    headers: headers,
  };
  try {
    if (body) {
      postsettings["body"] = JSON.stringify(body);
    }
    if (signal) {
      postsettings["signal"] = signal;
    }
    try {
      const response = await fetch(callURL, postsettings);
      if (response.status === 200) {
        return response.json();
      }
    } catch (error) {
      return error;
    }
  } catch (err) {
    console.error(
      "Error : " + err + " the values from API - " + callURL
    );
  }
}

function getTemporaryEmpId(email) {
  let empId = '';
  switch (email.toLowerCase()) {
    case 'sptestvisitor1@aerotek.com':
      empId = '06408727';
      break;
    case 'sptestvisitor2@aerotek.com':
      empId = '03886946';
      break;
    case 'sptestowner2@allegisgroup.com':
      empId = '04109212';
      break;
    case 'jmamilla@allegisgroup.com':
      empId = '03806371';
      break;
    case 'asajjan@allegisgroup.com':
      empId = '05522532';
      break;
    case 'sptestowner1@allegisgroup.com':
      empId = '05324088';
      break;
    case 'tthabjul@allegisgroup.com':
      empId = '07180029';
      break;
    case 'testmsuext1@allegisgroup.com':
      empId = '03953345';
      break;
    case 'testmsuint1@allegisgroup.com':
      empId = '03806371';
      break;
    case 'testmsuint81@allegisgroup.com':
      empId = '03806371';
      break;
    case 'testmsuext81@allegisgroup.com':
      empId = '05577928';
      break;
    case 'testmsuext82@allegisgroup.com':
      empId = '07427804';
      break;
    case 'testmsucns82@allegisgroup.com':
      empId = '04841662';
      break;
    case 'testmsccns83@allegisgroup.com':
      empId = '05522532';
      break;
    case 'testmsucns83@allegisgroup.com':
      empId = '03162853';
      break;
    case 'testmspcns83@allegisgroup.com':
      empId = '05313172';
      break;
    case 'testmsuext83@allegisgroup.com':
      empId = '07180029';
      break;
    case 'heajones@allegisgroup.com':
      empId = '03806371';
      break;
    default:
      break;
  }
  return empId;
}

async function getImpersonatedUserRoles(UPN) {
  try {
    if (UPN) {
      const bearer = `Bearer ${localStorage.getItem("UserToken")}`;
      const userAccessEndPoint = `${process.env.REACT_APP_USERACCESSENDPOINT}${UPN}`;
      const data = await getApi(userAccessEndPoint, bearer);
      if (data) {
        localStorage.setItem("LoggedInAsUserAccess", JSON.stringify(data));
      }
      else {
        localStorage.removeItem("LoggedInAsUserAccess");
        localStorage.removeItem("UserToken");
        localStorage.removeItem("UserAccess");
      }
    }
  } catch (error) {
    console.error(error);
  }
}

export { postValuesApi, getApi, postApi, getTemporaryEmpId, getImpersonatedUserRoles };
