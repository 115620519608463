//http://localhost:3000/compensation-change/63f40685
import React, { Component } from "react";
import "./style.css";
import Title from "../../components/Title";
import Note from "../../components/Note";
import moment from "moment";
import EsfInput from "../../components/EsfInput";
import SubmitButton from "../../components/SubmitButton";
import { Navigate } from "react-router-dom";
import Toggle from '../../components/Toggle';
import EmployeeSearch from "../../components/EmployeeSearch";
import Swal from "sweetalert2";
import Approval from "./approval";
import { requestStatus, userRoles, workflowHistory } from "../../utils/constants";
import Grid from "../../components/Grid";
import { MsalContext } from "@azure/msal-react";
import { getTemporaryEmpId, postApi, postValuesApi, getImpersonatedUserRoles } from "../../utils/fetchApi";
import { appInsights } from "../../components/AppInsights";
import { cancelRequest, getFormattedDate, redirectUrl } from "../../utils/globalUtils";
import LoginAs from "../LoginAs";

class CompensationChange extends Component {
  static contextType = MsalContext;
  //Compensation Change Inside and out Cool Gray 7 = #9A9B9C
  constructor(props) {
    super(props);
    this.state = {
      reasonForPayChangeOptions: [{ key: "--Choose Reason--", text: "--Choose Reason--" }],
      requestId: "",
      isEmployeeSelected: false,
      showBonus: false,
      Employees: [],
      PayRate: "",
      dateLblClass: "labelError",
      goToHome: false,
      isFormValid: false,
      isUpdateFormValid: false,
      AnnualizedBonusPotentialError: false,
      actionType: "",

      /*Form Values*/
      SelectedEmployee: null,
      ReasonForPayChange: "--Choose Reason--",
      EffectiveDate: null,
      HowIsPayEntered: "Salary",//"Hourly",

      BonusFrequency: "--Choose Frequency--",
      AnnualizedBonusPotential: "",
      YourPhoneNumber: "",
      submitterEmail: "",
      AdditionalComments: "",
      BusinessUnit: "",

      /*Approval States*/
      RequestStatus: null,
      esgApproval: null,
      esgAssignedTo: null,
      esgAssignedToEmail: null,
      esgComments: null,
      esgPayrollComments: null,
      esgAssignedToDisplay: null,


      accountingApproval: null,
      accountingAssignedTo: null,
      accountingAssignedToEmail: null,
      accountingComments: null,
      accountingAssignedToDisplay: null,

      financeApproval: null,
      financeAssignedTo: null,
      financeAssignedToEmail: null,
      financeComments: null,
      financeAssignedToDisplay: null,

      payrollApproval: null,
      payrollAssignedTo: null,
      payrollAssignedToEmail: null,
      payrollComments: null,
      payrollAssignedToDisplay: null,

      commercialhrApproval: null,
      commercialhrAssignedTo: null,
      commercialhrAssignedToEmail: null,
      commercialhrComments: null,
      commercialhrAssignedToDisplay: null,

      billablehrApproval: null,
      billablehrAssignedTo: null,
      billablehrAssignedToEmail: null,
      billablehrComments: null,
      billablehrAssignedToDisplay: null,

      /*Approval workflow*/
      gridRows: [],
      gridColumns: [],

      roles: '',
      isCancelVisible: false,
      isNavigateToApprovalQueue: false,
      loggedInAs: { EmpId: '', DisplayName: '', Email: '', DisplayEmail: null }
    };
    this.RequestStatus = {
      PendingHR: requestStatus.pendingCommercialHR,
      RejectHR: requestStatus.rejectToSubmitter,
      PendingBillableHR: requestStatus.pendingBillableHR,
      RejectBillableHR: requestStatus.rejectToSubmitter,
      PendingAccount: requestStatus.pendingAccounting,
      RejectAccount: requestStatus.rejectToSubmitter,
      PendingFinance: requestStatus.pendingFinance,
      RejectFinance: requestStatus.rejectToSubmitter,
      PendingESG: requestStatus.pendingESG,
      PendingPayroll: requestStatus.pendingPayroll,
      Complete: requestStatus.complete
    };
    this.LoggedInUser = {
    }
    this.roles = {}
    this.userRoles = userRoles;
    //const { instance } = useMsal();
    //const currentLoggedInUser = instance.getActiveAccount();
  }

  handleEmployeeSelected = (employee) => {
    this.setState({
      SelectedEmployee: employee,
    }, () => this.setFormValidationStatus());
  };
  /*   End - Employee Drop Down Events    */

  /*  Start - Page Event  */
  componentDidMount = () => {
    const start = new Date().getTime();
    const autAccount = this.context.accounts[0];
    if (autAccount && autAccount.name)
      this.LoggedInUser = { DisplayName: autAccount.name, UserName: autAccount.username, IsSubmitter: false, IsInAnyRole: false, IsAdminRole: false }
    try {
      let roles = [];
      const userAccess = JSON.parse(localStorage.getItem("UserAccess"));
      if (userAccess) {
        var isESFOwner = false;
        if (userAccess.roles.some(role => (role.name === this.userRoles.esfOwners))) { isESFOwner = true; }
        this.setState({ roles: userAccess.roles, isESFOwner: isESFOwner });
        this.roles = userAccess.roles;
        console.log("My Roles", this.roles);
        if (userAccess.employeeID && userAccess.employeeID.length > 0 && userAccess.employeeID[0].employeeId) {
          this.LoggedInUser.EmployeeID = userAccess.employeeID[0].employeeId;
        }
      }
    } catch (err) {
      console.log(err);
    }

    //"00203655"; Class:EXT-- Company:MSU--Business Unit:81
    //"05378281"; Class:CNS-- Company:MSC--Business Unit:83
    //"02507999"; Class:CNS-- Company:MSU--Business Unit:83
    //"00210115"; Class:INT-- Company:TEM--Business Unit:1
    //"03806371"; Class:INT-- Company:MSU--Business Unit:81
    //06983234 Russel,Erica ; Brice, Jessica MSU - BU 82
    //06146962 06983234 Russel,Erica ; Brice, Jessica MSU - BU 82
    this.getDropdownOptions().then((result) => {
      const actionReason = result.ResultSets.Table1?.map((val) => {
        return { key: val.ActionReason, text: val.ActionReason };
      });
      this.setState({ reasonForPayChangeOptions: [...[{ key: "--Choose Reason--", text: "--Choose Reason--" }], ...actionReason] });
    });

    this.getBarerToken().then((token) => {
      var empID = this.LoggedInUser.EmployeeID ? this.LoggedInUser.EmployeeID : "" //: "02507999" //For Production "02507999"
      //empID = "00203655" // For Dev Only remove for prod;
      if (getTemporaryEmpId(this.LoggedInUser.UserName)) {
        empID = getTemporaryEmpId(this.LoggedInUser.UserName);
      };
      //empID = "05378281"; // For Dev Only remove for prod;

      /* Login as other user*/
      if (this.state.loggedInAs && this.state.loggedInAs.EmpId) {
        empID = this.state.loggedInAs.EmpId;
      }

      this.getSubordinates(empID, token).then((empArr) => {
        empArr.forEach(emp => {
          if (emp.isSupervisor === "Y") {
            this.getSubordinates(emp.EmployeeID, token).then((empArr) => {
              emp.subordinates = empArr;
            })
          }
        });
        this.setState({ Employees: empArr });
      });

      if (window.location.href.indexOf('/compensation-change/') > 0) {
        var reqid = window.location.href.toString().substring(window.location.href.lastIndexOf('/compensation-change/') + '/compensation-change/'.length);
        if (reqid) {
          //this.validateUserAccess();
          //if (this.LoggedInUser.IsSubmitter || this.LoggedInUser.IsInAnyRole || this.LoggedInUser.IsAdminRole) {
          this.getRequestByRequestId(token, reqid).then(() => {
            if (this.LoggedInUser.IsSubmitter || this.LoggedInUser.IsInAnyRole || this.LoggedInUser.IsAdminRole) {
              this.getWorkflowHistory(reqid);
            }

          });
          //}


        }
      }
    })

    if (appInsights) {
      appInsights.trackEvent({
        name: `Page Load Compensation Change`,
        properties: {
          TimeTaken: `${(new Date().getTime() - start) / 1000} s`,
        },
      });
    }

  };
  /*  End - Page Event  */

  /*  Start-Component Change SetStates  */
  setComponentStatus = (e) => {
    if (e) {
      switch (e.target.name) {

        case "reasonForPayChange":
          this.setState({ ReasonForPayChange: e.target.value }, () => this.setFormValidationStatus());
          break;

        case "effectiveDate":
          try {
            if (this.validateSunday(moment(e.target.value).format("M/D/YYYY"))) {
              this.setState({
                EffectiveDate: moment(e.target.value).format("M/D/YYYY"),
                dateLblClass: "labelNormal"
              }, () => this.setFormValidationStatus());
            }
            else {
              this.setState({
                EffectiveDate: null,
                dateLblClass: "labelError"
              }, () => this.setFormValidationStatus());
            }
          } catch (err) {
            alert('Invalid effective date');
            this.setState({
              EffectiveDate: null,
              dateLblClass: "labelError"
            }, () => this.setFormValidationStatus());
          }
          break;

        case "bonusFrequency":
          this.setState({ BonusFrequency: e.target.value });
          if (
            e.target.value === "--Choose Frequency--" ||
            e.target.value === "Not Eligible"
          ) {
            this.setState({ showBonus: false, AnnualizedBonusPotential: 0 }, () => this.setFormValidationStatus());
          } else {
            this.setState({ showBonus: true }, () => this.setFormValidationStatus());
          }
          break;

        case "annualBonus":

          if (parseFloat(e.target.value) <= parseFloat("9999999.99")) {
            this.setState({ AnnualizedBonusPotential: e.target.value, AnnualizedBonusPotentialError: false }, () => this.setFormValidationStatus());

          } else {
            this.setState({ AnnualizedBonusPotential: e.target.value, AnnualizedBonusPotentialError: true }, () => this.setFormValidationStatus());
          }

          break;
        case "yourPhoneNumber":
          this.setState({ YourPhoneNumber: e.target.value }, () => this.setFormValidationStatus());
          break;
        case "addAnyAdditionalComments":
          this.setState({ AdditionalComments: e.target.value }, () => this.setFormValidationStatus());
          break;
        case "payRate":
          if (this.state.HowIsPayEntered === "Hourly" && parseFloat(e.target.value) <= parseFloat("999.99")) {
            this.setState({ PayRate: e.target.value, payRateError: false }, () => this.setFormValidationStatus());
          } else if (this.state.HowIsPayEntered === "Hourly" && parseFloat(e.target.value) > parseFloat("999.99")) {
            this.setState({ PayRate: e.target.value, payRateError: true }, () => this.setFormValidationStatus());
          } else if (this.state.HowIsPayEntered === "Salary" && parseFloat(e.target.value) < parseFloat("10000")) {
            this.setState({ PayRate: e.target.value, payRateError: true }, () => this.setFormValidationStatus());
          } else if (this.state.HowIsPayEntered === "Salary" && parseFloat(e.target.value) > parseFloat("999999.99")) {
            this.setState({ PayRate: e.target.value, payRateError: true }, () => this.setFormValidationStatus());
          } else {
            this.setState({ PayRate: e.target.value, payRateError: false }, () => this.setFormValidationStatus());
          }

          break;
        case "commercialhrassignto":
          var email = e.detail.length > 0 ? (e.detail[0].mail ? e.detail[0].mail : e.detail[0].userPrincipalName) : undefined;
          var displayName = e.detail.length > 0 ? (e.detail[0].displayName ? e.detail[0].displayName : undefined) : undefined;
          if (!email) {
            this.setState({ commercialhrAssignedToDisplay: undefined })
          }
          this.setState({
            commercialhrAssignedTo: displayName,
            commercialhrAssignedToEmail: email,

          }, () => this.setFormValidationStatus());
          break;
        case "commercialhrapprovalstatus":
          this.setState({ commercialhrApproval: e.target.value }, () => this.setFormValidationStatus());
          break;
        case "commercialhraddcomm":
          this.setState({ commercialhrComments: e.target.value }, () => this.setFormValidationStatus());
          break;

        case "billablehrassignto":
          var email = e.detail.length > 0 ? (e.detail[0].mail ? e.detail[0].mail : e.detail[0].userPrincipalName) : undefined;
          var displayName = e.detail.length > 0 ? (e.detail[0].displayName ? e.detail[0].displayName : undefined) : undefined;
          if (!email) {
            this.setState({ billablehrAssignedToDisplay: undefined })
          }
          this.setState({
            billablehrAssignedTo: displayName,
            billablehrAssignedToEmail: email,

          }, () => this.setFormValidationStatus());
          break;
        case "billablehrapprovalstatus":
          this.setState({ billablehrApproval: e.target.value }, () => this.setFormValidationStatus());
          break;
        case "billablehraddcomm":
          this.setState({ billablehrComments: e.target.value }, () => this.setFormValidationStatus());
          break;

        case "accountingassignto":
          var email = e.detail.length > 0 ? (e.detail[0].mail ? e.detail[0].mail : e.detail[0].userPrincipalName) : undefined;
          var displayName = e.detail.length > 0 ? (e.detail[0].displayName ? e.detail[0].displayName : undefined) : undefined;
          if (!email) {
            this.setState({ accountingAssignedToDisplay: undefined })
          }
          this.setState({
            accountingAssignedTo: displayName,
            accountingAssignedToEmail: email,
          }, () => this.setFormValidationStatus());
          break;
        case "accountingaddcomm":
          this.setState({ accountingComments: e.target.value }, () => this.setFormValidationStatus());
          break;
        case "accountingapprovalstatus":
          this.setState({ accountingApproval: e.target.value }, () => this.setFormValidationStatus());
          break;
        case "financeapprovalstatus":
          this.setState({ financeApproval: e.target.value }, () => this.setFormValidationStatus());
          break;
        case "financeassignto":
          var email = e.detail.length > 0 ? (e.detail[0].mail ? e.detail[0].mail : e.detail[0].userPrincipalName) : undefined;
          var displayName = e.detail.length > 0 ? (e.detail[0].displayName ? e.detail[0].displayName : undefined) : undefined;
          if (!email) {
            this.setState({ financeAssignedToDisplay: undefined })
          }
          this.setState({
            financeAssignedTo: displayName,
            financeAssignedToEmail: email,
          }, () => this.setFormValidationStatus());
          break;
        case "financeaddcomm":
          this.setState({ financeComments: e.target.value }, () => this.setFormValidationStatus());
          break;
        case "esgapprovalstatus":
          this.setState({ esgApproval: e.target.value }, () => this.setFormValidationStatus());
          break;
        case "esgassignto":
          var email = e.detail.length > 0 ? (e.detail[0].mail ? e.detail[0].mail : e.detail[0].userPrincipalName) : undefined;
          var displayName = e.detail.length > 0 ? (e.detail[0].displayName ? e.detail[0].displayName : undefined) : undefined;
          if (!email) {
            this.setState({ esgAssignedToDisplay: undefined })
          }
          this.setState({
            esgAssignedTo: displayName,
            esgAssignedToEmail: email,
          }, () => this.setFormValidationStatus());
          break;
        case "esgaddcomm":
          this.setState({ esgComments: e.target.value }, () => this.setFormValidationStatus());
          break;
          case "additionalPayrollCommName":
            this.setState({ esgPayrollComments: e.target.value }, () => this.setFormValidationStatus());
            break;
        case "payrollapprovalstatus":
          this.setState({ payrollApproval: e.target.value }, () => this.setFormValidationStatus());
          break;
        case "payrollassignto":
          var email = e.detail.length > 0 ? (e.detail[0].mail ? e.detail[0].mail : e.detail[0].userPrincipalName) : undefined;
          var displayName = e.detail.length > 0 ? (e.detail[0].displayName ? e.detail[0].displayName : undefined) : undefined;
          if (!email) {
            this.setState({ payrollAssignedToDisplay: undefined })
          }
          this.setState({
            payrollAssignedTo: displayName,
            payrollAssignedToEmail: email,
          }, () => this.setFormValidationStatus());
          break;
        case "payrolladdcomm":
          this.setState({ payrollComments: e.target.value }, () => this.setFormValidationStatus());
          break;

      }
    }

  };

  async getDropdownOptions() {
    return postValuesApi(
      process.env.REACT_APP_ESF_GETCHOICEVALUES,
      {
        RelatedProcess: "Compensation Change",
      }
    );
  };

  setFormValidationStatus() {
    if (this.state.ReasonForPayChange === "--Choose Reason--"
      || this.state.EffectiveDate === null || this.state.EffectiveDate === undefined
      || this.state.BonusFrequency === "--Choose Frequency--" ||
      this.state.AnnualizedBonusPotential === undefined || this.state.AnnualizedBonusPotential === ""
      || this.state.PayRate === null || this.state.PayRate === undefined || this.state.PayRate === "" || parseFloat(this.state.PayRate) <= parseFloat("0")
      || this.state.SelectedEmployee === null || this.state.SelectedEmployee === undefined
      || parseFloat(this.state.AnnualizedBonusPotential) > parseFloat("9999999.99")
      || this.state.payRateError
    ) {

      var AnnualizedBonusPotential = this.state.AnnualizedBonusPotential;
      if (this.state.BonusFrequency === "Not Eligible") { AnnualizedBonusPotential = 0; }
      this.setState({ isFormValid: false, isUpdateFormValid: false, AnnualizedBonusPotential: AnnualizedBonusPotential });

    }
    else {
      var isFormValid = true, isUpdateFormValid = false;
      var AnnualizedBonusPotential = this.state.AnnualizedBonusPotential;
      if (this.state.BonusFrequency === "Not Eligible") { AnnualizedBonusPotential = 0; }

      if (this.state.requestId) // In Approvals or Existing Request
      {
        if (this.state.RequestStatus === this.RequestStatus.PendingHR) {
          if (this.state.commercialhrApproval === "Pending Review" || this.state.commercialhrApproval === null) { isUpdateFormValid = true; }
          else if (this.state.commercialhrAssignedTo !== null && this.state.commercialhrAssignedTo !== undefined && this.state.commercialhrAssignedTo !== ""
            // && this.state.commercialhrApproval !== "Pending Review"
          ) {
            isUpdateFormValid = true;
          }
        }
        if (this.state.RequestStatus === this.RequestStatus.PendingBillableHR) {
          if (this.state.billablehrApproval === "Pending Review" || this.state.billablehrApproval === null) { isUpdateFormValid = true; }
          else if (this.state.billablehrAssignedTo !== null && this.state.billablehrAssignedTo !== undefined && this.state.billablehrAssignedTo !== ""
            // && this.state.commercialhrApproval !== "Pending Review"
          ) {
            isUpdateFormValid = true;
          }
        }
        if (this.state.RequestStatus === this.RequestStatus.PendingAccount) {
          if (this.state.accountingApproval === "Pending Review" || this.state.accountingApproval === null) { isUpdateFormValid = true; }
          else if (this.state.accountingAssignedTo !== null && this.state.accountingAssignedTo !== undefined && this.state.accountingAssignedTo !== ""
            // && this.state.accountingApproval !== "Pending Review"
          ) {
            isUpdateFormValid = true;
          }
        }
        if (this.state.RequestStatus === this.RequestStatus.PendingFinance) {
          if (this.state.financeApproval === "Pending Review" || this.state.financeApproval === null) { isUpdateFormValid = true; }
          else if (this.state.financeAssignedTo !== null && this.state.financeAssignedTo !== undefined && this.state.financeAssignedTo !== ""
            // && this.state.financeApproval !== "Pending Review"
          ) {
            isUpdateFormValid = true;
          }
        }
        if (this.state.RequestStatus === this.RequestStatus.PendingESG) {
          if (this.state.esgApproval === "Pending Review" || this.state.esgApproval === null) { isUpdateFormValid = true; }
          else if (this.state.esgAssignedTo !== null && this.state.esgAssignedTo !== undefined && this.state.esgAssignedTo !== ""
            // && this.state.esgApproval !== "Pending Review"
          ) {
            isUpdateFormValid = true;
          }
        }
        if (this.state.RequestStatus === this.RequestStatus.PendingPayroll) {
          if (this.state.payrollApproval === "Pending Review" || this.state.payrollApproval === null) { isUpdateFormValid = true; }
          else if (this.state.payrollAssignedTo !== null && this.state.payrollAssignedTo !== undefined && this.state.payrollAssignedTo !== ""
            // && this.state.payrollApproval !== "Pending Review"
          ) {
            isUpdateFormValid = true;
          }
        }
      }
      this.setState({ isFormValid: isFormValid, isUpdateFormValid: isUpdateFormValid, AnnualizedBonusPotential: AnnualizedBonusPotential });
    }


  }

  setStateHowIsPayEntered(checked) {

    if (checked != null && checked != undefined) {


      if (checked) {
        var payRateError = false;
        if (parseFloat(this.state.PayRate) < parseFloat("10000") || parseFloat(this.state.PayRate) > parseFloat("999,999.99")) { payRateError = true }
        this.setState({
          HowIsPayEntered: "Salary", payRateError: payRateError
        }, () => this.setFormValidationStatus());
      } else {
        if (parseFloat(this.state.PayRate) > parseFloat("999.99")) {
          this.setState({
            HowIsPayEntered: "Hourly",
            payRateError: true,
            isFormValid: false
          }, () => this.setFormValidationStatus());
        } else {
          this.setState({
            HowIsPayEntered: "Hourly",
            payRateError: false,
          }, () => this.setFormValidationStatus());

        }
      }
    }
    /*if (ev) {
      if (ev.trim().toLowerCase() === "salary") {
        this.setState({
          HowIsPayEntered: "Salary",
 
        });
      } else {
        this.setState({
          HowIsPayEntered: "Hourly",
 
        });
      }
    }*/

  }

  setStateTextField(val) {


    this.setState({ AnnualizedBonusPotential: val.target.value })

  }

  /* End-Component Change SetStates */

  /*   Start - DB and API Operations  */
  handleSubmitToSave = async (action) => {
    if (action === "create") {
      const start = new Date().getTime();
      this.setState({ goToHome: false });
      var validation = this.handleValidateForm();
      if (validation.status) {

        var requestId = this.state.ReasonForPayChange
          ? Math.floor(Math.random() * 1677721555).toString(16)
          : "";
        let compChangeData = {
          actionReason: this.state.ReasonForPayChange,
          effectiveDate: this.state.EffectiveDate,
          compensationType: this.state.HowIsPayEntered,
          bonusFrequency: this.state.BonusFrequency,
          bonusPotential: this.state.AnnualizedBonusPotential,
          submitterPhone: this.state.YourPhoneNumber,
          actionComments: this.state.AdditionalComments ? this.state.AdditionalComments : "",
          payRate: this.state.PayRate,
          requestStatus: "",

          empClass: this.state.SelectedEmployee.empClass,
          empCompany: this.state.SelectedEmployee.CompanyID,
          //empEmail: this.state.SelectedEmployee.empEmail,
          empEmail: this.state.SelectedEmployee.EmailPreferred,
          //empHireDate: this.state.SelectedEmployee.empHireDate,
          empHireDate: this.state.SelectedEmployee.HireDate,
          empId: this.state.SelectedEmployee.empID,
          empLocation: this.state.SelectedEmployee.HomeState,
          empName: this.state.SelectedEmployee.Name,
          empPosition: this.state.SelectedEmployee.empPosition,
          empProgram: this.state.SelectedEmployee.empProgram,
          empState: this.state.SelectedEmployee.HomeState,
          empSupervisor: this.state.SelectedEmployee.empSupervisor,
          empBusinessUnit: this.state.SelectedEmployee.empBusinessUnit,
          Title: this.state.SelectedEmployee.JobDescription,
          Created: getFormattedDate(new Date()),//moment(new Date()).format("M/D/YYYY"),//new Date(),//
          dateSubmitted: getFormattedDate(new Date()),//moment(new Date()).format("M/D/YYYY"),

          submitterEmail: this.LoggedInUser.UserName,
          submitterName: this.LoggedInUser.DisplayName,
          CreatedBy: this.LoggedInUser.DisplayName,
          ModifiedBy: this.LoggedInUser.DisplayName,
          requestId: requestId,

          /*Login AS*/
          loginAsEmpId: this.state.loggedInAs.EmpId,
          loggedInAsDisplayName: this.state.loggedInAs.DisplayName,
          loggedInAsEmail: this.state.loggedInAs.Email
        };

        /*If Class Int goto Accounting*/
        if (this.state.SelectedEmployee.empClass.toString().toLowerCase().trim() === "int") {
          //compChangeData.accountingApproval = "Pending Review";
          compChangeData.requestStatus = this.RequestStatus.PendingAccount;
        }/*if Not Int and Business Unit 83 assign to Commericial HR*/
        else if (compChangeData.empBusinessUnit.toString() === "83") {
          //compChangeData.commercialhrApproval = "Pending Review";
          compChangeData.requestStatus = this.RequestStatus.PendingHR;

        } else if (compChangeData.empBusinessUnit.toString() === "82") {
          //compChangeData.commercialhrApproval = "Pending Review";
          compChangeData.requestStatus = this.RequestStatus.PendingBillableHR;

        } else /*if Not Int and Bot Business Unit 83 assign to Finance*/ {
          //compChangeData.financeApproval = "Pending Review";
          compChangeData.requestStatus = this.RequestStatus.PendingFinance;
        }


        try {
          var SaveURL = process.env.REACT_APP_ESF_COMPENSATIONCHANGE;
          let compCreationResponse = await fetch(SaveURL, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              Method: "CREATE",
              CompensationChangeData: compChangeData,
              requestId: requestId,
            }),
          });
          let creationStaus = await compCreationResponse.json();
          if (creationStaus.ResultSets?.Table1?.[0]?.CREATEspRESULT) {
            Swal.fire({
              title: "Something went wrong...",
              html: creationStaus.ResultSets?.Table1?.[0]?.CREATEspRESULT,
              icon: "error",
            });
            console.error(creationStaus.error);
          } else {
            if (!Object.keys(creationStaus).includes('error')) {
              Swal
                .fire({
                  title: "Submitted Successfully",
                  text: "Your request has been submitted and is pending for applicable approvals",
                  icon: "success",
                  html: `<a href="/" >Go to Home</a>`,
                  // showCancelButton: true,
                  confirmButtonText: "View Details",
                  //cancelButtonText: "No, cancel!",
                  // reverseButtons: true,
                })
                .then(async (result) => {
                  if (result.isConfirmed) {
                    this.setState({
                      goToHome: true

                    });
                  }
                });
            }

          }
          //console.log(creationStaus);
          //alert("Saved Successfully");
          //window.location.href = "compensation-change/SuccessError/" + requestId;
          this.setState({
            //saveStatus: "success",
            requestId: requestId,

          });
          document.getElementById("lblError").innerHTML = "";

          if (appInsights) {
            appInsights.trackEvent({
              name: `Page Submit Compensation Change`,
              properties: {
                TimeTaken: `${(new Date().getTime() - start) / 1000} s`,
              },
            });
          }

        } catch (err) {
          console.log(err);
          //window.location.href = "compensation-change/SuccessError/0";
          document.getElementById("lblError").innerHTML = "Technical issue, please try after sometime or contact administrator";
          this.setState({
            //saveStatus: "error",
            requestId: 0,
          });
        }
      } else {
        document.getElementById("lblError").innerHTML = validation.message;
      }
    } else if (action === "resubmit") {
      this.handleSubmitToUpdate(action);
    }
  };

  handleSubmitToUpdate = async (approvingFor) => {
    const start = new Date().getTime();
    this.setState({ goToHome: false });
    var validation = this.handleValidateForm();
    var validationApprovals = this.handleApprovalValidation(approvingFor);
    if (validation.status && validationApprovals.status) {
      var accountingApproval = null, financeApproval = null, esgApproval = null, payrollApproval = null, requestStatus = null, actionComments = null;
      var dateApprovedByAccountingFinance = this.state.dateApprovedByAccountingFinance, completedDate = this.state.completedDate, dateApprovedByESG = this.state.dateApprovedByESG;
      /*Approval by ACC or Fin*/
      if ((this.state.accountingApproval === "Approve" && this.state.RequestStatus === this.RequestStatus.PendingAccount) ||
        (this.state.financeApproval === "Approve" && this.state.RequestStatus === this.RequestStatus.PendingFinance)) {
        /* If Company MSC goto Payroll Canada or else ESG*/
        dateApprovedByAccountingFinance = getFormattedDate(new Date());//new Date();
        if (this.state.SelectedEmployee.CompanyID.trim().toLowerCase() === "msc") {
          //payrollApproval = "Pending Review";
          requestStatus = this.RequestStatus.PendingPayroll;
        } else {
          //esgApproval = "Pending Review";
          requestStatus = this.RequestStatus.PendingESG;
        }
        //actionComments = (this.state.RequestStatus === this.RequestStatus.PendingAccount) ? this.state.accountingComments : null;
        //actionComments = (this.state.RequestStatus === this.RequestStatus.PendingFinance) ? this.state.financeComments : null;

      } else if (this.state.accountingApproval === "Reject to Submitter" && this.state.RequestStatus === this.RequestStatus.PendingAccount) {
        requestStatus = this.RequestStatus.RejectAccount;
        //actionComments = this.state.accountingComments;

      } else if (this.state.financeApproval === "Reject to Submitter" && this.state.RequestStatus === this.RequestStatus.PendingFinance) {
        requestStatus = this.RequestStatus.RejectFinance;
        //actionComments = this.state.financeComments;
      }
      /* Assigned to HR and HR Approving - Business Unit 83 and empClass Non Int*/
      if (this.state.commercialhrApproval === "Approve" && this.state.RequestStatus === this.RequestStatus.PendingHR) {
        //financeApproval = "Pending Review";
        requestStatus = this.RequestStatus.PendingFinance;
        //actionComments = this.state.commercialhrComments;
      } else if (this.state.commercialhrApproval === "Reject to Submitter" && this.state.RequestStatus === this.RequestStatus.PendingHR) {
        requestStatus = this.RequestStatus.RejectHR;
        //actionComments = this.state.commercialhrComments;
      }

      /* Assigned to Billable HR and HR Approving - Business Unit 82 and empClass Non Int*/
      if (this.state.billablehrApproval === "Approve" && this.state.RequestStatus === this.RequestStatus.PendingBillableHR) {
        //financeApproval = "Pending Review";
        requestStatus = this.RequestStatus.PendingFinance;
        //actionComments = this.state.commercialhrComments;
      } else if (this.state.billablehrApproval === "Reject to Submitter" && this.state.RequestStatus === this.RequestStatus.PendingBillableHR) {
        requestStatus = this.RequestStatus.RejectBillableHR;
        //actionComments = this.state.commercialhrComments;
      }

      /*ESG Approval Approve and Submit to pay roll or else approve and complete*/
      if (this.state.esgApproval === "Approve & Complete" && this.state.RequestStatus === this.RequestStatus.PendingESG) {
        requestStatus = this.RequestStatus.Complete;
        esgApproval = this.state.esgApproval;
        dateApprovedByESG = getFormattedDate(new Date());
        completedDate = getFormattedDate(new Date());
        //actionComments = this.state.esgComments;
      } else if (this.state.esgApproval === "Approve & Submit to Payroll" && this.state.RequestStatus === this.RequestStatus.PendingESG) {
        requestStatus = this.RequestStatus.PendingPayroll;
        //payrollApproval = "Pending Review";
        esgApproval = this.state.esgApproval
        dateApprovedByESG = getFormattedDate(new Date());
        //actionComments = this.state.esgComments;
      }

      /*Payroll Complete*/
      if (this.state.payrollApproval === "Complete" && this.state.RequestStatus === this.RequestStatus.PendingPayroll) {
        requestStatus = this.RequestStatus.Complete;
        payrollApproval = this.state.payrollApproval;
        completedDate = getFormattedDate(new Date());
        //actionComments = this.state.payrollComments;
      }

      //Resubmit with out status update
      if (requestStatus === null || requestStatus === undefined || requestStatus === "")
        requestStatus = this.state.RequestStatus;

      accountingApproval = accountingApproval ? accountingApproval : this.state.accountingApproval;
      financeApproval = financeApproval ? financeApproval : this.state.financeApproval;
      esgApproval = esgApproval ? esgApproval : this.state.esgApproval;
      payrollApproval = payrollApproval ? payrollApproval : this.state.payrollApproval;

      try {
        var SaveURL = process.env.REACT_APP_ESF_COMPENSATIONCHANGE;
        var compUpdateData = {
          Method: "UPDATE",
          CompensationChangeData: {
            actionReason: this.state.ReasonForPayChange,
            effectiveDate: this.state.EffectiveDate,
            compensationType: this.state.HowIsPayEntered,
            bonusFrequency: this.state.BonusFrequency,
            bonusPotential: this.state.AnnualizedBonusPotential,
            submitterPhone: this.state.YourPhoneNumber,
            submitterName: this.state.submitterName,
            submitterEmail: this.state.submitterEmail,
            dateSubmitted: this.state.dateSubmitted,
            actionComments: this.state.AdditionalComments ? this.state.AdditionalComments : "",
            payRate: this.state.PayRate,

            empClass: this.state.SelectedEmployee.empClass,
            empCompany: this.state.SelectedEmployee.CompanyID,
            empEmail: this.state.SelectedEmployee.EmailPreferred,
            empHireDate: this.state.SelectedEmployee.HireDate,
            empId: this.state.SelectedEmployee.empID,
            empLocation: this.state.SelectedEmployee.HomeState,
            empName: this.state.SelectedEmployee.Name,
            empPosition: this.state.SelectedEmployee.empPosition,
            empProgram: this.state.SelectedEmployee.empProgram,
            empState: this.state.SelectedEmployee.HomeState,
            empSupervisor: this.state.SelectedEmployee.empSupervisor,
            empBusinessUnit: this.state.SelectedEmployee.empBusinessUnit,
            Title: this.state.SelectedEmployee.JobDescription,
            Modified: getFormattedDate(new Date()),//new Date(), //moment(new Date()).format("M/D/YYYY"),
            //Status values
            commercialhrApproval: (approvingFor === "hr" && !this.state.commercialhrApproval) ? "Pending Review" : this.state.commercialhrApproval,
            commercialhrAssignedTo: this.state.commercialhrAssignedTo,
            commercialhrAssignedToEmail: this.state.commercialhrAssignedToEmail,
            commercialhrComments: this.state.commercialhrComments,

            //Status values
            billablehrApproval: (approvingFor === "billablehr" && !this.state.billablehrApproval) ? "Pending Review" : this.state.billablehrApproval,
            billablehrAssignedTo: this.state.billablehrAssignedTo,
            billablehrAssignedToEmail: this.state.billablehrAssignedToEmail,
            billablehrComments: this.state.billablehrComments,

            accountingApproval: (approvingFor === "accounting" && !this.state.accountingApproval) ? "Pending Review" : this.state.accountingApproval,//accountingApproval,
            accountingAssignedTo: this.state.accountingAssignedTo,
            accountingAssignedToEmail: this.state.accountingAssignedToEmail,
            accountingComments: this.state.accountingComments,

            financeApproval: (approvingFor === "finance" && !this.state.financeApproval) ? "Pending Review" : this.state.financeApproval,// financeApproval,
            financeAssignedTo: this.state.financeAssignedTo,
            financeAssignedToEmail: this.state.financeAssignedToEmail,
            financeComments: this.state.financeComments,
            dateApprovedByAccountingFinance: dateApprovedByAccountingFinance,

            esgApproval: (approvingFor === "esg" && !this.state.esgApproval) ? "Pending Review" : this.state.esgApproval,// esgApproval,
            esgAssignedTo: this.state.esgAssignedTo,
            esgAssignedToEmail: this.state.esgAssignedToEmail,
            esgComments: this.state.esgComments,
            esgPayrollComments: this.state.esgPayrollComments,
            dateApprovedByESG: dateApprovedByESG,

            payrollApproval: (approvingFor === "payroll" && !this.state.payrollApproval) ? "Pending Review" : this.state.payrollApproval,// payrollApproval,
            payrollAssignedTo: this.state.payrollAssignedTo,
            payrollAssignedToEmail: this.state.payrollAssignedToEmail,
            payrollComments: this.state.payrollComments,
            requestStatus: requestStatus,

            requestId: this.state.requestId,
            ModifiedBy: this.LoggedInUser.DisplayName,
            completedDate: completedDate

          },
          requestId: this.state.requestId,
        };

        if (approvingFor === "resubmit") {
          /*resubmit rest all approvals to undefine*/
          var SetBlank = undefined;
          compUpdateData.CompensationChangeData.commercialhrApproval = SetBlank;
          compUpdateData.CompensationChangeData.commercialhrAssignedTo = SetBlank;
          compUpdateData.CompensationChangeData.commercialhrAssignedToEmail = SetBlank;
          compUpdateData.CompensationChangeData.commercialhrComments = SetBlank;

          compUpdateData.CompensationChangeData.billablehrApproval = SetBlank;
          compUpdateData.CompensationChangeData.billablehrAssignedTo = SetBlank;
          compUpdateData.CompensationChangeData.billablehrAssignedToEmail = SetBlank;
          compUpdateData.CompensationChangeData.billablehrComments = SetBlank;

          compUpdateData.CompensationChangeData.accountingApproval = SetBlank;
          compUpdateData.CompensationChangeData.accountingAssignedTo = SetBlank;
          compUpdateData.CompensationChangeData.accountingAssignedToEmail = SetBlank;
          compUpdateData.CompensationChangeData.accountingComments = SetBlank;

          compUpdateData.CompensationChangeData.financeApproval = SetBlank;
          compUpdateData.CompensationChangeData.financeAssignedTo = SetBlank;
          compUpdateData.CompensationChangeData.financeAssignedToEmail = SetBlank;
          compUpdateData.CompensationChangeData.financeComments = SetBlank;
          compUpdateData.CompensationChangeData.dateApprovedByAccountingFinance = SetBlank;

          compUpdateData.CompensationChangeData.esgApproval = SetBlank;
          compUpdateData.CompensationChangeData.esgAssignedTo = SetBlank;
          compUpdateData.CompensationChangeData.esgAssignedToEmail = SetBlank;
          compUpdateData.CompensationChangeData.esgComments = SetBlank;
          compUpdateData.CompensationChangeData.esgPayrollComments = SetBlank;
          compUpdateData.CompensationChangeData.dateApprovedByESG = SetBlank;

          compUpdateData.CompensationChangeData.payrollApproval = SetBlank;
          compUpdateData.CompensationChangeData.payrollAssignedTo = SetBlank;
          compUpdateData.CompensationChangeData.payrollAssignedToEmail = SetBlank;
          compUpdateData.CompensationChangeData.payrollComments = SetBlank;
          compUpdateData.CompensationChangeData.requestStatus = SetBlank;
          /*If Class Int goto Accounting*/
          if (this.state.SelectedEmployee.empClass.toString().toLowerCase().trim() === "int") {
            //ompUpdateData.CompensationChangeData.accountingApproval = "Pending Review";
            compUpdateData.CompensationChangeData.requestStatus = this.RequestStatus.PendingAccount;
          }/*if Not Int and Business Unit 83 assign to Commericial HR*/
          else if (compUpdateData.CompensationChangeData.empBusinessUnit.toString() === "83") {
            //compUpdateData.CompensationChangeData.commercialhrApproval = "Pending Review";
            compUpdateData.CompensationChangeData.requestStatus = this.RequestStatus.PendingHR;

          }/*if Not Int and Business Unit 82 assign to Billable HR*/
          else if (compUpdateData.CompensationChangeData.empBusinessUnit.toString() === "82") {
            //compUpdateData.CompensationChangeData.commercialhrApproval = "Pending Review";
            compUpdateData.CompensationChangeData.requestStatus = this.RequestStatus.PendingBillableHR;

          } else /*if Not Int and Bot Business Unit 83 assign to Finance*/ {
            //compUpdateData.CompensationChangeData.financeApproval = "Pending Review";
            compUpdateData.CompensationChangeData.requestStatus = this.RequestStatus.PendingFinance;
          }
        }

        let compCreationResponse = await fetch(SaveURL, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(compUpdateData),
        });
        let creationStaus = await compCreationResponse.json();
        //console.log(creationStaus);


        document.getElementById("lblError").innerHTML = "";

        if (appInsights) {
          appInsights.trackEvent({
            name: `Page Update Compensation Change`,
            properties: {
              TimeTaken: `${(new Date().getTime() - start) / 1000} s`,
            },
          });
        }

        Swal
          .fire({
            title: "Submitted Successfully",
            text: "Your request has been submitted and is pending for applicable approvals",
            icon: "success",
            html: `<a href="${redirectUrl(this.state.roles)}" target="_blank">Go to Home</a>`,
            // showCancelButton: true,
            confirmButtonText: "View Details",
            //cancelButtonText: "No, cancel!",
            // reverseButtons: true,
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              this.setState({
                goToHome: true

              });
            }
          });
      } catch (err) {
        console.log(err);
        //window.location.href = "compensation-change/SuccessError/0";
        document.getElementById("lblError").innerHTML = "Technical issue, please try after sometime or contact administrator";
        this.setState({
          //saveStatus: "error",
          requestId: 0,
        });
      }
    } else {
      document.getElementById("lblError").innerHTML = validation.message + validationApprovals.message;
    }
  };

  handleValidateForm() {
    var errorMessage = "";
    if (this.state.SelectedEmployee === null) {
      errorMessage = errorMessage + "Select an employee <br/>";
    }
    if (
      this.state.ReasonForPayChange === "" ||
      this.state.ReasonForPayChange === "--Choose Reason--"
    ) {
      errorMessage = errorMessage + "Select reason for pay change<br/>";
    }
    if (this.state.EffectiveDate === "" || this.state.EffectiveDate == null) {
      errorMessage = errorMessage + "Invalid effective date<br/>";

    }
    if (parseFloat(this.state.PayRate) > parseFloat("999.99") && this.state.HowIsPayEntered === "Hourly") {
      errorMessage = errorMessage + "Pay rate should be less than 999.99, when pay enterd hourly.<br/>";
    } else if (parseFloat(this.state.PayRate) <= parseFloat("0.00")) {
      errorMessage = errorMessage + "Pay rate is required.<br/>";
    }
    if (
      this.state.BonusFrequency === "" ||
      this.state.BonusFrequency === "--Choose Frequency--"
    ) {
      errorMessage = errorMessage + "Select bonus frequency<br/>";
    }
    if (
      this.state.AnnualizedBonusPotential === "" ||
      this.state.AnnualizedBonusPotential === null
    ) {
      errorMessage = errorMessage + "Select annualized bonus potential<br/>";
    } else if (parseFloat(this.state.AnnualizedBonusPotential) > parseFloat("9999999.99")) {
      errorMessage = errorMessage + "Bonus Potential cannot be greater than seven digits (before the decimal place).<br/>";
    }

    if (errorMessage === "") {
      return { message: "", status: true };
    } else {
      return { message: errorMessage, status: false };
    }
  }

  handleApprovalValidation(approvalFor) {
    var errorMessage = "";
    switch (approvalFor) {
      case "hr":
        if (this.state.commercialhrApproval === "Pending Review" || this.state.commercialhrApproval === null || this.state.commercialhrApproval === undefined) { }
        else if (this.state.commercialhrAssignedTo === null || this.state.commercialhrAssignedTo === undefined || this.state.commercialhrAssignedTo === ""
          // || this.state.commercialhrApproval === "Pending Review"
        )
          errorMessage = errorMessage + "Please complete commericial hr's required fields'<br/>"
        break;
      case "accounting":
        if (this.state.accountingApproval === "Pending Review" || this.state.accountingApproval === null || this.state.accountingApproval === undefined) { }
        else if (this.state.accountingAssignedTo === null || this.state.accountingAssignedTo === undefined || this.state.accountingAssignedTo === ""
          // || this.state.accountingApproval === "Pending Review"
        )
          errorMessage = errorMessage + "Please complete accounting's required fields<br/>"
        break;
      case "finance":
        if (this.state.financeApproval === "Pending Review" || this.state.financeApproval === null || this.state.financeApproval === undefined) { }
        else if (this.state.financeAssignedTo === null || this.state.financeAssignedTo === undefined || this.state.financeAssignedTo === ""
          // || this.state.financeApproval === "Pending Review"
        )
          errorMessage = errorMessage + "Please complete fonance's required fields<br/>"
        break;
      case "esg":
        if (this.state.esgApproval === "Pending Review" || this.state.esgApproval === null || this.state.esgApproval === undefined) { }
        else if (this.state.esgAssignedTo === null || this.state.esgAssignedTo === undefined || this.state.esgAssignedTo === ""
          // || this.state.esgApproval === "Pending Review"
        )
          errorMessage = errorMessage + "Please complete esg's required fields<br/>"
        break;
      case "payroll":
        if (this.state.payrollApproval === "Pending Review" || this.state.payrollApproval === null || this.state.payrollApproval === undefined) { }
        else if (this.state.payrollAssignedTo === null || this.state.payrollAssignedTo === undefined || this.state.payrollAssignedTo === ""
          // || this.state.payrollApproval === "Pending Review"
        )
          errorMessage = errorMessage + "Please complete payroll's required fields<br/>"
        break;
      case "resubmit": // Approval VAlidation not requred for resubmit
        errorMessage = "";
        break;
    }
    if (errorMessage === "") {
      return { message: "", status: true };
    } else {
      return { message: errorMessage, status: false };
    }
  }

  validateSunday = (dat) => {
    try {
      dat = new Date(dat);
      var weekdays = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      if (weekdays[dat.getDay()] !== "Sunday") {
        return false;
      }
      else {
        return true;
      }
    } catch (err) {
      return false;
    }
  }

  validateUserAccess = (reqJsonValues) => {
    var IsSubmitter = false, IsInAnyRole = false, IsAdminRole = false;
    /* Is Logged in User is Submitter */
    if (this.LoggedInUser.UserName.trim().toLowerCase() === reqJsonValues.submitterEmail.trim().toLowerCase()) {
      IsSubmitter = true;
    }
    /* Is user is in any of the approval role*/
    if (this.roles.some(role => (role.name === this.userRoles.commercialHR)) || this.roles.some(role => (role.name === this.userRoles.hrBillable)) || this.roles.some(role => (role.name === this.userRoles.accounting)) ||
      this.roles.some(role => (role.name === this.userRoles.finance))
      || this.roles.some(role => (role.name === this.userRoles.esgInternal)) || this.roles.some(role => (role.name === this.userRoles.esgBillable))
      || this.roles.some(role => (role.name === this.userRoles.payrollInternal)) || this.roles.some(role => (role.name === this.userRoles.payrollBillable))
      || this.roles.some(role => (role.name === this.userRoles.payrollCanada))) {
      IsInAnyRole = true;
    }
    if (this.roles.some(role => (role.name === this.userRoles.esfOwners)) || this.roles.some(role => (role.name === this.userRoles.adminInternal))
      || this.roles.some(role => (role.name === this.userRoles.adminBillable))) {
      IsAdminRole = true;
    }
    this.LoggedInUser.IsSubmitter = IsSubmitter;
    this.LoggedInUser.IsInAnyRole = IsInAnyRole;
    this.LoggedInUser.IsAdminRole = IsAdminRole;
    console.log("LoggedInUser", this.LoggedInUser);
  }

  getSubordinates = async (employeeID, BearerToken) => {
    //00201436
    //var callURL = `https://allegismulesoft.allegisgroup.com/allegis-prod-employeesearch/v7/employees/search?SupervisorID=${employeeID}&EmployeeHRStatus=A&PersonOrganizationRelationship=EMP&Limit=350`;
    let employeeArr = [];
    try {


      var callURL = process.env.REACT_APP_ESF_DIRECT_REPORTEES.replace("{supervisorId}", employeeID);
      var response = await fetch(callURL, {
        method: "GET",
        crossDomain: true,
        headers: {
          authorization: BearerToken,
          accept: "application/json",
          "cache-control": "no-cache",
        },
      });

      var empsJson = await response.json();

      empsJson.employees.map((item) => {
        let obj = {
          empID: item.EmployeeID,
          EmployeeID: item.EmployeeID, // For Employee Searcg Drop down that was created later and needs different values
          businessTitle: item.JobDescription,
          JobDescription: item.JobDescription, // For Employee Searcg Drop down that was created later and needs different values
          //empBusinessUnit: item.DepartmentID, //It's not available in Response??
          empClass: item.EmployeeClass,//"TST",//
          EmployeeClass: item.EmployeeClass,//For Employee Control //"TST",//
          empBusinessUnit: item.ProductID, //item.ProductID, //item.ProductID,//
          CompanyID: item.CompanyID,//"MSC",//
          EmailPreferred: item.EmailPreferred,
          HireDate: item.HireDate,
          empId: item.EmployeeID,
          HomeState: item.HomeState,
          DepartmentDescription: item.DepartmentDescription,
          Name: item.Name,
          empPosition: item.JobDescription, //Not found
          empProgram: `${item.DepartmentDescription} - ${item.DepartmentID}`, //"", //Not found
          //empState: item.State,
          empSupervisor: item.SupervisorID,

          SupervisorID: item.SupervisorID,
          isSupervisor: item.IsSupervisor,
          subordinates: null
        };
        employeeArr.push(obj);

      });
    } catch (err) {
      console.log("getSubordinates - No Subbordinate found", err);
    }
    return employeeArr;

  }

  getBarerToken = async () => {
    const response = await postApi(process.env.REACT_APP_ESF_BEARERTOKEN, { ResourceID: process.env.REACT_APP_ESF_EMP_RESOURCEID });
    return "Bearer " + response.access_token;

  }

  getRequestByRequestId = async (token, requestid) => {

    try {
      let reqResponse = await fetch(process.env.REACT_APP_ESF_COMPENSATIONCHANGE, {
        method: "POST",
        headers:
        {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(
          {
            Method: "READ",
            requestID: requestid,
          }
        ),
      });
      let reqResponseJson = await reqResponse.json();

      let reqJsonValues = reqResponseJson.ResultSets.Table1[0];
      //console.log(reqJsonValues);

      var bonusVisible = (reqJsonValues.bonusFrequency !== "--Choose Frequency--" && reqJsonValues.bonusFrequency !== "Not Eligible") ? true : false;
      this.validateUserAccess(reqJsonValues);
      if (this.LoggedInUser.IsSubmitter || this.LoggedInUser.IsInAnyRole || this.LoggedInUser.IsAdminRole) {
        this.cancelVisibility(this.state.roles, reqJsonValues.requestStatus);
        this.setState({
          requestId: requestid,
          isEmployeeSelected: true,
          showBonus: bonusVisible,
          PayRate: reqJsonValues.payRate,
          dateLblClass: "labelError",
          goToHome: false,

          /*Form Values*/
          //SelectedEmployee: null,
          ReasonForPayChange: reqJsonValues.actionReason,
          EffectiveDate: reqJsonValues.effectiveDate,
          HowIsPayEntered: reqJsonValues.compensationType,

          BonusFrequency: reqJsonValues.bonusFrequency,
          AnnualizedBonusPotential: reqJsonValues.bonusPotential,
          YourPhoneNumber: reqJsonValues.submitterPhone,
          submitterName: reqJsonValues.submitterName,
          submitterEmail: reqJsonValues.submitterEmail,
          dateSubmitted: reqJsonValues.dateSubmitted,
          AdditionalComments: reqJsonValues.actionComments,
          BusinessUnit: reqJsonValues.empBusinessUnit,
          SelectedEmployee: {
            empID: reqJsonValues.empId,
            EmployeeID: reqJsonValues.empId, // For Employee Searcg Drop down that was created later and needs different values
            businessTitle: reqJsonValues.Title,
            JobDescription: reqJsonValues.Title, // For Employee Searcg Drop down that was created later and needs different values
            //empBusinessUnit: item.DepartmentID, //It's not available in Response??
            empClass: reqJsonValues.empClass,
            EmployeeClass: reqJsonValues.empClass,//For Employee Control
            CompanyID: reqJsonValues.empCompany,
            EmailPreferred: reqJsonValues.empEmail,
            HireDate: reqJsonValues.empHireDate,
            empLocation: reqJsonValues.empLocation,
            Name: reqJsonValues.empName,
            DepartmentDescription: reqJsonValues.empProgram.split(" - ")?.[0],
            empPosition: reqJsonValues.empPosition, //Not found
            empProgram: reqJsonValues.empProgram,
            HomeState: reqJsonValues.empState,
            empSupervisor: reqJsonValues.empSupervisor,
            empBusinessUnit: reqJsonValues.empBusinessUnit,
            SupervisorID: reqJsonValues.empSupervisor,
            isSupervisor: "",
            subordinates: null
          },
          commercialhrApproval: reqJsonValues.commercialhrApproval,
          commercialhrAssignedTo: reqJsonValues.commercialhrAssignedTo,
          commercialhrAssignedToEmail: reqJsonValues.commercialhrAssignedToEmail,
          commercialhrComments: reqJsonValues.commercialhrComments,
          commercialhrAssignedToDisplay: reqJsonValues.commercialhrAssignedToEmail,

          billablehrApproval: reqJsonValues.billablehrApproval,
          billablehrAssignedTo: reqJsonValues.billablehrAssignedTo,
          billablehrAssignedToEmail: reqJsonValues.billablehrAssignedToEmail,
          billablehrComments: reqJsonValues.billablehrComments,
          billablehrAssignedToDisplay: reqJsonValues.billablehrAssignedToEmail,

          accountingApproval: reqJsonValues.accountingApproval,
          accountingAssignedTo: reqJsonValues.accountingAssignedTo,
          accountingAssignedToEmail: reqJsonValues.accountingAssignedToEmail,
          accountingComments: reqJsonValues.accountingComments,
          accountingAssignedToDisplay: reqJsonValues.accountingAssignedToEmail,

          financeApproval: reqJsonValues.financeApproval,
          financeAssignedTo: reqJsonValues.financeAssignedTo,
          financeAssignedToEmail: reqJsonValues.financeAssignedToEmail,
          financeComments: reqJsonValues.financeComments,
          financeAssignedToDisplay: reqJsonValues.financeAssignedToEmail,

          dateApprovedByAccountingFinance: reqJsonValues.dateApprovedByAccountingFinance,
          completedDate: reqJsonValues.completedDate,
          dateApprovedByESG: reqJsonValues.dateApprovedByESG,

          esgApproval: reqJsonValues.esgApproval,
          esgAssignedTo: reqJsonValues.esgAssignedTo,
          esgAssignedToEmail: reqJsonValues.esgAssignedToEmail,
          esgComments: reqJsonValues.esgComments,
          esgPayrollComments: reqJsonValues.esgPayrollComments,
          esgAssignedToDisplay: reqJsonValues.esgAssignedToEmail,

          payrollApproval: reqJsonValues.payrollApproval,
          payrollAssignedTo: reqJsonValues.payrollAssignedTo,
          payrollAssignedToEmail: reqJsonValues.payrollAssignedToEmail,
          payrollComments: reqJsonValues.payrollComments,
          payrollAssignedToDisplay: reqJsonValues.payrollAssignedToEmail,

          RequestStatus: reqJsonValues.requestStatus,

          dateLblClass: "labelNormal",

          /*Loggin In As information*/
          loggedInAs: {
            EmpId: reqJsonValues.loginAsEmpId ?? undefined, DisplayName: reqJsonValues.loggedInAsDisplayName ?? undefined,
            Email: reqJsonValues.loggedInAsEmail ?? [], DisplayEmail: reqJsonValues.loggedInAsEmail ?? null
          }
        }, () => { console.log("Selected Employee", this.state.SelectedEmployee); });
      }

    }
    catch (err) {
      console.log(err);
    }



  }

  onClickCancel = async () => {
    const effectiveDateValue = this.state.EffectiveDate?.replace('Z', '');
    const dateSubmittedValue = this.state.dateSubmitted?.replace('Z', '');
    const request = {
      requestId: this.state.requestId,
      requestStatus: this.state.RequestStatus,
      dateSubmitted: dateSubmittedValue ? moment(dateSubmittedValue).format(
        "MM/DD/YY hh:mm A"
      ) : "",
      effectiveDate: effectiveDateValue ? moment(effectiveDateValue).format(
        "MM/DD/YY"
      ) : "",
      submitterName: this.state.submitterName,
      submitterEmail: this.state.submitterEmail,
      empId: this.state.SelectedEmployee.EmployeeID,
      empName: this.state.SelectedEmployee.Name,
      empPosition: this.state.SelectedEmployee.JobDescription,
      empSupervisor: this.state.SelectedEmployee.SupervisorID,
      empProgram: this.state.SelectedEmployee.empProgram,
      formName: 'Compensation Change'
    };
    const response = await cancelRequest(request);
    if (response) {
      this.setState({ isNavigateToApprovalQueue: true });
    }
  }

  cancelVisibility = async (roles, status) => {
    this.setState({ isCancelVisible: false });
    if (status !== requestStatus.cancelled && status !== requestStatus.complete) {
      const rolesAllowed = process.env.REACT_APP_CANCELLATION_ROLES_ALLOWED;
      if (roles.some(role => rolesAllowed.includes(role.name))) {
        this.setState({ isCancelVisible: true });
      }
    }
  }

  getWorkflowHistory = async (requestId) => {

    try {
      if (requestId) {
        let reqResponse = await fetch(process.env.REACT_APP_ESF_WORKFLOW_HISTORY, {
          method: "POST",
          headers:
          {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(
            {
              Method: "READ",
              requestID: requestId,
              formName: "Compensation"
            }
          ),
        });
        let reqResponseJson = await reqResponse.json();
        //console.log(reqResponseJson);
        const historyResult = reqResponseJson.ResultSets.Table1;
        if (historyResult && historyResult.length > 0) {
          const headers = Object.keys(historyResult[0]).map((col) => {
            return { field: col, filter: true, floatingFilter: true, sortable: true }
          });
          this.setState({ gridRows: historyResult, gridColumns: headers });
        }
      }
    }
    catch (err) {
      console.log(err)
    };

  }
  /*   End - DB and API Operations  */

  getImpersonated = (e) => {
    if (e !== null && e.detail !== null && e.detail.length > 0 && e.detail[0].userPrincipalName !== null &&
      (this.state.requestId == '' || this.state.requestId == undefined || this.state.requestId == null)) {
      this.setState({ loading: true });
      getImpersonatedUserRoles(e.detail[0].userPrincipalName).then(() => {
        var temp = localStorage.getItem("LoggedInAsUserAccess");
        if (temp) {
          temp = JSON.parse(temp);
          console.log(temp);
          this.setState({
            loggedInAs: { EmpId: temp.employeeID[0].employeeId, DisplayName: e.detail[0].displayName, Email: e.detail[0].userPrincipalName, LoginAsError: false },
            loading: false
          }, () => { this.componentDidMount() });
        } else {
          this.setState({ LoginAsError: true });
          localStorage.removeItem("LoggedInAsUserAccess");
          this.setState({
            loggedInAs: { EmpId: '', DisplayName: '', Email: '' },
            loading: false
          })
        }


      });

    } else if (!this.state.requestId) {
      localStorage.removeItem("LoggedInAsUserAccess");
      this.setState({
        loggedInAs: { EmpId: '', DisplayName: '', Email: '' },
        loading: false
      })
    }


  }

  clearImpersonation = (e) => {
    localStorage.removeItem("LoggedInAsUserAccess");
    this.setState({
      loggedInAs: { EmpId: '', DisplayName: '', Email: '', },
      Employees: [],
      selectedEmployee: null
    }, () => { this.componentDidMount() });

  }
  render() {

    const bonusFrequencyOptions = [
      { key: "--Choose Frequency--", text: "--Choose Frequency--" },
      { key: "Annual", text: "Annual" },
      { key: "Quarterly", text: "Quarterly" },
      { key: "Monthly", text: "Monthly" },
      { key: "Weekly", text: "Weekly" },
      { key: "Not Eligible", text: "Not Eligible" },
    ];
    var blnHowIsPayEntered = this.state.HowIsPayEntered === "Salary" ? true : false

    var effectiveDat = this.state.EffectiveDate ? moment(this.state.EffectiveDate).format("YYYY-MM-DD") : null

    var approvingFor = "", showUpdateButton = false;

    var isCommercialhrEnabled = false, isbillablehrEnabled = false, isAccountingEnabled = false, isFinanceEnabled = false, isESGEnabled = false, isPayRollHREnabled = false;
    /* Comm HR */
    if (this.state.RequestStatus === this.RequestStatus.PendingHR && this.roles.length > 0 && this.roles[0].name && (this.roles.some(role => (role.name === userRoles.commercialHR)) || this.LoggedInUser.IsAdminRole)) {
      isCommercialhrEnabled = true;
      approvingFor = "hr";
      showUpdateButton = true;
    }

    /* Billable HR */
    if (this.state.RequestStatus === this.RequestStatus.PendingBillableHR && this.roles.length > 0 && this.roles[0].name && (this.roles.some(role => (role.name === userRoles.hrBillable)) || this.LoggedInUser.IsAdminRole)) {
      isbillablehrEnabled = true;
      approvingFor = "billablehr";
      showUpdateButton = true;
    }

    /* Accounting */
    if (this.state.RequestStatus === this.RequestStatus.PendingAccount && this.roles.length > 0 && this.roles[0].name && (this.roles.some(role => (role.name === userRoles.accounting)) || this.LoggedInUser.IsAdminRole)) {
      isAccountingEnabled = true;
      approvingFor = "accounting";
      showUpdateButton = true;
    }
    /* Finance */
    if (this.state.RequestStatus === this.RequestStatus.PendingFinance && this.roles.length > 0 && this.roles[0].name && (this.roles.some(role => (role.name === userRoles.finance)) || this.LoggedInUser.IsAdminRole)) {
      isFinanceEnabled = true;
      approvingFor = "finance";
      showUpdateButton = true;
    }
    /* ESG */
    var checkUserRole = undefined;
    if (this.state.SelectedEmployee && this.state.SelectedEmployee.empClass && this.state.SelectedEmployee.empClass.trim().toLowerCase() === "int") {
      checkUserRole = userRoles.esgInternal;
    }
    else {
      checkUserRole = userRoles.esgBillable;
    }

    if (this.state.RequestStatus === this.RequestStatus.PendingESG && this.roles.length > 0 && this.roles[0].name && (this.roles.some(role => (role.name === checkUserRole)) || this.LoggedInUser.IsAdminRole)) {
      isESGEnabled = true;
      approvingFor = "esg";
      showUpdateButton = true;
    }
    /* Payroll */
    checkUserRole = "";
    if (this.state.SelectedEmployee && this.state.SelectedEmployee.CompanyID && this.state.SelectedEmployee.CompanyID.trim().toLowerCase() === "msc") {
      checkUserRole = userRoles.payrollCanada;
    }
    else if (this.state.SelectedEmployee && this.state.SelectedEmployee.empClass && this.state.SelectedEmployee.empClass.trim().toLowerCase() === "int") {
      checkUserRole = userRoles.payrollInternal;
    }
    else {
      checkUserRole = userRoles.payrollBillable;
    }
    if (this.state.RequestStatus === this.RequestStatus.PendingPayroll && this.roles.length > 0 && this.roles[0].name && (this.roles.some(role => (role.name === checkUserRole)) || this.LoggedInUser.IsAdminRole)) {
      isPayRollHREnabled = true;
      approvingFor = "payroll";
      showUpdateButton = true;
    }
    if (showUpdateButton &&
      (this.state.RequestStatus.toLowerCase().trim() === "complete" || this.state.RequestStatus.toLowerCase().trim().indexOf("reject") > 0 || this.state.RequestStatus.toLowerCase().trim().indexOf("cancel") > 0)
    ) {
      showUpdateButton = false;
    }
    var isEmployeeDisabled = this.state.requestId ? true : false

    var CreateOrUpdate = undefined, ShowSaveButton = false;
    if (!this.state.requestId) {
      CreateOrUpdate = "create"
      ShowSaveButton = true;
    }
    else if (this.state.requestId &&
      (this.state.RequestStatus === this.RequestStatus.RejectHR
        || this.state.RequestStatus === this.RequestStatus.RejectAccount
        || this.state.RequestStatus === this.RequestStatus.RejectFinance)
      && this.LoggedInUser.IsSubmitter
    ) {
      CreateOrUpdate = "resubmit"
      ShowSaveButton = true;
    }

    // console.log("Employees", this.state.Employees);
    // console.log("Is form valid", this.state.isFormValid);
    return (
      <section>
        <Title title={this.props.title} color={"#4d4f53"} />
        <Note note={this.props.note} />
        {this.state.isCancelVisible &&
          <>
            <br />
            {this.state.isNavigateToApprovalQueue && (<Navigate to={`/approval-queue/`} replace={true}></Navigate>)}
            <SubmitButton label="Cancel This Form" onClick={this.onClickCancel} color={"#A51D25"} />

          </>
        }
        {(this.state.isESFOwner || (this.state.loggedInAs.EmpId)) && (
          <LoginAs
            onChange={(e) => this.getImpersonated(e)}
            clearLoginAs={(e) => this.clearImpersonation(e)}
            showError={this.state.LoginAsError}
            empId={this.state.loggedInAs.EmpId}
            displayName={this.state.loggedInAs.DisplayName}
            email={this.state.loggedInAs.Email}
            requestId={this.state.requestId}
            loading={this.state.loading}
          //defaultSelectedUser={this.state.loggedInAs.DisplayEmail}
          //loginAsThisUser={() => this.loginAsOtherUser()}

          ></LoginAs>)}
        <label id="lblError" name="lblError"></label>

        <EmployeeSearch
          employees={this.state.Employees}
          selectedEmployee={this.state.SelectedEmployee}
          setSelectedEmployee={this.handleEmployeeSelected}
          disabled={isEmployeeDisabled}
        />

        <EsfInput
          type="select"
          name="reasonForPayChange"
          label="Reason for pay change"
          required
          value={this.state.ReasonForPayChange}
          onChange={(e) => this.setComponentStatus(e)}
          options={this.state.reasonForPayChangeOptions}
        />

        <EsfInput
          type="date"
          name="effectiveDate"
          label="Effective Date"
          alert="Sundays only"
          required
          value={effectiveDat}
          onChange={(e) => this.setComponentStatus(e)}
        />
        <label className={this.state.dateLblClass} id="lblSundayOnly" name="lblSundayOnly">Sundays only</label>


        <Toggle
          label="How is pay entered?"
          name="howIsPayEntered"
          onText="Salary"
          offText="Hourly"
          value={blnHowIsPayEntered}
          onChange={this.setStateHowIsPayEntered.bind(this)}
        />


        <EsfInput
          type="currency"
          label="Pay Rate"
          required
          name="payRate"
          value={this.state.PayRate}
          onChange={(e) => this.setComponentStatus(e)}
        />
        {(this.state.payRateError && this.state.HowIsPayEntered === "Hourly") && (<label className="labelError">Pay rate should be less than 999.99, when pay enterd hourly.</label>)}
        {(this.state.payRateError && this.state.HowIsPayEntered === "Salary") && (<label className="labelError">Pay rate entered must have a minimum of 5 and a maximum of 6 digits before the decimal (10,000.00 - 999,999.99).</label>)}

        <EsfInput
          type="select"
          label="Bonus Frequency"
          required
          name="bonusFrequency"
          value={this.state.BonusFrequency}
          onChange={(e) => this.setComponentStatus(e)}
          options={bonusFrequencyOptions}
        />

        {/* {this.getAnnulizedPotentialBonusComponent()} */}
        {this.state.showBonus && (<span><EsfInput
          type="currency"
          label="Annualized Bonus Potential"
          name="annualBonus"
          id="annualBonus"
          required
          value={this.state.AnnualizedBonusPotential}
          onChange={this.setComponentStatus.bind(this)}
        />

          <label className="labelNormal">
            Enter annualized bonus potential amount regardless of payout
            frequency.
          </label><br></br>
          {this.state.AnnualizedBonusPotentialError && (<label className="labelError">
            Bonus Potential cannot be greater than seven digits (before the decimal place).
          </label>)}</span>)}

        <EsfInput
          label="Your Phone Number"
          value={this.state.YourPhoneNumber}
          type="text"
          name="yourPhoneNumber"
          onChange={(e) => this.setComponentStatus(e)}
        />

        <EsfInput
          type="textarea"
          placeHolder="Add any additional comments…"
          name="addAnyAdditionalComments"
          value={this.state.AdditionalComments}
          onChange={(e) => this.setComponentStatus(e)}
        />

        {this.state.goToHome && (<Navigate to={`/compensation-change/view/${this.state.requestId}`} replace={true}></Navigate>)}

        {ShowSaveButton && (<SubmitButton
          onClick={(this.state.isFormValid && this.state.Employees && this.state.Employees.length > 0) ? () => this.handleSubmitToSave(CreateOrUpdate) : null}
          color={(this.state.Employees && this.state.Employees.length <= 0) ? "red" : ""}
          label={(this.state.Employees && this.state.Employees.length > 0) ?
            (this.state.isFormValid ?
              "Submit" :
              (<span>Please complete all required fields.{!this.state.EffectiveDate ? <span><br></br>Please choose a Sunday for Effective date.</span> : ""}</span>)
            ) : "No direct reports returned."
          }
        />)}
        <br></br>
        {(this.state.commercialhrApproval || this.state.RequestStatus === this.RequestStatus.PendingHR) && (< Approval approvalFor="commercialhr" isDisabled={!isCommercialhrEnabled} assignToLabel="Assigned To" assignToName="commercialhrassignto" assignToValue={this.state.commercialhrAssignedToDisplay} assignToOnChange={(e) => this.setComponentStatus(e)}
          statusName="commercialhrapprovalstatus" statusLabel="Approval" statusValue={this.state.commercialhrApproval} statusOnChange={(e) => this.setComponentStatus(e)}
          additionalCommName="commercialhraddcomm" additionalCommValue={this.state.commercialhrComments} additionalCommOnChange={(e) => this.setComponentStatus(e)}
          submitOnClick={() => this.handleSubmitToUpdate()}></Approval>)}

        {(this.state.billablehrApproval || this.state.RequestStatus === this.RequestStatus.PendingBillableHR) && (< Approval approvalFor="billablehr" isDisabled={!isbillablehrEnabled} assignToLabel="Assigned To" assignToName="billablehrassignto" assignToValue={this.state.billablehrAssignedToDisplay} assignToOnChange={(e) => this.setComponentStatus(e)}
          statusName="billablehrapprovalstatus" statusLabel="Approval" statusValue={this.state.billablehrApproval} statusOnChange={(e) => this.setComponentStatus(e)}
          additionalCommName="billablehraddcomm" additionalCommValue={this.state.billablehrComments} additionalCommOnChange={(e) => this.setComponentStatus(e)}
          submitOnClick={() => this.handleSubmitToUpdate()}></Approval>)}

        {(this.state.accountingApproval || this.state.RequestStatus === this.RequestStatus.PendingAccount) && (< Approval approvalFor="accounting" isDisabled={!isAccountingEnabled} assignToLabel="Assigned To" assignToName="accountingassignto" assignToValue={this.state.accountingAssignedToDisplay} assignToOnChange={(e) => this.setComponentStatus(e)}
          statusName="accountingapprovalstatus" statusLabel="Approval" statusValue={this.state.accountingApproval} statusOnChange={(e) => this.setComponentStatus(e)}
          additionalCommName="accountingaddcomm" additionalCommValue={this.state.accountingComments} additionalCommOnChange={(e) => this.setComponentStatus(e)}
        ></Approval>)}

        {(this.state.financeApproval || this.state.RequestStatus === this.RequestStatus.PendingFinance) && (< Approval approvalFor="finance" isDisabled={!isFinanceEnabled} assignToLabel="Assigned To" assignToName="financeassignto" assignToValue={this.state.financeAssignedToDisplay} assignToOnChange={(e) => this.setComponentStatus(e)}
          statusName="financeapprovalstatus" statusLabel="Approval" statusValue={this.state.financeApproval} statusOnChange={(e) => this.setComponentStatus(e)}
          additionalCommName="financeaddcomm" additionalCommValue={this.state.financeComments} additionalCommOnChange={(e) => this.setComponentStatus(e)}
        ></Approval>)}

        {(this.state.esgApproval || this.state.RequestStatus === this.RequestStatus.PendingESG) && (
        < Approval 
          approvalFor="esg" 
          isDisabled={!isESGEnabled} 
          assignToLabel="Assigned To" 
          assignToName="esgassignto" 
          assignToValue={this.state.esgAssignedToDisplay} 
          assignToOnChange={(e) => this.setComponentStatus(e)}
          statusName="esgapprovalstatus" statusLabel="Approval" statusValue={this.state.esgApproval} statusOnChange={(e) => this.setComponentStatus(e)}
          additionalCommName="esgaddcomm" additionalCommValue={this.state.esgComments} 
          additionalPayrollCommValue={this.state.esgPayrollComments}  
          additionalCommOnChange={(e) => this.setComponentStatus(e)}
        ></Approval>)}

        {(this.state.payrollApproval || this.state.RequestStatus === this.RequestStatus.PendingPayroll) && (< Approval approvalFor="payrollhr" isDisabled={!isPayRollHREnabled} assignToLabel="Assigned To" assignToName="payrollassignto" assignToValue={this.state.payrollAssignedToDisplay} assignToOnChange={(e) => this.setComponentStatus(e)}
          statusName="payrollapprovalstatus" statusLabel="Approval" statusValue={this.state.payrollApproval} statusOnChange={(e) => this.setComponentStatus(e)}
          additionalCommName="payrolladdcomm" additionalCommValue={this.state.payrollComments} additionalCommOnChange={(e) => this.setComponentStatus(e)}
        ></Approval>)}
        {/* () => this.handleSubmitToUpdate(approvingFor) */}
        {(showUpdateButton && this.state.RequestStatus.indexOf("Complete") < 0 && this.state.RequestStatus.indexOf("Cancel") < 0) && (<SubmitButton
          onClick={this.state.isUpdateFormValid ? () => this.handleSubmitToUpdate(approvingFor) : null}
          label={this.state.isUpdateFormValid ? "Submit" : (<span>Please complete all required fields.</span>)}
        />)}



        <br />
        <br />
        {this.state.gridRows.length > 0 &&
          <div className="outerDiv">
            <div>
              <p>{workflowHistory.title}</p>
              <Grid
                rowData={this.state.gridRows}
                columnDefs={this.state.gridColumns}
              />
            </div>
          </div>
        }
      </section >

    );
  }

}


//const CompensationChangetWithRouter = withRouter(CompensationChange);

export default CompensationChange;
