import React, { useEffect, useState, useCallback, useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles//ag-grid.css";
import "ag-grid-community/styles//ag-theme-alpine.css";
import { Link } from "react-router-dom";
import { LicenseManager } from "ag-grid-enterprise";
import useAgGridColumns from "../../hooks/grid/useAgGridColumns";
import { useLocation } from "react-router-dom";

LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_LICENCE_KEY);

const Grid = (props) => {
  const [columnDefs, setColumnDefs] = useState(props.columnDefs);
  const rowLimit = process.env.REACT_APP_AG_GRID_ROW_LIMIT;

  const location = useLocation();

  const getLocalStorageColumns = () => {
    return {
      state: JSON.parse(
        localStorage.getItem(`columnState-${location.pathname}`)
      ),
      defs: JSON.parse(localStorage.getItem(`columnDefs-${location.pathname}`)),
    };
  };

  const setLocalStorageColumns = (colState, colDefs ) => {
    localStorage.setItem(
      `columnState-${location.pathname}`,
      JSON.stringify(colState)
    );
    localStorage.setItem(
      `columnDefs-${location.pathname}`,
      JSON.stringify(colDefs)
    );
  };

  const saveStateToLocalStorage = (params) => {
    const columnState = params?.columnApi.getColumnState();
    const columnDefs = params?.api.getColumnDefs();
    setLocalStorageColumns(columnState, columnDefs);
  }

  const onGridReady = (params) => {
    const savedColumns = getLocalStorageColumns();
    if (savedColumns.state) {
      setTimeout(() => {
        params.columnApi.applyColumnState({
          state: savedColumns.state,
          applyOrder: true,
        });
      }, 1500);
    }
  };

  const terminationPriorityText = (value) => {
    switch (value) {
      case "1":
        return "High: Immediate Pay + Full PTO";
      case "2":
        return "High: Immediate Pay";
      case "3":
        return "Medium: 5-6 Day Payout";
      case "4":
        return "Low: Regular Payout + Full PTO";
      case "5":
        return "Low: Regular Payout";
      default:
        return `${value}`;
    }
  };

  const gridRef = useRef();

  const { setOverrideColumnDefs } = useAgGridColumns(gridRef, props.form);

  const sizeToFit = useCallback(() => {
    gridRef.current?.api?.sizeColumnsToFit({
      defaultMinWidth: 100,
      // columnLimits: [{ key: 'country', minWidth: 900 }],
    });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (gridRef.current?.api && !props.form) {
        sizeToFit();
      }
    }, 1500);
  }, [sizeToFit, gridRef.current?.api]);

  const customValueGetter = (params) => {
    if (params.colDef.field === "Priority") {
      const value = params.data.Priority;
      return terminationPriorityText(value);
    }
    return params.value;
  };

  const getRequestIdColumn = (key, form) => {
    return {
      field: key,
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filter: true,
      width: 118,
      cellRendererFramework: (params) => {
        return (
          <Link to={`/${form}/${params.data[key]}`} target="_blank">
            {`${params.data[key]}`}
          </Link>
        );
      },
    };
  };
  const getAssignedToColumn = (key) => {
    return {
      headerName: "Assigned To",
      field: key,
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filter: true,
      enableRowGroup: true,
      width: 124,
    };
  };

  const savedColumnsState = () => {
    if (!props.columnDefs && props.rowData) {
      let requestIdColumnDef = [];
      const newColumnDefs = [];
      Object.keys(props.rowData[0]).forEach((key) => {
        const keyToCheck = key.toLowerCase().replace(/\s/g, "");
        switch (keyToCheck) {
          case "requestid":
            requestIdColumnDef.unshift(getRequestIdColumn(key, props.form));
            return;
          case "candidateid":
            requestIdColumnDef.unshift(getRequestIdColumn(key, props.form));
            return;
          case "priority":
            requestIdColumnDef.push({
              field: key,
              rowGroup: true,
              hide: true,
              valueGetter: customValueGetter,
            });
            return;
          case "esgassignedto":
            requestIdColumnDef.push(getAssignedToColumn(key));
            return;
          case "hrassignedto":
            requestIdColumnDef.push(getAssignedToColumn(key));
            return;
          case "accountingassignedto":
            requestIdColumnDef.push(getAssignedToColumn(key));
            return;
          case "financeassignedto":
            requestIdColumnDef.push(getAssignedToColumn(key));
            return;
          case "payrollassignedto":
            requestIdColumnDef.push(getAssignedToColumn(key));
            return;
          default:
            newColumnDefs.push({
              field: key,
              sortable: true,
              resizable: true,
              floatingFilter: true,
              filter: true,
              enableRowGroup: true,
            });
            break;
        }
      });
      if (props.form) {
        setTimeout(() => {
          setOverrideColumnDefs(requestIdColumnDef);
        }, 100);
      } else {
        setColumnDefs(
          requestIdColumnDef
            ? [...requestIdColumnDef, ...newColumnDefs]
            : [...newColumnDefs]
        );
      }
    }
  };

  useEffect(() => {
    setTimeout(() => {
      savedColumnsState();
    }, 1000);
  }, [props.rowData]);

  const onBtnResetColumns = () => {
    savedColumnsState();
    gridRef?.current.columnApi.resetColumnState();
    setTimeout(() => {
        const columnState = gridRef?.current?.columnApi.getColumnState();
        const columnDefs = gridRef?.current?.api.getColumnDefs();
        setLocalStorageColumns(columnState, columnDefs);
      }, 100);
  };

  const onBtExport = useCallback(() => {
    const name = props.form ? props.form : "Data";
    gridRef.current?.api?.exportDataAsExcel({
      fileName: name + ".xlsx",
      sheetName: name,
    });
  }, []);

  return (
    <div className="ag-theme-alpine">
      <div className="buttonContainer approvalQueueControls">
        <button onClick={onBtnResetColumns} className="clearFilters">
          Reset Columns
        </button>
        <button onClick={onBtExport} className="exportButton">
          Export to Excel
        </button>
      </div>
      <AgGridReact
        ref={gridRef}
        rowData={props.rowData}
        domLayout="autoHeight"
        columnDefs={props.columnDefs ? props.columnDefs : columnDefs}
        groupDisplayType={"groupRows"}
        rowGroupPanelShow={"always"}
        groupDefaultExpanded={-1}
        animateRows={true}
        autoGroupColumnDef={{
          cellRenderer: "agGroupCellRenderer",
          cellRendererParams: {
            suppressCount: false,
          },
          groupRowRendererParams: {
            suppressCount: false,
          },
        }}
        pagination={props.pagination ? props.pagination : true}
        paginationPageSize={props.rowLimit ? props.rowLimit : rowLimit}
        onColumnVisible={saveStateToLocalStorage}
        onColumnMoved={saveStateToLocalStorage}
        onGridReady={onGridReady}
        onColumnResized={saveStateToLocalStorage}
        onSortChanged={saveStateToLocalStorage}
        onFilterChanged={saveStateToLocalStorage}
        onColumnRowGroupChanged={saveStateToLocalStorage}
      />
    </div>
  );
};

export default Grid;
