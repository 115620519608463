export const formNotes = {
  termination:
    "Use this action if the employee is being terminated from the company for any reason. Involuntary terminations require advanced consultation with your HR contact prior to submission.",
  addProgram:
    "This action is NOT a transfer. This will allow an employee to serve multiple programs at one time. The employee will still remain primarily associated to their current program. Before submitting, please ensure this is an approved program share as not all programs are allowed to share employees. HR approval is required prior to submitting this type of ESF. Salaried employees are NOT eligible for this action.",
  compesnation:
    "This action changes an employee’s compensation ONLY. NOT to be used for position changes nor program changes.",
  transfer:
    "An employee is changing a position to a new program/department. This could impact such things as benefits, systems access, pay, equipment, email, background check, drug tests and program required forms. Do NOT fill out the ESF until you get an email from IBM that you can move forward with the ESF. The ESF should be completed by the new manager that is hiring the employee, the former manager will get a notice to approve the transfer.",
  supervisor:
    "This action is for supervisor changes ONLY. NOT for program changes, title changes, pay changes, etc.",
  position:
    "This action is for changing an employee’s position (promotion, demotion, or title change) within the SAME program/department.",
};

export const formTitles = {
  addProgram: "Add Program",
  termination: "Termination",
  compesnation: "Compensation Change",
  transfer: "Transfer to Another Program/Department",
  supervisor: "Supervisor Change",
  position: "Position Change Within Program",
};

export const requestStatus = {
  pendingHr: "Pending HR",
  pendingESG: "Pending ESG",
  pendingPayroll: "Pending Payroll",
  pendingCommercialHR: "Pending Commercial HR",
  pendingAccounting: "Pending Accounting",
  pendingFinance: "Pending Finance",
  complete: "Complete",
  cancelled: "Cancelled",
  rejectToSubmitter: "Reject to Submitter",
  pendingBillableHR: "Pending Billable HR",
};

export const formRoutes = {
  addProgram: "add-program",
  positionChange: "position-change",
  supervisorChange: "supervisor-change",
};

export const bgColorsTitle = {
  "add-Program": "#0195D3",
  termination: "#A51D25",
  "compensation-change": "#9A9B9C",
  "position-change": "#f8971d",
  "supervisor-change": "#021a32",
  transfer: "#0195D3",
};

export const compensationType = {
  salary: "Salary",
  hourly: "Hourly",
};

export const approvalOptions = {
  pendingApprove: [
    { key: "Pending Review", text: "Pending Review" },
    { key: "Approve", text: "Approve" },
  ],
  pendingApproveReject: [
    { key: "Pending Review", text: "Pending Review" },
    { key: "Approve", text: "Approve" },
    { key: "Reject to Submitter", text: "Reject to Submitter" },
  ],
  pendingApproveSubmitPayroll: [
    { key: "Pending review", text: "Pending review" },
    { key: "Approve & Complete", text: "Approve & Complete" },
    { key: "Approve & Submit to Payroll", text: "Approve & Submit to Payroll" },
  ],
  financeLevel: [
    { key: "1", text: "1" },
    { key: "2", text: "2" },
    { key: "3", text: "3" },
    { key: "4", text: "4" },
    { key: "None", text: "None" }
  ]
};

export const userRoles = {
  hr: "HR",
  finance: "Finance",
  accounting: "Accounting",
  esgBillable: "ESG-Billable",
  esgInternal: "ESG-Internal",
  payrollCanada: "Payroll-Canada",
  payrollInternal: "Payroll-Internal",
  payrollBillable: "Payroll-Billable",
  commercialHR: "Commercial-HR",
  hrInternal: "HR-Internal",
  hrBillable: "HR-Billable",
  adminInternal: "Admin-Internal",
  adminBillable: "Admin-Billable",
  assetManagementSG: "AssetManagementSG",
  facilities: "MS-Facilities",
  esfOwners: "ESF-Owners",
  marketSourceUser: "MarketSourceUser"
};

export const employeeSearchText = {
  message:
    "Please enter a search term above in one of the following formats: 'Last Name,First Name' or 'Last Name'.",
};

export const errorMessage = {
  userNotAuthorized:
    "You are not authorized to access the application. Need Help? Call 877.883.4575",
  userRoleInvalid: "Something went wrong with user role. Need Help? Call 877.883.4575"
};

export const approvalSectionTitle = {
  commercialHR: "Commercial HR Approval",
  hr: "HR",
  accounting: "Accounting Approval",
  finance: "Finance Approval",
  esg: "ESG Approval",
  payroll: "Payroll Approval"
};

export const workflowHistory = {
  title: "Form History"
};

export const employeeCompanyLocations = [
  { key: "Choose a Location", text: "Choose a Location" },
  { key: "MSU", text: "MarketSource - United States of America" },
  { key: "MSZ", text: "Salelytics - United States of America" },
  { key: "MSC", text: "MarketSource- Canada" },
  { key: "MSP", text: "MarketSource - Puerto Rico" },

];

export const typeOfRequest = {
  active: "Active",
  archive: "Archive"
};