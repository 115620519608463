import React, { useState } from "react";
import "./styles.css";
import { BsSearch, BsCaretDownFill, BsCaretUpFill } from "react-icons/bs";
import { ClipLoader } from "react-spinners";
import EmployeeDetails from "./EmployeeDetails";

const EmployeeSearch = ({
  employees,
  selectedEmployee,
  setSelectedEmployee,
  selectedEmployees: selectedEmployeesProp,
  setSelectedEmployees: setSelectedEmployeesProp,
  searchQuery: searchQueryProp,
  setSearchQuery: setSearchQueryProp,
  loading = false,
  supervisor = false,
  multiSelect = false,
  disabled = false,
  headerExpand = false
}) => {
  const [internalSearchQuery, setInternalSearchQuery] = useState("");
  const searchQuery = searchQueryProp || internalSearchQuery;
  const setSearchQuery = setSearchQueryProp || setInternalSearchQuery;
  const [expandedEmployee, setExpandedEmployee] = useState(null);
  const [headerExpanded, setHeaderExpanded] = useState(headerExpand);

  const [internalSelectedEmployees, setInternalSelectedEmployees] = useState(
    multiSelect ? [] : [selectedEmployee].filter(Boolean)
  );

  const selectedEmployees =
    multiSelect && selectedEmployeesProp
      ? selectedEmployeesProp
      : internalSelectedEmployees;

  const setSelectedEmployees = multiSelect
    ? setSelectedEmployeesProp || setInternalSelectedEmployees
    : (newSelectedEmployees) =>
      setSelectedEmployee(newSelectedEmployees[0] || null);

  const handleToggleEmployee = (employee) => {
    if (employee.subordinates && employee.subordinates.length === 0) {
      handleEmployeeClick(employee);
      return;
    }

    if (expandedEmployee === employee.EmployeeID) {
      setExpandedEmployee(null);
    } else {
      setExpandedEmployee(employee.EmployeeID);
    }
  };

  const handleEmployeeClick = (employee) => {
    if (multiSelect) {
      if (selectedEmployees.some((e) => e.EmployeeID === employee.EmployeeID)) {
        setSelectedEmployees(
          selectedEmployees.filter((e) => e.EmployeeID !== employee.EmployeeID)
        );
      } else {
        setSelectedEmployees([...selectedEmployees, employee]);
      }
    } else {
      setSelectedEmployees([employee]);
    }
    setSearchQuery("");
  };

  const clearSelectedEmployee = () => {
    setSelectedEmployees([]);
  };

  const isEmployeeMatch = (employee) =>
    employee.Name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    (employee.subordinates &&
      employee.subordinates.some((subordinate) =>
        subordinate.Name.toLowerCase().includes(searchQuery.toLowerCase())
      ));

  const filteredEmployees = employees?.filter(isEmployeeMatch);

  return (
    <div className={`employee-search-container ${disabled ? 'disabled' : ''}`} >
      <div
        className="employee-search-header"
        onClick={() => setHeaderExpanded((prevState) => !prevState)}
      >
        <div className="employee-search-header-content">
          {selectedEmployees.length > 0
            ? `${selectedEmployees.length} ${multiSelect
              ? "selected"
              : supervisor
                ? "Supervisor"
                : "Employee"
            }`
            : supervisor
              ? "Search Supervisors..."
              : "Search Employees..."}
          {headerExpanded ? (
            <BsCaretUpFill className="employee-search-header-icon" />
          ) : (
            <BsCaretDownFill className="employee-search-header-icon" />
          )}
        </div>
        {!disabled && selectedEmployee && (
          <button
            className="employee-selection-clear-button"
            onClick={clearSelectedEmployee}
          >
            Clear
          </button>
        )}
      </div>
      {headerExpanded && (
        <>
          {!selectedEmployee && (
            <div className="employee-search-input-container">
              <input
                className="employee-search-input"
                type="text"
                placeholder={
                  supervisor ? "Search Supervisors..." : "Search Employees..."
                }
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <BsSearch className="employee-search-input-icon" />
              {searchQuery !== "" && (
                <button
                  className="employee-search-clear-button"
                  onClick={() => setSearchQuery("")}
                >
                  Clear
                </button>
              )}
              {loading && <ClipLoader color="#3f51b5" />}
            </div>
          )}
          {!selectedEmployee && (
            <ul className="employee-search-list">
              {filteredEmployees?.map((employee) => (
                <li
                  key={employee.EmployeeID}
                  className={`employee-search-list-item${selectedEmployees.some(
                    (e) => e.EmployeeID === employee.EmployeeID
                  )
                    ? " selected"
                    : ""
                    }`}
                >
                  {employee.subordinates && employee.subordinates.length > 0 ? (
                    <>
                      <div
                        onClick={() => handleToggleEmployee(employee)}
                        className="employee-search-item-header"
                      >
                        <div onClick={() => handleEmployeeClick(employee)}>
                          <span className="blue">{employee.Name}</span>{" "}
                          <span>{employee.JobDescription}</span>
                        </div>
                        {expandedEmployee === employee.EmployeeID ? (
                          <BsCaretUpFill className="employee-search-header-icon" />
                        ) : (
                          <BsCaretDownFill className="employee-search-header-icon" />
                        )}
                      </div>
                      {expandedEmployee === employee.EmployeeID && (
                        <ul className="employee-search-subordinates-list">
                          {employee.subordinates.map((subordinate) => (
                            <li
                              key={subordinate.EmployeeID}
                              className="employee-search-subordinates-list-item"
                              onClick={() => handleEmployeeClick(subordinate)}
                            >
                              <div>
                                <span className="blue">{subordinate.Name}</span>{" "}
                                <span>{subordinate.JobDescription}</span>
                              </div>
                            </li>
                          ))}
                        </ul>
                      )}
                    </>
                  ) : (
                    <div
                      className="employee-search-item-header"
                      key={employee.EmployeeID}
                      onClick={() => handleEmployeeClick(employee)}
                    >
                      <div>
                        <span className="blue">{employee.Name}</span>{" "}
                        <span>{employee.JobDescription}</span>
                      </div>
                    </div>
                  )}
                </li>
              ))}
            </ul>
          )}
        </>
      )}
      {selectedEmployee && !multiSelect && (
        <EmployeeDetails employee={selectedEmployee} />
      )}
      {multiSelect && (
        <div className="selected-employees-container">
          {selectedEmployees.map((employee) => (
            <EmployeeDetails
              employee={employee}
              onRemove={() => {
                setSelectedEmployees(
                  selectedEmployees.filter(
                    (selected) => selected.EmployeeID !== employee.EmployeeID
                  )
                );
              }}
              key={employee.EmployeeID}
            />
          ))}
        </div>
      )}
    </div >
  );
};

export default EmployeeSearch;
