import React from "react";
import { Outlet } from "react-router-dom";
import ApprovalQueueNav from "../ApprovalQueueNav";
import Header from "../Header";
import Footer from "../Footer";
import "./styles.css";

const AppovalQueueLayout = (props) => {
  return (
    <>
      <Header />
      <div className="approvalLayout">
        <ApprovalQueueNav />
        <main className="main-content">
          <Outlet />
        </main>
      </div>
      <Footer />
    </>
  );
};

export default AppovalQueueLayout;
