import React, { useEffect, useState } from "react";
import EsfInput from "../../components/EsfInput";
import Grid from "../../components/Grid";
import SubmitButton from "../../components/SubmitButton";
import { getApi } from "../../utils/fetchApi";
import { useMsal } from "@azure/msal-react";
import { Link } from "react-router-dom";
import { Oval } from "react-loader-spinner";
import { requestStatus, typeOfRequest, userRoles } from "../../utils/constants";
import { useParams } from "react-router-dom";
import moment from "moment";

const SearchForms = () => {
    const { instance } = useMsal();
    const { requestType } = useParams();
    const [pageLoader, setPageLoader] = useState(false);
    const [disableSearch, setDisableSearch] = useState(true);
    const [state, setState] = useState({
        txtSearchTerm: ''
    });
    const [gridRows, setGridRows] = useState([]);
    const [gridColumns, setGridColumns] = useState([]);

    useEffect(() => {
        getPageLoadData();
    }, [requestType]);

    const getPageLoadData = async () => {
        try {
            setState({ txtSearchTerm: '' });
            const userAccess = JSON.parse(localStorage.getItem("UserAccess"));
            if (userAccess && userAccess.roles?.length > 0) {
                setPageLoader(true);
                const roles = userAccess.roles;
                if (!roles.some(role => role.name === userRoles.esfOwners) &&
                    !roles.some(role => role.name === userRoles.adminBillable) &&
                    !roles.some(role => role.name === userRoles.adminInternal) &&
                    !roles.some(role => role.name === userRoles.marketSourceUser)
                ) {
                    setPageLoader(false);
                    return;
                }
                else {
                    setDisableSearch(false);
                    const queryToAppend = getQueryToAppend();
                    const result = await getDataFromSearch('*', queryToAppend);
                    if (result) {
                        prepareGridData(result);
                    }
                    setPageLoader(false);
                }
            }
        } catch (error) {
            setPageLoader(false);
            console.error(error);
        }
    }

    const getQueryToAppend = () => {
        let queryToAppend = `&$filter=requestStatus ne '${requestStatus.complete}' and requestStatus ne '${requestStatus.cancelled}'`;
        if (requestType === typeOfRequest.archive) {
            queryToAppend = `&$filter=requestStatus eq '${requestStatus.complete}' or requestStatus eq '${requestStatus.cancelled}'`;
        }
        let andClause = ``;
        const account = instance.getActiveAccount();
        const userAccess = JSON.parse(localStorage.getItem("UserAccess"));
        const roles = userAccess.roles;
        if (!roles.some(role => role.name === userRoles.esfOwners) &&
            (!roles.some(role => role.name === userRoles.adminBillable) ||
                !roles.some(role => role.name === userRoles.adminInternal))
        ) {
            if (roles.some(role => role.name === userRoles.marketSourceUser)) {
                andClause = `and submitterEmail eq '${account.username}'`;
            }
            if (roles.some(role => role.name === userRoles.adminInternal)) {
                andClause = `and Class eq 'INT'`;
            }
            else if (roles.some(role => role.name === userRoles.adminBillable)) {
                andClause = `and Class ne 'INT'`;
            }
        }
        if (andClause) {
            queryToAppend += andClause;
        }

        return queryToAppend;
    }


    const prepareGridData = (result) => {
        setGridRows([]);
        setGridColumns([]);
        if (result.value.length > 0) {
            let requestIdColumnDef = null;
            const newColumnDefs = [];
            Object.keys(result.value[0]).map((key) => {
                const keyToCheck = key.toLowerCase().replace(/\s/g, "");
                if (keyToCheck === "requestid") {
                    requestIdColumnDef = [
                        {
                            field: key,
                            sortable: true,
                            floatingFilter: true,
                            filter: true,
                            cellRendererFramework: (params) => {
                                let formRoute = '';
                                switch (params.data['formName']) {
                                    case 'AddProgram':
                                        formRoute = 'add-program'
                                        break;
                                    case 'PositionChange':
                                        formRoute = 'position-change'
                                        break;
                                    case 'Termination':
                                        formRoute = 'termination'
                                        break;
                                    case 'SupervisorChange':
                                        formRoute = 'supervisor-change'
                                        break;
                                    case 'CompensationChange':
                                        formRoute = 'compensation-change'
                                        break;
                                    case 'Transfer':
                                        formRoute = 'transfer'
                                        break;
                                    case 'NewHire':
                                        formRoute = 'onboarding-queue'
                                        break;
                                    default:
                                        break;
                                }
                                return (
                                    <Link to={`/${formRoute}/${params.data[key]}`} target='_blank'>
                                        {`${params.data[key]}`}
                                    </Link>
                                );
                            }
                        }
                    ];
                }
                else if (keyToCheck.includes('date')) {
                    newColumnDefs.push({
                        field: key,
                        sortable: true,
                        floatingFilter: true,
                        filter: true,
                        enableRowGroup: true,
                        cellRendererFramework: (params) => {
                            const value = params.data[key]?.replace('Z', '');
                            return (
                                <>
                                    {value ? moment(value).format(
                                        "MM/DD/YY hh:mm A"
                                    ) : ""}
                                </>
                            );
                        }
                    });
                }
                else if (keyToCheck !== '@search.score' && keyToCheck !== 'submitteremail' &&
                    keyToCheck !== "uniqueid") {
                    newColumnDefs.push({
                        field: key,
                        sortable: true,
                        floatingFilter: true,
                        filter: true,
                        enableRowGroup: true,
                    });
                }
            });
            setGridRows(result.value);
            setGridColumns(requestIdColumnDef ? [...requestIdColumnDef, ...newColumnDefs] : [...newColumnDefs]);
        }
    }

    const getDataFromSearch = async (searchTerm, queryToAppend = null) => {
        try {
            const searchUrl = process.env.REACT_APP_ESF_COGNITIVE_SEARCH_API;
            const apiKey = process.env.REACT_APP_ESF_COGNITIVE_SEARCH_API_KEY;
            const top = process.env.REACT_APP_ESF_COGNITIVE_SEARCH_API_RESULT_LIMIT;
            let callURL = `${searchUrl}&$top=${top}&search=${searchTerm}`;
            if (queryToAppend) {
                callURL = `${callURL}${queryToAppend}`;
            }
            const result = await getApi(callURL, null, apiKey);
            return result;
        } catch (error) {
            console.error(error);
        }
    }

    const handleChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.value
        });
    }

    const onClickSearch = async () => {
        try {
            setPageLoader(true);
            const queryToAppend = getQueryToAppend();
            const result = await getDataFromSearch(state.txtSearchTerm, queryToAppend);
            if (result) {
                prepareGridData(result);
            }
            setPageLoader(false);
        } catch (error) {
            console.error(error);
            setPageLoader(false);
        }
    }

    return (
        <main>
            {pageLoader && <div className="loader">
                <Oval color="#035797" height={100} width={100} />
            </div>}
            <EsfInput
                label=""
                name="txtSearchTerm"
                value={state.txtSearchTerm}
                type="textarea"
                onChange={handleChange}
                disabled={disableSearch}
            />
            {disableSearch && <SubmitButton label="Search" />}
            {!disableSearch && <SubmitButton label="Search" onClick={() => { onClickSearch() }} />}
            <Grid
                rowData={gridRows}
                columnDefs={gridColumns}
            />
        </main>
    );
};

export default SearchForms;
