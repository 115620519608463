import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import Swal from "sweetalert2";
import EsfInput from "../../components/EsfInput";
import SubmitButton from "../../components/SubmitButton";
import { postApi } from "../../utils/fetchApi";
import { useMsal } from "@azure/msal-react";
import { getFormattedDate, redirectUrl } from "../../utils/globalUtils";
import User from "../../components/User";

const OnboardingQueue = () => {
  const { instance } = useMsal();
  const [user, setUser] = useState({
    userName: "",
    roles: ""
  });
  const { candidateId } = useParams();
  const [state, setState] = useState({
    IntegrationStatus: "",
    EmployeeID: "",
    CandidateID: "",
    BRReqNumber: "",
    HireDate: null,
    LastName: "",
    MiddleName: "",
    FirstName: "",
    Address1: "",
    Address2: "",
    City: "",
    State: "",
    Postal: "",
    Country: "",
    Company: "",
    BusinessUnit: "",
    Department: "",
    EmployeeType: "",
    OfficeLocation: "",
    BonusType: "",
    BonusFrequency: "",
    BonusAmount: "",
    Position: "",
    DirectSupervisor: "",
    RegularOrTemp: "",
    EmployeeClass: "",
    FTorPTField: "",
    StandardHours: "",
    PayGroup: "",
    HourlyPay: "",
    CustomerID: "",
    Recruiter: "",
    AssignedTo: "",
    AssignedToEmail: "",
    AssignedToEmailDisplay: "",
    Status: "",
    UpdatedDate: null,
    UpdatedBy: ""
  });

  const optionsStatus = [
    { key: "Pending Processor", text: "Pending Processor" },
    { key: "Complete", text: "Complete" },
    { key: "Cancelled", text: "Cancelled" }
  ];

  useEffect(() => {
    const account = instance.getActiveAccount();
    if (account) {
      const userAccess = JSON.parse(localStorage.getItem("UserAccess"));
      const roles = userAccess.roles;
      setUser({ ...user, userName: account.name, roles: roles });
    }
    if (candidateId) {
      const empDetails = postApi(process.env.REACT_APP_ESF_NEWHIRE, {
        Method: "READ",
        CandidateID: candidateId
      });
      Promise.all([empDetails]).then(([resEmpDetails]) => {
        if (!Object.keys(resEmpDetails).includes('error')) {
          const row = resEmpDetails.ResultSets.Table1?.[0]
          if (row) {
            setState({
              ...state,
              IntegrationStatus: row.IntegrationStatus,
              EmployeeID: row.EmployeeID,
              CandidateID: row.CandidateID,
              BRReqNumber: row.BRReqNumber,
              HireDate: row.HireDate ? moment(row.HireDate).format("YYYY-MM-DD") : row.HireDate,
              LastName: row.LastName,
              MiddleName: row.MiddleName,
              FirstName: row.FirstName,
              Address1: row.Address1,
              Address2: row.Address2,
              City: row.City,
              State: row.State,
              Postal: row.Postal,
              Country: row.Country,
              Company: row.Company,
              BusinessUnit: row.BusinessUnit,
              Department: row.Department,
              EmployeeType: row.EmployeeType,
              OfficeLocation: row.OfficeLocation,
              BonusType: row.BonusType,
              BonusFrequency: row.BonusFrequency,
              BonusAmount: row.BonusAmount,
              Position: row.Position,
              DirectSupervisor: row.DirectSupervisor,
              RegularOrTemp: row.RegularOrTemp,
              EmployeeClass: row.EmployeeClass,
              FTorPTField: row.FTorPTField,
              StandardHours: row.StandardHours,
              PayGroup: row.PayGroup,
              HourlyPay: row.HourlyPay,
              CustomerID: row.CustomerID,
              Recruiter: row.Recruiter,
              AssignedTo: row.AssignedTo,
              AssignedToEmail: row.AssignedToEmail,
              AssignedToEmailDisplay: row.AssignedToEmail,
              Status: row.Status
            });
          }
          else {
            Swal.fire({
              title: `Candidate ID: ${candidateId} not found...`,
              icon: "error",
            });
          }
        }
        else {
          Swal.fire({
            title: "Something went wrong...",
            icon: "error",
          });
        }
      });
    }
  }, []);

  const handleInput = (e) => {
    try {
      const name = e.target.name;
      const value = e.target.value;
      setState({ ...state, [name]: value });
    } catch (error) {
      console.error(error);
    }
  };

  const prepareBody = () => {
    try {
      const method = "UPDATE";
      const paramNewHireData = {
        IntegrationStatus: state.IntegrationStatus,
        EmployeeID: state.EmployeeID,
        BRReqNumber: state.BRReqNumber,
        HireDate: moment(state.HireDate).format("YYYY-MM-DD"),
        LastName: state.LastName,
        MiddleName: state.MiddleName,
        FirstName: state.FirstName,
        Address1: state.Address1,
        Address2: state.Address2,
        City: state.City,
        State: state.State,
        Postal: state.Postal,
        Country: state.Country,
        Company: state.Company,
        BusinessUnit: state.BusinessUnit,
        Department: state.Department,
        EmployeeType: state.EmployeeType,
        OfficeLocation: state.OfficeLocation,
        BonusType: state.BonusType,
        BonusFrequency: state.BonusFrequency,
        BonusAmount: state.BonusAmount,
        Position: state.Position,
        DirectSupervisor: state.DirectSupervisor,
        RegularOrTemp: state.RegularOrTemp,
        EmployeeClass: state.EmployeeClass,
        FTorPTField: state.FTorPTField,
        StandardHours: state.StandardHours,
        PayGroup: state.PayGroup,
        HourlyPay: state.HourlyPay,
        CustomerID: state.CustomerID,
        Recruiter: state.Recruiter,
        AssignedTo: state.AssignedTo,
        AssignedToEmail: state.AssignedToEmail,
        Status: state.Status,
        UpdatedDate: getFormattedDate(new Date()),
        UpdatedBy: user.userName,
      };

      const body = {
        Method: method,
        NewHireData: paramNewHireData,
        CandidateID: candidateId,
      };
      return body;
    } catch (error) {
      console.error(error);
    }
  };

  const onClickUpdate = async () => {
    try {
      if (candidateId) {
        const body = prepareBody();
        postApi(
          process.env.REACT_APP_ESF_NEWHIRE,
          body
        ).then((response) => {
          if (!Object.keys(response).includes("error")) {
            Swal.fire({
              title: "Updated Successfully",
              icon: "success",
              html: `<a href="${redirectUrl(user.roles)}" target="_blank">Go to Home</a>`,
            }).then(async (result) => {
            });
          } else {
            Swal.fire({
              title: "Something went wrong...",
              html: response.error.message,
              icon: "error",
            });
            console.error(response.error);
          }
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onChangeUser = (e) => {
    try {
      const name = e.target.name;
      const displayName = e?.detail?.[0]?.displayName;
      if (displayName) {
        const email = e?.detail?.[0]?.userPrincipalName;
        setState({ ...state, [name]: displayName, [`${name}Email`]: email });
      }
      else {
        setState({
          ...state, [name]: '', [`${name}Email`]: ''
          , [`${name}EmailDisplay`]: ''
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <main>
      <EsfInput
        label="Tibco Result"
        name="IntegrationStatus"
        type="text"
        value={state.IntegrationStatus}
        onChange={handleInput}
      />

      <EsfInput
        label="Employee ID"
        name="EmployeeID"
        type="text"
        value={state.EmployeeID}
        onChange={handleInput}
      />

      <EsfInput
        label="Candidate ID"
        name="CandidateID"
        type="text"
        value={state.CandidateID}
        disabled
      />

      <EsfInput
        label="BR Req Number"
        name="BRReqNumber"
        type="text"
        value={state.BRReqNumber}
        onChange={handleInput}
      />

      <EsfInput
        label="Hire Date"
        name="HireDate"
        type="date"
        value={state.HireDate}
        onChange={handleInput}
      />

      <EsfInput
        label="Last Name"
        name="LastName"
        type="text"
        value={state.LastName}
        onChange={handleInput}
      />

      <EsfInput
        label="Middle Name"
        name="MiddleName"
        type="text"
        value={state.MiddleName}
        onChange={handleInput}
      />

      <EsfInput
        label="First Name"
        name="FirstName"
        type="text"
        value={state.FirstName}
        onChange={handleInput}
      />

      <EsfInput
        label="Address 1"
        name="Address1"
        type="text"
        value={state.Address1}
        onChange={handleInput}
      />

      <EsfInput
        label="Address 2"
        name="Address2"
        type="text"
        value={state.Address2}
        onChange={handleInput}
      />

      <EsfInput
        label="City"
        name="City"
        type="text"
        value={state.City}
        onChange={handleInput}
      />

      <EsfInput
        label="State"
        name="State"
        type="text"
        value={state.State}
        onChange={handleInput}
      />

      <EsfInput
        label="Postal"
        name="Postal"
        type="text"
        value={state.Postal}
        onChange={handleInput}
      />

      <EsfInput
        label="Country"
        name="Country"
        type="text"
        value={state.Country}
        onChange={handleInput}
      />

      <EsfInput
        label="Company"
        name="Company"
        type="text"
        value={state.Company}
        onChange={handleInput}
      />

      <EsfInput
        label="Business Unit"
        name="BusinessUnit"
        type="text"
        value={state.BusinessUnit}
        onChange={handleInput}
      />

      <EsfInput
        label="Department"
        name="Department"
        type="text"
        value={state.Department}
        onChange={handleInput}
      />

      <EsfInput
        label="Office Location"
        name="OfficeLocation"
        type="text"
        value={state.OfficeLocation}
        onChange={handleInput}
      />

      <EsfInput
        label="Bonus Type"
        name="BonusType"
        type="text"
        value={state.BonusType}
        onChange={handleInput}
      />

      <EsfInput
        label="Bonus Frequency"
        name="BonusFrequency"
        type="text"
        value={state.BonusFrequency}
        onChange={handleInput}
      />

      <EsfInput
        label="Bonus Amount"
        name="BonusAmount"
        type="text"
        value={state.BonusAmount}
        onChange={handleInput}
      />

      <EsfInput
        label="Position"
        name="Position"
        type="text"
        value={state.Position}
        onChange={handleInput}
      />

      <EsfInput
        label="Direct Supervisor"
        name="DirectSupervisor"
        type="text"
        value={state.DirectSupervisor}
        onChange={handleInput}
      />

      <EsfInput
        label="Regular Or Temp"
        name="RegularOrTemp"
        type="text"
        value={state.RegularOrTemp}
        onChange={handleInput}
      />

      <EsfInput
        label="Employee Class"
        name="EmployeeClass"
        type="text"
        value={state.EmployeeClass}
        onChange={handleInput}
      />

      <EsfInput
        label="FT/PT Field"
        name="FTorPTField"
        type="text"
        value={state.FTorPTField}
        onChange={handleInput}
      />

      <EsfInput
        label="Standard Hours"
        name="StandardHours"
        type="text"
        value={state.StandardHours}
        onChange={handleInput}
      />

      <EsfInput
        label="Pay Group"
        name="PayGroup"
        type="text"
        value={state.PayGroup}
        onChange={handleInput}
      />

      <EsfInput
        label="Employee Type"
        name="EmployeeType"
        type="text"
        value={state.EmployeeType}
        onChange={handleInput}
      />

      <EsfInput
        label="Hourly Pay"
        name="HourlyPay"
        type="text"
        value={state.HourlyPay}
        onChange={handleInput}
      />

      <EsfInput
        label="Customer ID"
        name="CustomerID"
        type="text"
        value={state.CustomerID}
        onChange={handleInput}
      />

      <EsfInput
        label="Recruiter"
        name="Recruiter"
        type="text"
        value={state.Recruiter}
        onChange={handleInput}
      />

      <User
        label="Assigned To"
        name="AssignedTo"
        onChange={onChangeUser}
        defaultSelectedUser={state.AssignedToEmailDisplay ? [state.AssignedToEmailDisplay] : []}
      />

      <EsfInput
        label="Status"
        name="Status"
        type="select"
        value={state.Status}
        options={optionsStatus}
        onChange={handleInput}
      />

      {!state.CandidateID && <SubmitButton label="Update" />}
      {state.CandidateID && <SubmitButton label="Update" onClick={onClickUpdate} />}
    </main>
  );
};

export default OnboardingQueue;
