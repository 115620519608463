import React from "react";
import "./styles.css";
import { useEffect, useState } from "react";
import SubmittedDetails from "../SubmittedDetails";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Title from "../../components/Title";
import Note from "../../components/Note";
import EmployeeSearch from "../../components/EmployeeSearch";
import EsfInput from "../../components/EsfInput";
import Error from "../../components/Error";
import SubmitButton from "../../components/SubmitButton";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import Grid from "../../components/Grid";
import { getTemporaryEmpId, postApi } from "../../utils/fetchApi";
import User from "../../components/User";
import { useMsal } from "@azure/msal-react";
import { cancelRequest, getFormattedDate, redirectUrl } from "../../utils/globalUtils";
import {
  bgColorsTitle,
  formRoutes,
  requestStatus,
  workflowHistory,
  employeeSearchText,
  approvalSectionTitle,
} from "../../utils/constants";
import {
  getReportees,
  getSupervisor,
  getJobTitle,
} from "../../utils/employeeService";
import { userRoles } from "../../utils/constants";
import { postValuesApi, getImpersonatedUserRoles } from "../../utils/fetchApi";
import { appInsights } from "../../components/AppInsights";
import LoginAs from "../LoginAs";
const Termination = ({ title, note }) => {
  const { instance } = useMsal();
  const { requestId } = useParams();
  const navigate = useNavigate();

  const [Termination, setTermination] = useState({
    dateSubmitted: null,
    TerminationReason: "",
    TerminationReasonType: "",
    TerminationReasonDisplayMessage: "",
    TerminationDate: "",
    submitterPhone: "",
    submitterEmail: "",
    AddtionalComments: "",

    assetReturn: "",
  });
  const [actionReasonOptions, setactionReasonOptions] = useState({
    empActionReason: [{ key: "Choose Reason", text: "Choose Reason" }],
  });

  const [hrData, setHrData] = useState({
    hrApproval: "Pending Review",
    hrAssignedTo: "",
    hrAssignedToEmail: "",
    hrAssignedToEmailDisplay: "",
    hrComments: "",
  });
  const [commercialhrData, setcommercialhrData] = useState({
    commercialhrApproval: "Pending Review",
    commercialhrAssignedTo: "",
    commercialhrAssignedToEmail: "",
    commercialhrAssignedToEmailDisplay: "",
    commercialhrComments: "",
  });
  const [esgData, setEsgData] = useState({
    esgApproval: "Pending Review",
    esgAssignedTo: "",
    esgAssignedToEmail: "",
    esgAssignedToEmailDisplay: "",
    esgComments: "",
    esgPayrollComments: "",
  });

  const [PayrollData, setPayrollData] = useState({
    payrollApproval: "Pending Review",
    payrollAssignedTo: "",
    payrollAssignedToEmail: "",
    payrollAssignedToEmailDisplay: "",
    payrollComments: "",
  });

  const [user, setUser] = useState({
    userName: "",
    userEmail: "",
    roles: ""
  });

  const [disableSection, setDisableSection] = useState({
    commercialHR: true,
    hr: true,
    esg: true,
    payroll: true,
  });

  const [EmployeeData, setEmployeeData] = useState([]);


  const [loading, setLoading] = useState(false);
  const [isCancelVisible, setIsCancelVisible] = useState(false);
  const [employeerecords, setEmployeerecords] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [showHideHRControls, setShowHideHRControls] = useState(false);
  const [showHideEsgControls, setShowHideEsgControls] = useState(false);
  const [showHidePayrollControls, setShowHidePayrollControls] = useState(false);
  const [showHideCommercialHrControls, setshowHideCommercialHrControls] =
    useState(false);
  const [isFormComplete, setIsFormComplete] = useState(false);
  const [getRqStatus, setGetRqStatus] = useState("");
  const [getHrStatus, setGetHrStatus] = useState("");
  const [getCommercialHrStatus, setgetCommercialHrStatus] = useState("");
  const [getESGStatus, setGetESGStatus] = useState("");
  const [getPayrollStatus, setGetPayrollStatus] = useState("");
  const [gridRows, setGridRows] = useState([]);
  const [gridColumns, setGridColumns] = useState([]);

  const [loggedInAs, setLoginAs] = useState({ EmpId: '', DisplayName: '', Email: '', DisplayEmail: null });
  useEffect(
    () => {
      LoadPage();
    },
    [loggedInAs]
  );
  const [isESFOwner, setIsESFOwner] = useState(false);
  const [LoginAsError, setLoginAsError] = useState(false);
  const [isLoginAsVisible, setIsLoginAsVisible] = useState(false);

  const handleInputHR = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setHrData({ ...hrData, [name]: value });
  };

  const handleInputCommercialHR = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setcommercialhrData({ ...commercialhrData, [name]: value });
  };

  const handleInputESG = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setEsgData({ ...esgData, [name]: value });
  };
  const handleInputPayroll = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setPayrollData({ ...PayrollData, [name]: value });
  };

  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    if (name === "TerminationReason") {
      const index = e.target.selectedIndex;
      const optionElement = e.target.childNodes[index];
      const terminationType = optionElement.getAttribute("terminationtype");
      const terminationDisplayMessage = optionElement.getAttribute(
        "terminationdisplaymessage"
      );
      setTermination({
        ...Termination,
        TerminationReason: value,
        TerminationReasonType: terminationType,
        TerminationReasonDisplayMessage: terminationDisplayMessage,
      });
    } else {
      setTermination({ ...Termination, [name]: value });
    }


  };

  const HRApprovalOptions = [
    { key: "Pending Review", text: "Pending Review" },
    { key: "Approve", text: "Approve" },
    { key: "Reject to Submitter", text: "Reject to Submitter" },
  ];
  const ESGApprovalOptions = [
    { key: "Pending Review", text: "Pending Review" },
    { key: "Approve & Complete", text: "Approve & Complete" },
    { key: "Approve & Submit to Payroll", text: "Approve & Submit to Payroll" },
  ];

  const PayrollOptions = [
    { key: "Pending Review", text: "Pending Review" },

    { key: "Complete", text: "Complete" },
  ];

  const AssetOptions = [
    { key: "Choose an Option", text: "Choose an Option" },
    { key: "Return to Manager/Office", text: "Return to Manager/Office" },
    { key: "Ship packaging materials", text: "Ship packaging materials" },
    { key: "No Asset Collection Needed", text: "No Asset Collection Needed" },
  ];

  useEffect(() => {
    handleValidation();
  }, [
    Termination,
    selectedEmployee,
    hrData,
    esgData,
    PayrollData,
    commercialhrData,
  ]);

  const isDefaultValidationPassed = () => {
    let isValid = false;
    if (
      Termination.TerminationReason &&
      Termination.TerminationReason !== "Choose Reason" &&
      Termination.TerminationDate &&
      selectedEmployee?.EmployeeID &&
      Termination.assetReturn &&
      Termination.assetReturn !== "Choose an Option"

    ) {
      isValid = true;
    }
    return isValid;
  };

  const isCommercialHRValidationPassed = () => {
    let isValid = false;
    if (
      commercialhrData.commercialhrApproval &&
      commercialhrData.commercialhrAssignedTo
    ) {
      isValid = true;
    }
    return isValid;
  };

  const isHrValidationPassed = () => {
    let isValid = false;
    if (hrData.hrApproval && hrData.hrAssignedTo) {
      isValid = true;
    }
    return isValid;
  };

  const isESGValidationPassed = () => {
    let isValid = false;
    if (esgData.esgApproval =="Pending Review" || ( esgData.esgApproval &&  esgData.esgAssignedTo)) {
      isValid = true;
    }
    return isValid;
  };

  const isPayrollValidationPassed = () => {
    let isValid = false;
    if (PayrollData.payrollApproval && PayrollData.payrollAssignedTo) {
      isValid = true;
    }
    return isValid;
  };

  const handleValidation = () => {
    setIsFormComplete(false);
    const userAccess = JSON.parse(localStorage.getItem("UserAccess"));
    if (getRqStatus === requestStatus.cancelled) {
      return;
    }
    if (userAccess.roles.length === 1 && userAccess.roles.some(role => role.name === userRoles.marketSourceUser) &&
      getRqStatus && !(getRqStatus === requestStatus.rejectToSubmitter)
    ) {
      return;
    }
    let isFormValid = false;
    isFormValid = isDefaultValidationPassed();
    if (requestId) {
      if (showHideCommercialHrControls) {
        isFormValid = isFormValid && isCommercialHRValidationPassed();
      }
      if (showHideHRControls) {
        isFormValid = isFormValid && isHrValidationPassed();
      }

      if (showHideEsgControls) {
        isFormValid = isFormValid && isESGValidationPassed();
      }
      if (showHidePayrollControls) {
        isFormValid = isFormValid && isPayrollValidationPassed();
      }
      if (getRqStatus === requestStatus.rejectToSubmitter) {
        const userAccess = JSON.parse(localStorage.getItem("UserAccess"));
        if (!userAccess.roles.some(role => role.name === userRoles.esfOwners) &&
          Termination.submitterEmail !== user.userEmail) {
          isFormValid = false;
        }
      }
    }
    setIsFormComplete(isFormValid);
  };

  const getEmployeeData = async (employeeId) => {
    try {
      setLoading(true);
      const reportees = getReportees(employeeId);
      const supervisor = getSupervisor(employeeId);
      Promise.all([reportees, supervisor]).then(
        ([employeesAndSubordinates, supervisorInfo]) => {
          setEmployeeData(employeesAndSubordinates);

          setLoading(false);
        }
      );
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const InsertData = async () => {
    const start = new Date().getTime();
    let date = new Date();
    const requestId = Termination.TerminationReason
      ? Math.floor(Math.random() * 1677721555).toString(16)
      : "";

    try {
      let reqStatus = requestStatus.pendingHr;

      if (Termination.TerminationReasonType === "Voluntary") {
        if (selectedEmployee.CompanyID === "MSC") {
          reqStatus = requestStatus.pendingPayroll;
        } else {
          reqStatus = requestStatus.pendingESG;
        }
      } else {
        if (selectedEmployee.EmployeeClass === "INT") {
          reqStatus = requestStatus.pendingHr;
        } else {
          if (selectedEmployee.ProductID === "83") {
            reqStatus = requestStatus.pendingCommercialHR;
          } else {
            reqStatus = requestStatus.pendingHr;
          }
        }
      }

      let creationResponse = await fetch(
        process.env.REACT_APP_ESF_TERMINATION,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            Method: "CREATE",
            TerminationData: {
              actionComments: Termination.AddtionalComments,
              actionReason: Termination.TerminationReason,
              submitterPhone: Termination.submitterPhone,
              effectiveDate: moment(Termination.TerminationDate).format(
                "M/D/YYYY"
              ),
              ModifiedBy: user.userName,
              Modified: getFormattedDate(new Date()),
              submitterName: user.userName,
              submitterEmail: user.userEmail,
              empName: selectedEmployee.Name,
              empState: selectedEmployee.HomeState,
              empId: selectedEmployee.EmployeeID,
              empPosition: selectedEmployee.JobDescription,
              empHireDate: selectedEmployee.HireDate,
              empEmail: selectedEmployee.EmailPreferred,
              empClass: selectedEmployee.EmployeeClass,
              empCompany: selectedEmployee.CompanyID,
              empLocation: selectedEmployee.OfficeLocationID,
              empSupervisor: selectedEmployee.SupervisorID,
              isSupervisor: selectedEmployee.isSupervisor,
              requestId: requestId,
              requestStatus: reqStatus,

              dateSubmitted: getFormattedDate(new Date()),
              actionType: "Termination",
              returnAsset: Termination.assetReturn,
              empBusinessUnit: selectedEmployee.ProductID,
              //empProgram: selectedEmployee.DepartmentDescription,
              empProgram: `${selectedEmployee.DepartmentDescription} - ${selectedEmployee.DepartmentID}`,
              voluntaryType: Termination.TerminationReasonType,
              loginAsEmpId: loggedInAs.EmpId,
              loggedInAsDisplayName: loggedInAs.DisplayName,
              loggedInAsEmail: loggedInAs.Email
            },
            requestID: requestId,
          }),
        }
      );

      if (appInsights) {
        appInsights.trackEvent({
          name: `Page Submit Termination`,
          properties: {
            TimeTaken: `${(new Date().getTime() - start) / 1000} s`,
          },
        });
      }

      let creationStatus = await creationResponse.json();
      if (creationStatus.ResultSets?.Table1?.[0]?.CREATEspRESULT) {
        Swal.fire({
          title: "Something went wrong...",
          html: creationStatus.ResultSets?.Table1?.[0]?.CREATEspRESULT,
          icon: "error",
        });
        console.error(creationStatus.error);
      } else {
        if (!Object.keys(creationStatus).includes('error')) {
          Swal.fire({
            title: "Submitted Successfully",
            icon: "success",
            html: `<a href="${redirectUrl(user.roles)}" target="_blank">Go to Home</a>`,
            confirmButtonText: "View Details",
          }).then(async (result) => {
            setIsFormComplete(false);
            if (result.isConfirmed) {
              navigate(`/termination/view/${requestId}`);
            }
          });
        }
      }



    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    LoadPage();
  }, []);

  const LoadPage = () => {
    const start = new Date().getTime();
    getDropdownOptions();
    const account = instance.getActiveAccount();
    let employeeId = "";
    let roles = [];
    const userAccess = JSON.parse(localStorage.getItem("UserAccess"));
    if (userAccess) {
      roles = userAccess.roles;
      employeeId = userAccess.employeeID[0].employeeId;
      var isESFOwner = false;
      if (userAccess.roles.some(role => (role.name === userRoles.esfOwners))) { isESFOwner = true; }
      setIsESFOwner(isESFOwner);
    }
    setUser({ ...user, userName: account.name, userEmail: account.username, roles: roles });
    if (getTemporaryEmpId(account.username)) {
      employeeId = getTemporaryEmpId(account.username);
    }
    if (loggedInAs && loggedInAs.EmpId) {
      employeeId = loggedInAs.EmpId;
    }
    //employeeId = "03806371";
    if (requestId) {
      const TermiantionHistory = postApi(
        process.env.REACT_APP_ESF_WORKFLOW_HISTORY,
        {
          formName: "Termination",
          requestID: requestId,
        }
      );
      const requestDetails = postApi(process.env.REACT_APP_ESF_TERMINATION, {
        Method: "READ",
        requestId,
      });
      Promise.all([TermiantionHistory, requestDetails]).then(
        ([resTerminationHistory, resRequestDetails]) => {
          if (
            !Object.keys(resTerminationHistory).includes("error") &&
            !Object.keys(resRequestDetails).includes("error")
          ) {
            if (resRequestDetails.ResultSets.Table1?.[0]) {
              const jsonValue = resRequestDetails.ResultSets.Table1[0];
              cancelVisibility(roles, jsonValue.requestStatus);
              const esgStatus = jsonValue.esgApproval;
              const empBU = jsonValue.empBusinessUnit;
              const reqStatus = jsonValue.requestStatus;
              const empClass = jsonValue.empClass;
              const empCompany = jsonValue.empCompany;
              setGetRqStatus(reqStatus);

              const historyResult = resTerminationHistory.ResultSets.Table1;
              if (historyResult && historyResult.length > 0) {
                const headers = Object.keys(historyResult[0]).map((col) => {
                  return {
                    field: col,
                    filter: true,
                    floatingFilter: true,
                    sortable: true,
                  };
                });
                setGridRows(historyResult);
                setGridColumns(headers);
              }

              const terminationReasonType = jsonValue.voluntaryType;
              const esgRequest = jsonValue.esgApproval;
              setGetESGStatus(esgRequest);
              const hrRequest = jsonValue.hrApproval;
              setGetHrStatus(hrRequest);
              const payrollRequest = jsonValue.PayrollApproval;
              setGetPayrollStatus(payrollRequest);


              setHrData({
                hrApproval: jsonValue.hrApproval
                  ? jsonValue.hrApproval
                  : hrData.hrApproval,
                hrAssignedTo: jsonValue.hrAssignedTo,
                hrAssignedToEmail: jsonValue.hrAssignedToEmail,
                hrAssignedToEmailDisplay: jsonValue.hrAssignedToEmail,
                hrComments: jsonValue.hrComments,
              });

              setcommercialhrData({
                commercialhrApproval: jsonValue.commercialhrApproval
                  ? jsonValue.commercialhrApproval
                  : commercialhrData.commercialhrApproval,
                commercialhrAssignedTo: jsonValue.commercialhrAssignedTo,
                commercialhrAssignedToEmail:
                  jsonValue.commercialhrAssignedToEmail,
                commercialhrAssignedToEmailDisplay:
                  jsonValue.commercialhrAssignedToEmail,
                commercialhrComments: jsonValue.commercialhrComments,
              });

              setEsgData({
                esgApproval: jsonValue.esgApproval
                  ? jsonValue.esgApproval
                  : esgData.esgApproval,
                esgAssignedTo: jsonValue.esgAssignedTo,
                esgAssignedToEmail: jsonValue.esgAssignedToEmail,
                esgAssignedToEmailDisplay: jsonValue.esgAssignedToEmail,
                esgComments: jsonValue.esgComments,
                esgPayrollComments:jsonValue.esgPayrollComments
              });

              setPayrollData({
                payrollApproval: jsonValue.payrollApproval
                  ? jsonValue.payrollApproval
                  : PayrollData.payrollApproval,
                payrollAssignedTo: jsonValue.payrollAssignedTo,
                payrollAssignedToEmail: jsonValue.payrollAssignedToEmail,
                payrollAssignedToEmailDisplay: jsonValue.payrollAssignedToEmail,
                payrollComments: jsonValue.payrollComments,
              });

              if (reqStatus === requestStatus.pendingCommercialHR || jsonValue.commercialhrApproval) {
                setshowHideCommercialHrControls(true);
              }

              if (reqStatus === requestStatus.pendingHr || jsonValue.hrApproval) {
                setShowHideHRControls(true);
              }
              if (reqStatus === requestStatus.pendingESG || jsonValue.esgApproval) {
                setShowHideEsgControls(true);
                if (terminationReasonType?.toLowerCase() !== "voluntary") {
                  if (empClass === "INT") {
                    setShowHideHRControls(true);
                  } else {
                    if (empBU === "83") {
                      setshowHideCommercialHrControls(true);
                    } else {
                      setShowHideHRControls(true);
                    }
                  }
                }


              }
              if (reqStatus === requestStatus.pendingPayroll || jsonValue.payrollApproval) {
                setShowHidePayrollControls(true);

                if (terminationReasonType?.toLowerCase() !== "voluntary") {
                  // setShowHideEsgControls(true);
                  if (empClass === "INT") {
                    setShowHideHRControls(true);
                  } else {
                    if (empBU === "83") {
                      setshowHideCommercialHrControls(true);
                    } else {
                      setShowHideHRControls(true);
                    }
                  }
                } else {
                  if (empCompany !== "MSC") {
                    setShowHideEsgControls(true);
                  }
                }

              }


              setTermination({
                ...Termination,
                ["submitterPhone"]: jsonValue.submitterPhone,
                submitterName: jsonValue.submitterName,
                submitterEmail: jsonValue.submitterEmail,
                ["TerminationReason"]: jsonValue.actionReason,
                ["AddtionalComments"]: jsonValue.actionComments,
                ["TerminationDate"]: moment(jsonValue.effectiveDate).format("YYYY-MM-DD"),
                ["assetReturn"]: jsonValue.returnAsset,
                dateSubmitted: jsonValue.dateSubmitted,
                TerminationReasonType: jsonValue.voluntaryType,
              });
              if (loggedInAs.EmpId !== jsonValue.loginAsEmpId) {
                setLoginAs({ EmpId: jsonValue.loginAsEmpId, DisplayName: jsonValue.loggedInAsDisplayName, Email: jsonValue.loggedInAsEmail })
              }


              if (
                jsonValue.requestStatus === requestStatus.pendingCommercialHR &&
                (roles.some(role => role.name === userRoles.esfOwners) ||
                  roles.some(role => role.name === userRoles.adminBillable) ||
                  roles.some(role => role.name === userRoles.adminInternal) ||
                  roles.some((role) => role.name === userRoles.commercialHR))
              ) {
                setDisableSection({ ...disableSection, commercialHR: false });
              } else if (
                jsonValue.requestStatus === requestStatus.pendingHr &&
                (roles.some(role => role.name === userRoles.esfOwners) ||
                  roles.some(role => role.name === userRoles.adminBillable) ||
                  roles.some(role => role.name === userRoles.adminInternal) ||
                  roles.some(
                    (role) =>
                      role.name === userRoles.hr ||
                      role.name === userRoles.hrInternal ||
                      role.name === userRoles.hrBillable
                  ))
              ) {
                setDisableSection({ ...disableSection, hr: false });
              } else if (
                jsonValue.requestStatus === requestStatus.pendingESG &&
                (roles.some(role => role.name === userRoles.esfOwners) ||
                  roles.some(role => role.name === userRoles.adminBillable) ||
                  roles.some(role => role.name === userRoles.adminInternal) ||
                  roles.some(
                    (role) =>
                      role.name === userRoles.esgBillable ||
                      role.name === userRoles.esgInternal
                  ))
              ) {
                setDisableSection({ ...disableSection, esg: false });
              } else if (
                jsonValue.requestStatus === requestStatus.pendingPayroll &&
                (roles.some(role => role.name === userRoles.esfOwners) ||
                  roles.some(role => role.name === userRoles.adminBillable) ||
                  roles.some(role => role.name === userRoles.adminInternal) ||
                  roles.some(
                    (role) =>
                      role.name === userRoles.payrollCanada ||
                      role.name === userRoles.payrollInternal ||
                      role.name === userRoles.payrollBillable
                  ))
              ) {
                setDisableSection({ ...disableSection, payroll: false });
              }

              setSelectedEmployee({
                Name: jsonValue.empName,
                JobDescription: jsonValue.empPosition,
                EmployeeID: jsonValue.empId,
                //HomeState: jsonValue.empState,
                HireDate: jsonValue.empHireDate,
                EmployeeClass: jsonValue.empClass,
                CompanyID: jsonValue.empCompany,
                EmailPreferred: jsonValue.empEmail,
                OfficeLocationID: jsonValue.empLocation,
                HomeState: jsonValue.empState,
                SupervisorID: jsonValue.empSupervisor,
                isSupervisor: jsonValue.isSupervisor,
                ProductID: jsonValue.empBusinessUnit,
                DepartmentDescription: jsonValue.empProgram.split(" - ")?.[0],
                DepartmentID: jsonValue.empProgram.split(" - ")?.[1]
              });
            }
          } else {
            Swal.fire({
              title: "Something went wrong...",

              icon: "error",
            });
          }
        }
      );
    } else {
      if (employeeId) {
        getEmployeeData(employeeId);
      }
    }
    if (appInsights) {
      appInsights.trackEvent({
        name: "Page Load Termination",
        properties: {
          TimeTaken: `${(new Date().getTime() - start) / 1000} s`,
        },
      });
    }

    if (requestId && loggedInAs.EmpId) {
      setIsLoginAsVisible(true);
    }
    else if (isESFOwner && (requestId === null || requestId === undefined || requestId === "")) {
      setIsLoginAsVisible(true);
    }
    else {
      setIsLoginAsVisible(false);
    }
  }

  const resetApproval = (paramTerminationData) => {

    paramTerminationData.commercialhrApproval = null;
    paramTerminationData.commercialhrAssignedTo = null;
    paramTerminationData.commercialhrAssignedToEmail = null;
    paramTerminationData.commercialhrComments = null;

    paramTerminationData.hrApproval = null;
    paramTerminationData.hrAssignedTo = null;
    paramTerminationData.hrAssignedToEmail = null;
    paramTerminationData.hrComments = null;

  }

  const updateData = async () => {
    const start = new Date().getTime();
    let date = new Date();

    try {
      let newRqStatus = getRqStatus;
      const paramTerminationData = {
        actionComments: Termination.AddtionalComments,
        actionReason: Termination.TerminationReason,
        submitterPhone: Termination.submitterPhone,
        effectiveDate: moment(Termination.TerminationDate).format("M/D/YYYY"),
        empName: selectedEmployee.Name,
        empState: selectedEmployee.HomeState,
        empId: selectedEmployee.EmployeeID,
        empPosition: selectedEmployee.JobDescription,
        empHireDate: selectedEmployee.HireDate,
        empEmail: selectedEmployee.EmailPreferred,
        empClass: selectedEmployee.EmployeeClass,
        empCompany: selectedEmployee.CompanyID,
        empLocation: selectedEmployee.OfficeLocationID,
        empSupervisor: selectedEmployee.SupervisorID,
        isSupervisor: selectedEmployee.isSupervisor,
        empBusinessUnit: selectedEmployee.ProductID,
        empProgram: `${selectedEmployee.DepartmentDescription} - ${selectedEmployee.DepartmentID}`,
        requestId: requestId,
        actionType: "Termination",
        requestId: requestId,
        // requestStatus: newRqStatus,
        returnAsset: Termination.assetReturn,
        ModifiedBy: user.userName,
        Modified: getFormattedDate(new Date()),
        submitterName: Termination.submitterName,
        submitterEmail: Termination.submitterEmail,
        dateSubmitted: Termination.dateSubmitted,
        voluntaryType: Termination.TerminationReasonType,

      };

      if (showHideCommercialHrControls) {
        paramTerminationData.commercialhrApproval =
          commercialhrData.commercialhrApproval;
        paramTerminationData.commercialhrAssignedTo =
          commercialhrData.commercialhrAssignedTo;
        paramTerminationData.commercialhrAssignedToEmail =
          commercialhrData.commercialhrAssignedToEmail;
        paramTerminationData.commercialhrComments =
          commercialhrData.commercialhrComments;

      }

      if (showHideHRControls) {
        paramTerminationData.hrApproval = hrData.hrApproval;
        paramTerminationData.hrAssignedTo = hrData.hrAssignedTo;
        paramTerminationData.hrAssignedToEmail = hrData.hrAssignedToEmail;
        paramTerminationData.hrComments = hrData.hrComments;
        paramTerminationData.dateApprovedByHR = getFormattedDate(new Date())
      }

      if (showHideEsgControls) {
        paramTerminationData.esgApproval = esgData.esgApproval;
        paramTerminationData.esgAssignedTo = esgData.esgAssignedTo;
        paramTerminationData.esgAssignedToEmail = esgData.esgAssignedToEmail;
        paramTerminationData.esgComments = esgData.esgComments;
        paramTerminationData.esgPayrollComments=esgData.esgPayrollComments;
        paramTerminationData.dateApprovedByESG = getFormattedDate(new Date())
      }
      if (showHidePayrollControls) {
        paramTerminationData.payrollApproval = PayrollData.payrollApproval;
        paramTerminationData.payrollAssignedTo = PayrollData.payrollAssignedTo;
        paramTerminationData.payrollAssignedToEmail =
          PayrollData.payrollAssignedToEmail;
        paramTerminationData.payrollComments = PayrollData.payrollComments;
        paramTerminationData.completedDate = getFormattedDate(new Date())
      }

      if (
        getRqStatus == requestStatus.pendingCommercialHR &&
        commercialhrData.commercialhrApproval === "Approve"
      ) {
        if (selectedEmployee.CompanyID === "MSC") {
          newRqStatus = requestStatus.pendingPayroll;
        } else {
          newRqStatus = requestStatus.pendingESG;
        }


      } else if (
        getRqStatus == requestStatus.pendingCommercialHR &&
        commercialhrData.commercialhrApproval === requestStatus.rejectToSubmitter
      ) {
        newRqStatus = requestStatus.rejectToSubmitter;
      }

      if (getRqStatus == requestStatus.pendingHr && hrData.hrApproval === "Approve") {
        newRqStatus = requestStatus.pendingESG;
      } else if (
        getRqStatus == requestStatus.pendingHr &&
        hrData.hrApproval === requestStatus.rejectToSubmitter
      ) {
        newRqStatus = requestStatus.rejectToSubmitter;
      } else if (
        getRqStatus === requestStatus.pendingESG &&
        esgData.esgApproval === "Approve & Complete"
      ) {
        newRqStatus = "Complete";
      } else if (
        getRqStatus === requestStatus.pendingESG &&
        esgData.esgApproval === "Approve & Submit to Payroll"
      ) {
        newRqStatus = requestStatus.pendingPayroll;
      } else if (
        getRqStatus == requestStatus.pendingPayroll &&
        PayrollData.payrollApproval === "Complete"
      ) {
        newRqStatus = "Complete";
      } else if (getRqStatus == requestStatus.rejectToSubmitter) {
        resetApproval(paramTerminationData);
        newRqStatus = requestStatus.pendingHr;
        if (Termination.TerminationReasonType === "Voluntary") {
          if (selectedEmployee.CompanyID === "MSC") {
            newRqStatus = requestStatus.pendingPayroll;
          } else {
            newRqStatus = requestStatus.pendingESG;
          }
        } else {
          if (selectedEmployee.EmployeeClass === "INT") {
            newRqStatus = requestStatus.pendingHr;
          } else {
            if (selectedEmployee.ProductID === "83") {
              newRqStatus = requestStatus.pendingCommercialHR;
            } else {
              newRqStatus = requestStatus.pendingHr;
            }
          }
        }
      }

      paramTerminationData.requestStatus = newRqStatus;




      let updateResponse = await fetch(process.env.REACT_APP_ESF_TERMINATION, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          Method: "UPDATE",
          TerminationData: paramTerminationData,
          requestID: requestId,
        }),
      });

      let updateStatus = await updateResponse.json();
      if (updateStatus.ResultSets?.Table1?.[0]?.CREATEspRESULT) {
        Swal.fire({
          title: "Something went wrong...",
          html: updateStatus.ResultSets?.Table1?.[0]?.CREATEspRESULT,
          icon: "error",
        });
        console.error(updateStatus.error);
      } else {
        if (!Object.keys(updateStatus).includes('error')) {
          Swal.fire({
            title: "Submitted Successfully",
            icon: "success",
            html: `<a href="${redirectUrl(user.roles)}" target="_blank">Go to Home</a>`,
            confirmButtonText: "View Details",
          }).then(async (result) => {
            setIsFormComplete(false);
            if (result.isConfirmed) {
              navigate(`/termination/view/${requestId}`);
            }
          });
        }
      }
      if (appInsights) {
        appInsights.trackEvent({
          name: `Page Update Termination`,
          properties: {
            TimeTaken: `${(new Date().getTime() - start) / 1000} s`,
          },
        });
      }

      // Swal.fire({
      //   title: "Submitted Successfully",
      //   icon: "success",
      //   html: `<a href="${redirectUrl(user.roles)}" target="_blank">Go to Home</a>`,
      //   confirmButtonText: "View Details",
      // }).then(async (result) => {
      //   setIsFormComplete(false);
      //   if (result.isConfirmed) {
      //     navigate(`/termination/view/${requestId}`);
      //   }
      // });
    } catch (error) {
      console.error(error);
    }
  };

  const onClickCancel = async () => {
    const effectiveDateValue = Termination.TerminationDate?.replace('Z', '');
    const dateSubmittedValue = Termination.dateSubmitted?.replace('Z', '');
    const request = {
      requestId: requestId,
      requestStatus: getRqStatus,
      dateSubmitted: dateSubmittedValue ? moment(dateSubmittedValue).format(
        "MM/DD/YY hh:mm A"
      ) : "",
      effectiveDate: effectiveDateValue ? moment(effectiveDateValue).format(
        "MM/DD/YY"
      ) : "",
      submitterName: Termination.submitterName,
      submitterEmail: Termination.submitterEmail,
      empId: selectedEmployee.EmployeeID,
      empName: selectedEmployee.Name,
      empPosition: selectedEmployee.JobDescription,
      empSupervisor: selectedEmployee.SupervisorID,
      empProgram: `${selectedEmployee.DepartmentDescription} - ${selectedEmployee.DepartmentID}`,
      formName: 'Termination'
    };
    const response = await cancelRequest(request);
    if (response) {
      navigate(`/approval-queue/`);
    }
  }

  const cancelVisibility = async (roles, status) => {
    setIsCancelVisible(false);
    if (status !== requestStatus.cancelled && status !== requestStatus.complete) {
      const rolesAllowed = process.env.REACT_APP_CANCELLATION_ROLES_ALLOWED;
      if (roles.some(role => rolesAllowed.includes(role.name))) {
        setIsCancelVisible(true);
      }
    }
  }

  const onChangeUser = (e) => {
    try {
      const name = e.target.name;

      const displayName = e?.detail?.[0]?.displayName;
      switch (name) {
        case "commercialhrAssignedTo":
          if (displayName) {
            const email = e?.detail?.[0]?.userPrincipalName;
            setcommercialhrData({
              ...commercialhrData,
              commercialhrAssignedTo: displayName,
              commercialhrAssignedToEmail: email,
            });
          } else {
            setcommercialhrData({
              ...commercialhrData,
              commercialhrAssignedTo: "",
              commercialhrAssignedToEmail: "",
              commercialhrAssignedToEmailDisplay: "",
            });
          }
          break;
        case "esgAssignedTo":
          if (displayName) {
            const email = e?.detail?.[0]?.userPrincipalName;
            setEsgData({
              ...esgData,
              esgAssignedTo: displayName,
              esgAssignedToEmail: email,
            });
          } else {
            setEsgData({
              ...esgData,
              esgAssignedTo: "",
              esgAssignedToEmail: "",
              esgAssignedToEmailDisplay: "",
            });
          }
          break;
        case "hrAssignedTo":
          if (displayName) {
            const email = e?.detail?.[0]?.userPrincipalName;
            setHrData({
              ...hrData,
              hrAssignedTo: displayName,
              hrAssignedToEmail: email,
            });
          } else {
            setHrData({
              ...hrData,
              hrAssignedTo: "",
              hrAssignedToEmail: "",
              hrAssignedToEmailDisplay: "",
            });
          }
          break;

        case "payrollAssignedTo":
          if (displayName) {
            const email = e?.detail?.[0]?.userPrincipalName;
            setPayrollData({
              ...PayrollData,
              payrollAssignedTo: displayName,
              payrollAssignedToEmail: email,
            });
          } else {
            setPayrollData({
              ...PayrollData,
              payrollAssignedTo: "",
              payrollAssignedToEmail: "",
              payrollAssignedToEmailDisplay: "",
            });
          }
          break;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getDropdownOptions = async () => {
    if (actionReasonOptions.empActionReason.length <= 1) {
    postValuesApi(
      process.env.REACT_APP_ESF_GETCHOICEVALUES,
      {
        RelatedProcess: "Termination",
      }
    ).then((result) => {
      console.log(result);
      const actionReason = result.ResultSets.Table4?.map((val) => {
        return {
          key: val.reason,
          text: val.reason,
          type: val.vountaryInvoluntary,
          description: val.displayMessage,
        };
      });
        //console.log("Action Reason Options:", actionReasonOptions.empActionReason.length);

      setactionReasonOptions({
        ...actionReasonOptions,

        empActionReason: [
          ...actionReasonOptions.empActionReason,
          ...actionReason,
        ],
      });

    });
    }
  };

  const getImpersonated = (e) => {
    if (e !== null && e.detail !== null && e.detail.length > 0 && e.detail[0].userPrincipalName !== null &&
      (requestId == '' || requestId == undefined || requestId == null)) {
      setLoading(true);
      getImpersonatedUserRoles(e.detail[0].userPrincipalName).then(() => {
        var temp = localStorage.getItem("LoggedInAsUserAccess");
        if (temp) {
          temp = JSON.parse(temp);
          console.log(temp);
          setLoginAs(
            { EmpId: temp.employeeID[0].employeeId, DisplayName: e.detail[0].displayName, Email: e.detail[0].userPrincipalName, LoginAsError: false }
          );
          setLoading(false);
          //LoadPage();
        } else {
          setLoginAsError(true);
          setIsLoginAsVisible(true);
          localStorage.removeItem("LoggedInAsUserAccess");
          setLoginAs({
            EmpId: '', DisplayName: '', Email: ''
          });
          setLoading(false);
          //LoadPage();
        }


      });

    } else if (!requestId) {
      localStorage.removeItem("LoggedInAsUserAccess");
      setLoginAs({ EmpId: '', DisplayName: '', Email: '' });
      setLoading(false);
    }


  }

  const clearImpersonation = (e) => {
    localStorage.removeItem("LoggedInAsUserAccess");
    setLoginAs({ EmpId: '', DisplayName: '', Email: '', });
    //LoadPage();
    /*this.setState({
      loggedInAs: { EmpId: '', DisplayName: '', Email: '', },
      Employees: [],
      selectedEmployee: null
    }, () => { this.componentDidMount() });*/

  }

  return (
    <main>
      <Title title={title} color={"#A51D25"} />
      <Note note={note} />
      <section>
        {isCancelVisible &&
          <>
            <br />
            <SubmitButton label="Cancel This Form" onClick={onClickCancel} color={"#A51D25"} />
          </>
        }
        {
          isLoginAsVisible && (
            <LoginAs
              onChange={(e) => getImpersonated(e)}
              clearLoginAs={(e) => clearImpersonation(e)}
              showError={LoginAsError}
              empId={loggedInAs.EmpId}
              displayName={loggedInAs.DisplayName}
              email={loggedInAs.Email}
              requestId={requestId}
              loading={loading}
            //defaultSelectedUser={this.state.loggedInAs.DisplayEmail}
            //loginAsThisUser={() => this.loginAsOtherUser()}

            ></LoginAs>)}
        <EmployeeSearch
          employees={EmployeeData}
          selectedEmployee={selectedEmployee}
          setSelectedEmployee={setSelectedEmployee}
          loading={loading}
        />
        {!selectedEmployee && (
          <label className="labelNormal">{employeeSearchText.message}</label>
        )}

        <EsfInput
          label="Reason for Termination:"
          name="TerminationReason"
          type="select"
          value={Termination.TerminationReason}
          options={actionReasonOptions.empActionReason}
          onChange={handleInput}
          message={Termination.TerminationReasonDisplayMessage}
          required
          disabled={requestId ? true : false}
        />

        <EsfInput
          label="Termination Date:"
          name="TerminationDate"
          type="date"
          value={Termination.TerminationDate}
          onChange={handleInput}
          required
        />

        <EsfInput
          label="Your Phone Number:"
          name="submitterPhone"
          type="text"
          value={Termination.submitterPhone}
          onChange={handleInput}
        />

        <EsfInput
          label="How will assets be returned?"
          name="assetReturn"
          type="select"
          value={Termination.assetReturn}
          options={AssetOptions}
          onChange={handleInput}
          required

        />

        <EsfInput

          placeHolder="Add any additional comments..."
          name="AddtionalComments"
          type="textarea"
          value={Termination.AddtionalComments}
          onChange={handleInput}
        />
        <>
          {isFormComplete && getRqStatus === requestStatus.rejectToSubmitter && getRqStatus !== requestStatus.complete && (
            <SubmitButton
              label="Submit"
              onClick={requestId ? updateData : InsertData}
            />
          )}
        </>

        {showHideCommercialHrControls && (
          <div className="outerDiv">
            <div>
              <p>{approvalSectionTitle.commercialHR}</p>
              <EsfInput
                type="select"
                name="commercialhrApproval"
                label="Approval"
                required
                value={commercialhrData.commercialhrApproval}
                onChange={handleInputCommercialHR}
                options={HRApprovalOptions}
                disabled={disableSection.commercialHR}
              />

              <User
                label="Assigned To"
                name="commercialhrAssignedTo"
                onChange={onChangeUser}
                defaultSelectedUser={
                  commercialhrData.commercialhrAssignedToEmailDisplay
                    ? [commercialhrData.commercialhrAssignedToEmailDisplay]
                    : []
                }
                required
                disabled={disableSection.commercialHR}
              />

              <EsfInput

                placeHolder="Comments"
                name="commercialhrComments"
                type="textarea"
                value={commercialhrData.commercialhrComments}
                onChange={handleInputCommercialHR}
                disabled={disableSection.commercialHR}
              />
            </div>
          </div>
        )}

        {showHideHRControls && (
          <div className="outerDiv">
            <div>
              <p>{approvalSectionTitle.hr}</p>
              <EsfInput
                type="select"
                name="hrApproval"
                label="Approval"
                required
                value={hrData.hrApproval}
                onChange={handleInputHR}
                options={HRApprovalOptions}
                disabled={disableSection.hr}
              />

              <User
                label="Assigned To"
                name="hrAssignedTo"
                onChange={onChangeUser}
                defaultSelectedUser={
                  hrData.hrAssignedToEmailDisplay ? [hrData.hrAssignedToEmailDisplay] : []
                }
                required
                disabled={disableSection.hr}
              />

              <EsfInput

                placeHolder="Comments"
                name="hrComments"
                type="textarea"
                value={hrData.hrComments}
                onChange={handleInputHR}
                disabled={disableSection.hr}
              />
            </div>
          </div>
        )}
        {showHideEsgControls && (
          <div className="outerDiv">
            <div>
              <p>{approvalSectionTitle.esg}</p>
              <EsfInput
                type="select"
                name="esgApproval"
                required
                label="Approval"
                value={esgData.esgApproval}
                onChange={handleInputESG}
                options={ESGApprovalOptions}
                disabled={disableSection.esg}
              />

              <User
                label="Assigned To"
                name="esgAssignedTo"
                onChange={onChangeUser}
                defaultSelectedUser={
                  esgData.esgAssignedToEmailDisplay ? [esgData.esgAssignedToEmailDisplay] : []
                }
                required ={esgData.esgApproval!="Pending Review" ? true :false}
                disabled={disableSection.esg}
              />

              <EsfInput
                placeHolder="Comments"
                name="esgComments"
                type="textarea"
                value={esgData.esgComments}
                onChange={handleInputESG}
                disabled={disableSection.esg}
              />
              <EsfInput
                label={"Payroll Team Comments"}
                placeHolder="Comments"
                name="esgPayrollComments"
                type="textarea"
                value={esgData.esgPayrollComments}
                onChange={handleInputESG}
                disabled={disableSection.esg}
              />
            </div>
          </div>
        )}

        {showHidePayrollControls && (
          <div className="outerDiv">
            <div>
              <p>{approvalSectionTitle.payroll}</p>
              <EsfInput
                type="select"
                name="payrollApproval"
                label="Approval"
                required
                value={PayrollData.payrollApproval}
                onChange={handleInputPayroll}
                options={PayrollOptions}
                disabled={disableSection.payroll}
              />

              <User
                label="Assigned To"
                name="payrollAssignedTo"
                onChange={onChangeUser}
                defaultSelectedUser={
                  PayrollData.payrollAssignedToEmailDisplay
                    ? [PayrollData.payrollAssignedToEmailDisplay]
                    : []
                }
                required
                disabled={disableSection.payroll}
              />

              <EsfInput

                placeHolder="Comments"
                name="payrollComments"
                type="textarea"
                value={PayrollData.payrollComments}
                onChange={handleInputPayroll}
                disabled={disableSection.payroll}
              />
            </div>
          </div>
        )}
        {!employeerecords && <Error error={"No direct reports returned."} />}

        {!isFormComplete && (!requestId || getRqStatus !== "Complete") && (
          <SubmitButton label="Please complete all required fields." />
        )}
        {isFormComplete && getRqStatus !== requestStatus.rejectToSubmitter && getRqStatus !== requestStatus.complete && (
          <SubmitButton
            label="Submit"
            onClick={requestId ? updateData : InsertData}
          />
        )}

        <br />
        <br />
        {gridRows.length > 0 && (
          <div className="outerDiv">
            <div>
              <p>{workflowHistory.title}</p>
              <Grid rowData={gridRows} columnDefs={gridColumns} />
            </div></div>
        )}
      </section>
    </main>
  );
};

export default Termination;
