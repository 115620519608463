import React, { useEffect, useState } from "react";
import "./styles.css";
import { NavLink } from "react-router-dom";
import { approvalQueues } from "../../containers/ApprovalQueue/constants";
import { userRoles } from "../../utils/constants";

const ApprovalQueueNav = () => {
  let currentParent;
  const [approvalQueueNav, setapprovalQueueNav] = useState({});
  useEffect(() => {
    let roles = [];
    const navToRender = {};
    const userAccess = JSON.parse(localStorage.getItem("UserAccess"));
    if (userAccess) {
      roles = userAccess.roles;
    }
    if (roles.length > 0) {
      const allKeys = Object.keys(approvalQueues);
      allKeys.forEach(key => {
        if (key === 'Active' || key === 'Archive') {
          if (roles.some(role => role.name === userRoles.esfOwners) ||
            roles.some(role => role.name === userRoles.adminInternal) ||
            roles.some(role => role.name === userRoles.adminBillable) ||
            roles.some(role => role.name === userRoles.marketSourceUser)
          ) {
            navToRender[key] = approvalQueues[key];
          }
        }
        else if (roles.some(role => role.name === userRoles.esfOwners)) {
          navToRender[key] = approvalQueues[key];
        }
        else if (roles.some(role => role.name === userRoles.adminInternal) && (key === userRoles.hrInternal ||
          key === userRoles.esgInternal || key === userRoles.payrollInternal ||
          key === userRoles.accounting || key === userRoles.finance)) {
          navToRender[key] = approvalQueues[key];
        }
        else if (roles.some(role => role.name === userRoles.adminBillable) && (key === userRoles.hrBillable ||
          key === userRoles.esgBillable || key === userRoles.payrollBillable ||
          key === userRoles.accounting || key === userRoles.finance)) {
          navToRender[key] = approvalQueues[key];
        }
        else {
          if (roles.some(role => role.name === key)) {
            navToRender[key] = approvalQueues[key];
          }
        }
      });
      setapprovalQueueNav(navToRender);
    }
  }, []);

  const renderQueueItem = (key) => {
    if (approvalQueueNav[key].parent) {
      if (currentParent !== approvalQueueNav[key].parent) {
        currentParent = approvalQueueNav[key].parent;
        return (
          <React.Fragment key={key}>
            <li className="parent" key={approvalQueueNav[key].parent}>
              <span>{approvalQueueNav[key].parent}</span>
            </li>
            <li className="child">
              <NavLink
                to={`/approval-queue/${key}`}
                activeclassname="active"
              >
                {approvalQueueNav[key].name}
              </NavLink>
            </li>
          </React.Fragment>
        );
      } else {
        return (
          <li className="child" key={key}>
            <NavLink to={`/approval-queue/${key}`} activeclassname="active">
              {approvalQueueNav[key].name}
            </NavLink>
          </li>
        );
      }
    }
    return (
      <li key={key}>
        {key === 'Active' || key === 'Archive'
          ? <NavLink to={`/approval-queue/search-forms/${key}`} activeclassname="active">
            {approvalQueueNav[key].name}
          </NavLink>
          : <NavLink to={`/approval-queue/${key}`} activeclassname="active">
            {approvalQueueNav[key].name}
          </NavLink>}
      </li>
    );
  };

  return (
    <nav className="approval-menu">
      <ul>
        {Object.keys(approvalQueueNav).map((key) => renderQueueItem(key))}
      </ul>
    </nav>
  );
};

export default ApprovalQueueNav;
