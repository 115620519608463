import React from "react";
import "./styles.css";
import { useEffect, useState, useRef } from "react";
import moment from "moment";
import { getApi, postApi } from "../../utils/fetchApi";
import EsfInput from "../../components/EsfInput";
import Grid from "../../components/Grid";
import User from "../../components/User";
import SubmitButton from "../../components/SubmitButton";
import Title from "../../components/Title";
import Note from "../../components/Note";
import EmployeeSearch from "../../components/EmployeeSearch";
import { approvalSectionTitle, bgColorsTitle, employeeCompanyLocations, employeeSearchText, formRoutes, requestStatus, userRoles, workflowHistory } from "../../utils/constants";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { Oval } from "react-loader-spinner";
import { cancelRequest, getFormattedDate, redirectUrl } from "../../utils/globalUtils";
import { appInsights } from "../../components/AppInsights";
import { getDepartments, getEmployee, getEmployeesFromSearch } from "../../utils/employeeService";

const AddProgram = ({ title, note }) => {
  const { instance } = useMsal();
  const { requestId } = useParams();
  const previousController = useRef();
  const navigate = useNavigate();
  const [isFormValid, setIsFormValid] = useState(false);
  const [gridRows, setGridRows] = useState([]);
  const [gridColumns, setGridColumns] = useState([]);
  const [approvalSection, setApprovalSection] = useState({
    showESGSection: false,
    showPayrollSection: false
  });
  const [disableSection, setDisableSection] = useState({
    esg: true,
    payroll: true
  });
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [isCancelVisible, setIsCancelVisible] = useState(false);
  const optionsESGApprovalReason = [
    { key: "Pending Review", text: "Pending Review" },
    { key: "Approve & Complete", text: "Approve & Complete" }
  ];

  const optionsPayrollApprovalReason = [
    { key: "Pending Review", text: "Pending Review" },
    { key: "Approve & Complete", text: "Approve & Complete" }
  ];

  const [optionsProgramToAdd, setOptionsProgramToAdd] = useState([{ key: 'Choose Program', text: 'Choose Program' }]);

  const [user, setUser] = useState({
    userName: "",
    userEmail: "",
    roles: ""
  });

  const [pageLoader, setPageLoader] = useState(false);

  const [state, setState] = useState({
    requestStatus: "",
    effectiveDate: null,
    dateSubmitted: null,
    employeeCompany: "",
    programToAdd: "",
    submitterPhone: "",
    submitterEmail: "",
    actionComments: "",
    esgApproval: "Pending Review",
    esgAssignedTo: "",
    esgAssignedToEmail: "",
    esgAssignedToEmailDisplay: "",
    dateApprovedByESG: null,
    esgComments: "",
    payrollApproval: "Pending Review",
    payrollAssignedTo: "",
    payrollAssignedToEmail: "",
    payrollAssignedToEmailDisplay: "",
    payrollComments: "",
    completedDate: null
  });

  const [employee, setEmployee] = useState({
    searchResults: [],
    selectedEmployee: null
  });

  useEffect(() => {
    getPageLoadData();
  }, []);

  useEffect(() => {
    handleValidation();
  }, [state, employee]);

  useEffect(() => {
    if (!searchQuery || searchQuery.length <= 2) {
      if (previousController.current) {
        previousController.current.abort();
      }
      setEmployee({ ...employee, selectedEmployee: null, searchResults: [] });
      setLoading(false);
    }
    if (searchQuery?.length === 3) {
      const companyId = employeeCompanyLocations.filter(item => item.text === state.employeeCompany)[0]?.key;
      if (companyId) {
        getEmployees(searchQuery, companyId);
      }
    }
  }, [searchQuery]);

  const getPageLoadData = async () => {
    try {
      const start = new Date().getTime();
      const account = instance.getActiveAccount();
      if (account) {
        const userAccess = JSON.parse(localStorage.getItem("UserAccess"));
        if (userAccess && userAccess.roles?.length > 0) {
          const roles = userAccess.roles;
          setUser({ ...user, userName: account.name, userEmail: account.username, roles: roles });
          if (requestId) {
            setPageLoader(true);
            const addProgramHistory = postApi(process.env.REACT_APP_ESF_WORKFLOW_HISTORY, {
              formName: 'AddProgram',
              requestID: requestId
            });
            const requestDetails = postApi(process.env.REACT_APP_ESF_ADDPROGRAM, {
              Method: "READ",
              requestId
            });
            Promise.all([addProgramHistory, requestDetails]).then(([resAddProgramHistory, resRequestDetails]) => {
              if (!Object.keys(resAddProgramHistory).includes('error') && !Object.keys(resRequestDetails).includes('error')) {
                if (resRequestDetails.ResultSets.Table1?.[0]) {
                  const jsonValue = resRequestDetails.ResultSets.Table1[0];
                  cancelVisibility(roles, jsonValue.requestStatus);
                  Promise.all([setPrograms(jsonValue.empCompany), setEmployeeInfo(jsonValue.empId)]).then(() => {
                    const historyResult = resAddProgramHistory.ResultSets.Table1;
                    if (historyResult && historyResult.length > 0) {
                      const headers = Object.keys(historyResult[0]).map((col) => {
                        return { field: col, filter: true, floatingFilter: true, sortable: true }
                      });
                      setGridRows(historyResult);
                      setGridColumns(headers);
                    }
                    setState({
                      ...state,
                      requestStatus: jsonValue.requestStatus,
                      submitterPhone: jsonValue.submitterPhone,
                      submitterName: jsonValue.submitterName,
                      submitterEmail: jsonValue.submitterEmail,
                      actionComments: jsonValue.actionComments,
                      dateSubmitted: jsonValue.dateSubmitted,
                      effectiveDate: moment(jsonValue.effectiveDate).format(
                        "YYYY-MM-DD"
                      ),
                      programToAdd: jsonValue.programToAdd,
                      esgApproval: jsonValue.esgApproval ? jsonValue.esgApproval : state.esgApproval,
                      esgAssignedTo: jsonValue.esgAssignedTo,
                      esgAssignedToEmail: jsonValue.esgAssignedToEmail,
                      esgAssignedToEmailDisplay: jsonValue.esgAssignedToEmail,
                      esgComments: jsonValue.esgComments,
                      dateApprovedByESG: jsonValue.dateApprovedByESG,
                      payrollApproval: jsonValue.payrollApproval ? jsonValue.payrollApproval : state.payrollApproval,
                      payrollAssignedTo: jsonValue.payrollAssignedTo,
                      payrollAssignedToEmail: jsonValue.payrollAssignedToEmail,
                      payrollAssignedToEmailDisplay: jsonValue.payrollAssignedToEmail,
                      payrollComments: jsonValue.payrollComments,
                      completedDate: jsonValue.completedDate,
                    });

                    if (jsonValue.requestStatus === requestStatus.pendingESG && (
                      roles.some(role => role.name === userRoles.esfOwners) ||
                      roles.some(role => role.name === userRoles.adminBillable) ||
                      roles.some(role => role.name === userRoles.esgBillable))) {
                      setDisableSection({ ...disableSection, esg: false });
                    }
                    else if (jsonValue.requestStatus === requestStatus.pendingPayroll && (
                      roles.some(role => role.name === userRoles.esfOwners) ||
                      roles.some(role => role.name === userRoles.payrollCanada))) {
                      setDisableSection({ ...disableSection, payroll: false });
                    }

                    if (jsonValue.requestStatus === requestStatus.pendingESG || jsonValue.esgApproval) {
                      setApprovalSection({ ...approvalSection, showESGSection: true });
                    }
                    if (jsonValue.requestStatus === requestStatus.pendingPayroll || jsonValue.payrollApproval) {
                      setApprovalSection({ ...approvalSection, showPayrollSection: true });
                    }
                    setPageLoader(false);
                  });
                }
              }
              else {
                setPageLoader(false);
                Swal.fire({
                  title: "Something went wrong...",
                  icon: "error",
                });
              }
            });
          }
        }
      }
      if (appInsights) {
        appInsights.trackEvent({
          name: "Page Load Add Program",
          properties: {
            TimeTaken: `${(new Date().getTime() - start) / 1000} s`,
          },
        });
      }
    } catch (error) {
      setPageLoader(false);
      console.error(error);
    }
  }

  const handleValidation = () => {
    setIsFormValid(false);
    const userAccess = JSON.parse(localStorage.getItem("UserAccess"));
    if (state.requestStatus === requestStatus.cancelled) {
      return;
    }
    if (userAccess.roles.length === 1 && userAccess.roles.some(role => role.name === userRoles.marketSourceUser) &&
      state.requestStatus && !(state.requestStatus === requestStatus.rejectToSubmitter)
    ) {
      return;
    }
    if (requestId) {
      if (state.requestStatus === requestStatus.pendingESG) {
        if (state.effectiveDate && state.programToAdd && state.programToAdd !== 'Choose Program'
          && employee.selectedEmployee?.EmployeeID && state.esgApproval && state.esgAssignedTo) {
          setIsFormValid(true);
        }
      }
      else if (state.requestStatus === requestStatus.pendingPayroll) {
        if (state.effectiveDate && state.programToAdd && state.programToAdd !== 'Choose Program' &&
          employee.selectedEmployee?.EmployeeID && state.payrollApproval && state.payrollAssignedTo) {
          setIsFormValid(true);
        }
      }
    }
    else {
      if (state.effectiveDate && state.programToAdd && state.programToAdd !== 'Choose Program'
        && employee.selectedEmployee?.EmployeeID) {
        setIsFormValid(true);
      }
    }
  }

  const getEmployees = async (searchTerm, companyId) => {
    try {
      setLoading(true)
      const controller = new AbortController();
      const { signal } = controller;
      previousController.current = controller;
      const empResponse = await getEmployeesFromSearch(searchTerm, companyId, signal);
      if (empResponse) {
        setEmployee({ ...employee, searchResults: [...empResponse] });
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const handleInput = (e) => {
    try {
      const name = e.target.name;
      const value = e.target.value;
      if (name === 'employeeCompany') {
        setEmployee({ ...employee, selectedEmployee: null, searchResults: [] });
      }
      setState({ ...state, [name]: value });
    } catch (error) {
      console.error(error);
    }
  };

  const onChangeUser = (e) => {
    try {
      const name = e.target.name;
      const displayName = e?.detail?.[0]?.displayName;
      if (displayName) {
        const email = e?.detail?.[0]?.userPrincipalName;
        setState({ ...state, [name]: displayName, [`${name}Email`]: email });
      }
      else {
        setState({
          ...state, [name]: '', [`${name}Email`]: ''
          , [`${name}EmailDisplay`]: ''
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSelectedEmployee = (value) => {
    try {
      if (value && Object.keys(value).length > 0) {
        setPrograms(value.CompanyID);
        const selectedEmployee = value;
        postApi(process.env.REACT_APP_ESF_BEARERTOKEN, { ResourceID: process.env.REACT_APP_ESF_EMP_RESOURCEID })
          .then((tokenResponse) => {
            const bearerToken = "Bearer " + tokenResponse.access_token;
            const callURL = process.env.REACT_APP_ESF_EMPDETAILS.replace('{employeeId}', selectedEmployee.SupervisorID);
            getApi(callURL, bearerToken)
              .then((response) => {
                const supervisorEmail = response?.employees?.[0].EmailPreferred;
                if (supervisorEmail) {
                  selectedEmployee['SupervisorEmail'] = supervisorEmail;
                }
                setEmployee({ ...employee, selectedEmployee });
              })
              .catch((err) => {
                console.error(" MuleSoft Employee Search API Fetch aborted " + err);
              });
          });
      }
      else {
        setEmployee({ ...employee, selectedEmployee: null, searchResults: [] });
      }
    } catch (error) {
      console.error(error);
    }
  }

  const prepareBody = () => {
    try {
      let method = "CREATE";
      let reqId = requestId;
      let reqStatus = requestStatus.pendingESG;
      const paramAddProgramData = {
        actionComments: state.actionComments,
        programToAdd: state.programToAdd,
        submitterPhone: state.submitterPhone,
        Modified: getFormattedDate(new Date()),
        ModifiedBy: user.userName,
        effectiveDate: moment(state.effectiveDate).format(
          "M/D/YYYY"
        ),
        dateApprovedByESG: state.dateApprovedByESG,
        completedDate: state.completedDate,
        // Employee information 
        empBusinessUnit: employee.selectedEmployee.ProductID,
        empClass: employee.selectedEmployee.EmployeeClass,
        empCompany: employee.selectedEmployee.CompanyID,
        empId: employee.selectedEmployee.EmployeeID,
        empName: employee.selectedEmployee.Name,
        empPosition: employee.selectedEmployee.JobDescription,
        empProgram: `${employee.selectedEmployee.DepartmentDescription} - ${employee.selectedEmployee.DepartmentID}`,
        empState: employee.selectedEmployee.HomeState,
        empSupervisor: employee.selectedEmployee.SupervisorID,
        empSupervisorEmail: employee.selectedEmployee.SupervisorEmail
      };
      if (requestId) {
        method = "UPDATE";
        paramAddProgramData.submitterName = state.submitterName;
        paramAddProgramData.submitterEmail = state.submitterEmail;
        paramAddProgramData.dateSubmitted = state.dateSubmitted;
        if (approvalSection.showESGSection) {
          paramAddProgramData.esgApproval = state.esgApproval;
          paramAddProgramData.esgAssignedTo = state.esgAssignedTo;
          paramAddProgramData.esgAssignedToEmail = state.esgAssignedToEmail;
          paramAddProgramData.esgComments = state.esgComments;
        }
        if (approvalSection.showPayrollSection) {
          paramAddProgramData.payrollApproval = state.payrollApproval;
          paramAddProgramData.payrollAssignedTo = state.payrollAssignedTo;
          paramAddProgramData.payrollAssignedToEmail = state.payrollAssignedToEmail;
          paramAddProgramData.payrollComments = state.payrollComments;
        }

        paramAddProgramData.requestStatus = state.requestStatus;
        if (state.esgApproval === 'Approve & Complete') {
          paramAddProgramData.requestStatus = requestStatus.complete;
          paramAddProgramData.dateApprovedByESG = getFormattedDate(new Date());
          paramAddProgramData.completedDate = getFormattedDate(new Date());
        }
        else if (state.payrollApproval === 'Approve & Complete') {
          paramAddProgramData.requestStatus = requestStatus.complete;
          paramAddProgramData.completedDate = getFormattedDate(new Date());
        }
      }
      else {
        reqId = Math.floor(Math.random() * 1677721555).toString(16);
        if (employee.selectedEmployee.CompanyID === 'MSC') {
          reqStatus = requestStatus.pendingPayroll;
        }
        paramAddProgramData.requestStatus = reqStatus;
        paramAddProgramData.dateSubmitted = getFormattedDate(new Date());
        paramAddProgramData.submitterName = user.userName;
        paramAddProgramData.submitterEmail = user.userEmail;
      }
      paramAddProgramData.requestId = reqId;
      const body = {
        Method: method,
        AddProgramData: paramAddProgramData,
        requestID: reqId
      };
      return body;
    } catch (error) {
      console.error(error);
    }
  }

  const setPrograms = async (companyId) => {
    try {
      setOptionsProgramToAdd([{ key: 'Choose Program', text: 'Choose Program' }]);
      const response = await getDepartments(companyId);
      if (response.Departments?.length > 0) {
        const programs = [{ key: 'Choose Program', text: 'Choose Program' }];
        for (let index = response.Departments?.length - 1; index >= 0; index--) {
          const dept = response.Departments[index];
          programs.push({ key: dept.DepartmentID, text: dept.DescriptionDepartment });
        }
        setOptionsProgramToAdd([...programs]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const setEmployeeInfo = async (employeeId) => {
    try {
      return getEmployee(employeeId).then((empInfo) => {
        if (empInfo) {
          setEmployee({
            ...employee, selectedEmployee: empInfo
          });
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const onClickCancel = async () => {
    const effectiveDateValue = state.effectiveDate?.replace('Z', '');
    const dateSubmittedValue = state.dateSubmitted?.replace('Z', '');
    const request = {
      requestId: requestId,
      requestStatus: state.requestStatus,
      dateSubmitted: dateSubmittedValue ? moment(dateSubmittedValue).format(
        "MM/DD/YY hh:mm A"
      ) : "",
      effectiveDate: effectiveDateValue ? moment(effectiveDateValue).format(
        "MM/DD/YY"
      ) : "",
      submitterName: state.submitterName,
      submitterEmail: state.submitterEmail,
      empId: employee.selectedEmployee.EmployeeID,
      empName: employee.selectedEmployee.Name,
      empPosition: employee.selectedEmployee.JobDescription,
      empSupervisor: employee.selectedEmployee.SupervisorID,
      empProgram: `${employee.selectedEmployee.DepartmentDescription} - ${employee.selectedEmployee.DepartmentID}`,
      formName: 'Add Program'
    };
    const response = await cancelRequest(request);
    if (response) {
      navigate(`/approval-queue/`);
    }
  }

  const cancelVisibility = async (roles, status) => {
    setIsCancelVisible(false);
    if (status !== requestStatus.cancelled && status !== requestStatus.complete) {
      const rolesAllowed = process.env.REACT_APP_CANCELLATION_ROLES_ALLOWED;
      if (roles.some(role => rolesAllowed.includes(role.name))) {
        setIsCancelVisible(true);
      }
    }
  }

  const submitProgram = async () => {
    try {
      const start = new Date().getTime();
      setPageLoader(true);
      const body = prepareBody();
      postApi(process.env.REACT_APP_ESF_ADDPROGRAM, body).then((response) => {
        if (appInsights) {
          appInsights.trackEvent({
            name: `Page ${requestId ? 'Update' : 'Submit'} Add Program`,
            properties: {
              TimeTaken: `${(new Date().getTime() - start) / 1000} s`,
            },
          });
        }
        setPageLoader(false);
        if (response.ResultSets?.Table1?.[0]?.CREATEspRESULT) {
          Swal.fire({
            title: "Something went wrong...",
            html: response.ResultSets?.Table1?.[0]?.CREATEspRESULT,
            icon: "error",
          });
        } else {
          if (!Object.keys(response).includes('error')) {
            Swal.fire({
              title: "Submitted Successfully",
              icon: "success",
              html: `<a href="${redirectUrl(user.roles)}" target="_blank">Go to Home</a>`,
              confirmButtonText: "View Details"
            })
              .then(async (result) => {
                setIsFormValid(false);
                if (result.isConfirmed) {
                  navigate(`/${formRoutes.addProgram}/view/${body.requestID}`);
                }
              });
          } else {
            console.error(response.error);
          }
        }
      }
      );
    } catch (error) {
      setPageLoader(false);
      console.error(error);
    }
  };

  return (
    <main>
      {pageLoader && <div className="loader">
        <Oval color="#035797" height={100} width={100} />
      </div>}
      <Title title={title} color={bgColorsTitle['add-Program']} />
      <Note note={note} />
      <section>
        {isCancelVisible &&
          <>
            <br />
            <SubmitButton label="Cancel This Form" onClick={onClickCancel} color={"#A51D25"} />
            <br />
          </>
        }
        {!requestId && <EsfInput
          label="Employee's Location"
          name="employeeCompany"
          type="select"
          value={state.employeeCompany}
          options={employeeCompanyLocations}
          onChange={handleInput}
          required
          disabled={requestId ? true : false}
        />
        }
        {(state.employeeCompany && state.employeeCompany !== 'Choose a Location' || requestId) &&
          <>
            <EmployeeSearch
              employees={employee.searchResults}
              selectedEmployee={employee.selectedEmployee}
              setSelectedEmployee={handleSelectedEmployee}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              loading={loading}
              headerExpand
              disabled={requestId ? true : false}
            />
            {!employee.selectedEmployee && <label className="labelNormal">{employeeSearchText.message}</label>}
          </>
        }
        <EsfInput
          label="Effective Date:"
          name="effectiveDate"
          type="date"
          value={state.effectiveDate}
          onChange={handleInput}
          required
        />
        {employee.selectedEmployee && <EsfInput
          label="Program to Add:"
          name="programToAdd"
          type="select"
          value={state.programToAdd}
          options={optionsProgramToAdd}
          onChange={handleInput}
          required
        />}
        <EsfInput
          label="Your Phone Number:"
          name="submitterPhone"
          type="text"
          value={state.submitterPhone}
          onChange={handleInput}
        />
        <EsfInput
          placeHolder="Add any additional comments..."
          name="actionComments"
          type="textarea"
          value={state.actionComments}
          onChange={handleInput}
        />
        {approvalSection.showESGSection &&
          <div className="outerDiv">
            <div>
              <p>{approvalSectionTitle.esg}</p>
              <EsfInput
                label="Approval"
                name="esgApproval"
                type="select"
                value={state.esgApproval}
                options={optionsESGApprovalReason}
                onChange={handleInput}
                required
                disabled={disableSection.esg}
              />
              <User
                label='Assigned To'
                name='esgAssignedTo'
                onChange={onChangeUser}
                defaultSelectedUser={state.esgAssignedToEmailDisplay ? [state.esgAssignedToEmailDisplay] : []}
                required
                disabled={disableSection.esg}
              />
              <EsfInput
                placeHolder="Comments"
                name="esgComments"
                type="textarea"
                value={state.esgComments}
                onChange={handleInput}
                disabled={disableSection.esg}
              />
            </div>
          </div>}
        {approvalSection.showPayrollSection &&
          <div className="outerDiv">
            <div>
              <p>{approvalSectionTitle.payroll}</p>
              <EsfInput
                label="Approval"
                name="payrollApproval"
                type="select"
                value={state.payrollApproval}
                options={optionsPayrollApprovalReason}
                onChange={handleInput}
                required
                disabled={disableSection.payroll}
              />
              <User
                label='Assigned To'
                name='payrollAssignedTo'
                onChange={onChangeUser}
                defaultSelectedUser={state.payrollAssignedToEmailDisplay ? [state.payrollAssignedToEmailDisplay] : []}
                required
                disabled={disableSection.payroll}
              />
              <EsfInput
                placeHolder="Comments"
                name="payrollComments"
                type="textarea"
                value={state.payrollComments}
                onChange={handleInput}
                disabled={disableSection.payroll}
              />
            </div>
          </div>}
        {!isFormValid && (!requestId || state.requestStatus !== requestStatus.complete) && <SubmitButton label="Please complete all required fields." />}
        {isFormValid && <SubmitButton label="Submit" onClick={submitProgram} />}
      </section>
      <br />
      <br />
      {gridRows.length > 0 && (
        <div className="outerDiv">
          <div>
            <p>{workflowHistory.title}</p>
            <Grid rowData={gridRows} columnDefs={gridColumns} />
          </div></div>
      )}
    </main>
  );
};

export default AddProgram;