import React from "react";
//import "./styles.css";
import EsfInput from "../../components/EsfInput";
import { useState } from "react";
import Toggle from "../../components/Toggle";
import SubmitButton from "../../components/SubmitButton";
import Title from "../../components/Title";
import Note from "../../components/Note";
import { useEffect, useRef } from "react";
import { getTemporaryEmpId, postApi } from "../../utils/fetchApi";
import moment from "moment";
import { useParams } from "react-router-dom";
import EmployeeSearch from "../../components/EmployeeSearch";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Grid from "../../components/Grid";
import User from "../../components/User";
import { cancelRequest, getFormattedDate, redirectUrl } from "../../utils/globalUtils";

import { postValuesApi } from "../../utils/fetchApi";
import {
  requestStatus,
  workflowHistory,
  compensationType,
  employeeSearchText,
  approvalSectionTitle,
  approvalOptions,
  employeeCompanyLocations
} from "../../utils/constants";
import { useMsal } from "@azure/msal-react";
import { userRoles } from "../../utils/constants";
import {
  getJobTitle,
  getEmployee,
  getDepartments,
  getEmployeesFromSearch,
} from "../../utils/employeeService";
import { appInsights } from "../../components/AppInsights";
const Transfer = ({ title, note }) => {
  const { instance } = useMsal();
  const navigate = useNavigate();
  const previousController = useRef();
  const previousControllerEmp = useRef();
  const { requestId } = useParams();

  const [isFormComplete, setIsFormComplete] = useState(false);
  const [Transfer, setTransfer] = useState({
    dateSubmitted: null,
    EffectiveDate: "",
    BusinessUnit: "",
    EmployeeFutureLocation: "",
    NewJobTitle: "",
    payRate: "",
    BonusFrequency: "",
    AnnualizedBonusPotential: "",
    PhoneNumber: "",
    AdditionalComments: "",
    submitterEmail: "",
    DepartmentName: "",
    compensationType: true,
    lblSunday: "labelError",
    emailAccountChangeRequiredPTtoFTorFTtoPT: true,
    businessTitle: "",
    financeLevel: "1",
    employeeCompany: "",
    programDepartmentCompany: "",
    programDepartmentCompanyCode: "",
    submitButtonText: (
      <span>
        Please complete all required fields.<br></br>Please choose a Sunday for
        Effective date.
      </span>
    ),
  });
  const [programDepartmentCompanyCode, setprogramDepartmentCompanyCode] = useState(undefined);
  const [user, setUser] = useState({
    userName: "",
    userEmail: "",
    roles: ""
  });
  const [optionsJobTitle, setOptionsJobTitle] = useState({
    JobTitle: [{ key: "Choose Job Title", text: "Choose Job Title" }],
  });
  const [employeeData, setEmployeeData] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(undefined);
  const [LocationOptions, setLocationOptions] = useState({
    empLocation: [{ key: "Choose a Location", text: "Choose a Location" }],
    jobTitle: [{ key: "Choose Job Title", text: "Choose Job Title" }],
  });
  const [gridRows, setGridRows] = useState([]);
  const [gridColumns, setGridColumns] = useState([]);
  const [showPayrateError, setShowPayrateError] = useState(false);
  const [getRqStatus, setGetRqStatus] = useState("");
  const [getClass, setgetClass] = useState("");

  const [showHideAccountingControls, setshowHideAccountingControls] =
    useState(false);
  const [showHideFinanceControls, setshowHideFinanceControls] = useState(false);
  const [showHideEsgControls, setShowHideEsgControls] = useState(false);
  const [showHidePayrollControls, setShowHidePayrollControls] = useState(false);
  const [showHideCommercialHrControls, setshowHideCommercialHrControls] =
    useState(false);
  const [showHideBillableHrControls, setshowHideBillableHrControls] =
    useState(false);
  const [deptOptions, setDeptOptions] = useState({
    Department: [{ key: "Choose Program", text: "Choose Program" }],
  });
  const [loading, setLoading] = useState(false);
  const [loadingSupervisor, setLoadingSupervisor] = useState(false);
  const [isCancelVisible, setIsCancelVisible] = useState(false);
  const [searchQueryEmployee, setSearchQueryEmployee] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const [showBonusPotential, setShowBonusPotential] = useState(false);
  const [showBonusPotentialError, setShowBonusPotentialError] = useState(false);
  const [supervisorData, setSupervisorData] = useState({
    searchResults: [],
    selectedSupervisor: null,
  });

  const [commercialhrData, setcommercialhrData] = useState({
    commercialhrApproval: "Pending Review",
    commercialhrAssignedTo: "",
    commercialhrAssignedToEmail: "",
    commercialhrAssignedToEmailDisplay: "",
    commercialhrComments: "",
  });
  const [billablehrData, setbillablehrData] = useState({
    billablehrApproval: "Pending Review",
    billablehrAssignedTo: "",
    billablehrAssignedToEmail: "",
    billablehrAssignedToEmailDisplay: "",
    billablehrComments: "",
  });
  const [esgData, setEsgData] = useState({
    esgApproval: "Pending Review",
    esgAssignedTo: "",
    esgAssignedToEmail: "",
    esgAssignedToEmailDisplay: "",
    esgComments: "",
    esgPayrollComments: "",
  });

  const [PayrollData, setPayrollData] = useState({
    payrollApproval: "Pending Review",
    payrollAssignedTo: "",
    payrollAssignedToEmail: "",
    payrollAssignedToEmailDisplay: "",
    payrollComments: "",
  });

  const [accountingData, setaccountingDataa] = useState({
    accountingApproval: "Pending Review",
    accountingAssignedTo: "",
    accountingAssignedToEmail: "",
    accountingAssignedToEmailDisplay: "",
    accountingComments: "",
  });

  const [financeData, setfinanceData] = useState({
    financeApproval: "Pending Review",
    financeAssignedTo: "",
    financeAssignedToEmail: "",
    financeAssignedToEmailDisplay: "",
    financeComments: "",

  });

  const [disableSection, setDisableSection] = useState({
    commercialHR: true,
    billableHR:true,
    accounting: true,
    finance: true,
    esg: true,
    payroll: true,
  });

  useEffect(() => {
    if (!searchQueryEmployee || searchQueryEmployee.length <= 2) {
      if (previousControllerEmp.current) {
        previousControllerEmp.current.abort();
      }
      setEmployeeData([]);
      setLoading(false);
    }
    if (searchQueryEmployee?.length === 3) {
      const companyId = employeeCompanyLocations.filter(item => item.text === Transfer.employeeCompany)[0]?.key;
      if (companyId) {
        getEmployees(searchQueryEmployee, companyId);
      }
    }
  }, [searchQueryEmployee]);

  const handleInputCommercialHR = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setcommercialhrData({ ...commercialhrData, [name]: value });
  };
  const handleInputBillableHR = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setbillablehrData({ ...billablehrData, [name]: value });
  };
  const handleInputESG = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setEsgData({ ...esgData, [name]: value });
  };
  const handleInputPayroll = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setPayrollData({ ...PayrollData, [name]: value });
  };

  const handleInputAccounting = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setaccountingDataa({ ...accountingData, [name]: value });
  };
  const handleInputFinance = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setfinanceData({ ...financeData, [name]: value });
  };

  const HRApprovalOptions = [
    { key: "Pending Review", text: "Pending Review" },
    { key: "Approve", text: "Approve" },
    { key: "Reject to Submitter", text: "Reject to Submitter" },
  ];
  const ESGApprovalOptions = [
    { key: "Pending Review", text: "Pending Review" },
    { key: "Approve & Complete", text: "Approve & Complete" },
    { key: "Approve & Submit to Payroll", text: "Approve & Submit to Payroll" },
  ];

  const PayrollOptions = [
    { key: "Pending Review", text: "Pending Review" },

    { key: "Complete", text: "Complete" },
  ];

  const BusinessUnitoptions = [
    { key: "Choose Business Unit", text: "Choose Business Unit" },
    { key: "Corporate Commercial - 1", text: "Corporate: MS Commercial & Salelytics - 1" },
    { key: "Corporate Retail - 81", text: "Corporate Retail - 81" },
    { key: "Retail Program - 82", text: "Retail Program - 82" },
    { key: "Commercial Program - 83", text: "Client Dedicated: MS Commercial & Salelytics - 83" },
  ];

  const BonusFrequency = [
    { key: "Choose Frequency", text: "Choose Frequency" },
    { key: "Annual", text: "Annual" },
    { key: "Quarterly", text: "Quarterly" },
    { key: "Monthly", text: "Monthly" },
    { key: "Weekly", text: "Weekly" },
    { key: "Not Eligible", text: "Not Eligible" },
  ];

  useEffect(() => {
    handleValidation();
  }, [
    Transfer,
    selectedEmployee,
    commercialhrData,
    billablehrData,
    accountingData,
    financeData,
    esgData,
    PayrollData,
    supervisorData
  ]);

  const isDefaultValidationPassed = () => {
    let isValid = false;
    if (
      moment(Transfer.EffectiveDate).day() === 0 &&
      Transfer.NewJobTitle &&
      Transfer.NewJobTitle !== "Choose Job Title" &&
      Transfer.DepartmentName &&
      Transfer.DepartmentName !== "Choose Program" &&
      Transfer.EmployeeFutureLocation &&
      Transfer.EmployeeFutureLocation !== "Choose a Location" &&
      Transfer.payRate &&
      !showPayrateError &&
      Transfer.BonusFrequency
    ) {
      if (showBonusPotential) {
        if (Transfer.AnnualizedBonusPotential && !showBonusPotentialError) {
          isValid = true;
        }
      } else {
        isValid = true;
      }
    }
    return isValid;
  };

  const isCommercialHRValidationPassed = () => {
    let isValid = false;
    if (
      commercialhrData.commercialhrApproval &&
      commercialhrData.commercialhrAssignedTo
    ) {
      isValid = true;
    }
    return isValid;
  };
  const isBillableHRValidationPassed = () => {
    let isValid = false;
    if (
      billablehrData.billablehrApproval &&
      billablehrData.billablehrAssignedTo
    ) {
      isValid = true;
    }
    return isValid;
  };
  const isAccountingValidationPassed = () => {
    let isValid = false;
    if (
      accountingData.accountingApproval &&
      accountingData.accountingAssignedTo
    ) {
      isValid = true;
    }
    return isValid;
  };

  const isFinanceValidationPassed = () => {
    let isValid = false;
    if (financeData.financeApproval && financeData.financeAssignedTo && Transfer.financeLevel) {
      isValid = true;
    }
    return isValid;
  };

  const isESGValidationPassed = () => {
    let isValid = false;
    if (esgData.esgApproval =="Pending Review" || ( esgData.esgApproval &&  esgData.esgAssignedTo)) {
      isValid = true;
    }
    return isValid;
  };

  const isPayrollValidationPassed = () => {
    let isValid = false;
    if (PayrollData.payrollApproval && PayrollData.payrollAssignedTo) {
      isValid = true;
    }
    return isValid;
  };

  const handleValidation = () => {
    setIsFormComplete(false);
    const userAccess = JSON.parse(localStorage.getItem("UserAccess"));
    if (getRqStatus === requestStatus.cancelled) {
      return;
    }
    if (userAccess.roles.length === 1 && userAccess.roles.some(role => role.name === userRoles.marketSourceUser) &&
      getRqStatus && !(getRqStatus === requestStatus.rejectToSubmitter)
    ) {
      return;
    }
    let isFormValid = false;
    isFormValid = isDefaultValidationPassed();
    if (requestId) {
      if (showHideCommercialHrControls) {
        isFormValid = isFormValid && isCommercialHRValidationPassed();
      }
      if (showHideBillableHrControls) {
        isFormValid = isFormValid && isBillableHRValidationPassed();
      }
      if (showHideAccountingControls) {
        isFormValid = isFormValid && isAccountingValidationPassed();
      }
      if (showHideFinanceControls) {
        isFormValid = isFormValid && isFinanceValidationPassed();
      }
      if (showHideEsgControls) {
        isFormValid = isFormValid && isESGValidationPassed();
      }
      if (showHidePayrollControls) {
        isFormValid = isFormValid && isPayrollValidationPassed();
      }
      if (getRqStatus === requestStatus.rejectToSubmitter) {
        const userAccess = JSON.parse(localStorage.getItem("UserAccess"));
        if (!userAccess.roles.some(role => role.name === userRoles.esfOwners) &&
          Transfer.submitterEmail !== user.userEmail) {
          isFormValid = false;
        }
      }

    }
    setIsFormComplete(isFormValid);
  };

  const getDropdownOptions = async () => {
    postValuesApi(
      process.env.REACT_APP_ESF_GETCHOICEVALUES,
      {
        RelatedProcess: "Transfer",
      }
    ).then((result) => {
      const locations = result.ResultSets.Table2?.map((val) => {
        return { key: val.locationID, text: val.LocationDescription };
      });

      setLocationOptions({
        empLocation: [...[{ key: "Choose a Location", text: "Choose a Location" }], ...locations],
      });
    });
  };

  const handleSelectedEmployee = (value) => {
    try {
      if (value && Object.keys(value).length > 0) {
        setSelectedEmployee(value);
        setJobTitle(value.ProductID);
        //setDepartments(value.CompanyID);
      } else {
        setSelectedEmployee(null);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const setDepartments = async (companyId) => {
    try {
      setDeptOptions({ Department: [{ key: 'Choose Program', text: 'Choose Program' }] });
      const response = await getDepartments(companyId);
      if (response.Departments?.length > 0) {
        const programs = [{ key: 'Choose Program', text: 'Choose Program' }];
        for (let index = response.Departments?.length - 1; index >= 0; index--) {
          const dept = response.Departments[index];
          programs.push({ key: dept.DepartmentID, text: dept.DescriptionDepartment });
        }
        setDeptOptions({ Department: [...programs] });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleInput = (event) => {
    try {
      switch (event.target.name) {
        case "EffectiveDate":
          let lblClass = "labelError";
          let submitButtonText = (
            <span>
              Please complete all required fields.<br></br>Please choose a Sunday
              for Effective date.
            </span>
          );
          if (moment(event.target.value).day() === 0) {
            lblClass = "labelNormal";
            submitButtonText = "Please complete all required fields.";
          }
          setTransfer({ ...Transfer, lblSunday: lblClass, submitButtonText });
          break;


        case "payRate":
          const compType = Transfer.compensationType
            ? compensationType.salary
            : compensationType.hourly;
          payRateValidation(compType, event.target.value);
          break;


        case "BonusFrequency":
          let showBonusPotential = true;
          if (
            event.target.value === "Not Eligible" ||
            event.target.value === "Choose Frequency"
          ) {
            showBonusPotential = false;
            setTransfer({ ...Transfer, AnnualizedBonusPotential: "" });
          }
          setShowBonusPotential(showBonusPotential);
          break;
        case "AnnualizedBonusPotential":
          bonusPotentialValidation(event.target.value);
          break;

        case "employeeCompany":
          setEmployeeData([]);
          setSelectedEmployee(undefined);
          break;

        case "supervisorCompany":
          setSupervisorData({ searchResults: [], selectedSupervisor: null });
          break;

        case "programDepartmentCompany":

          const test = event.target.value;
          console.log('employeeCompanyLocations', employeeCompanyLocations);
          employeeCompanyLocations.map((loc) => {
            if (loc.text === test) {
              setTransfer((Transfer) => ({
                ...Transfer,
                ["programDepartmentCompanyCode"]: loc.key,
              }));
              setDepartments(loc.key);
            }

          })


          break;

        default:
          break;
      }
      setTransfer((Transfer) => ({
        ...Transfer,
        [event.target.name]: event.target.value,
      }));
    } catch (error) {
      console.error(error);
    }
  };





  const InsertData = async () => {
    const start = new Date().getTime();
    const reqID = Math.floor(Math.random() * 1677721555).toString(16);

    try {
      let RqStatus = requestStatus.pendingFinance;

      if (selectedEmployee.EmployeeClass === "INT") { // Soma code added
        RqStatus = requestStatus.pendingAccounting;
      } else if (selectedEmployee.ProductID === "83" && ( selectedEmployee.EmployeeClass === "CNS"||  selectedEmployee.EmployeeClass === "EXT")) {
        RqStatus = requestStatus.pendingCommercialHR;
      } else {
        RqStatus = requestStatus.pendingHr;
      }

      let creationResponse = await fetch(
        process.env.REACT_APP_ESF_TRASFER,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },

          body: JSON.stringify({
            Method: "CREATE",
            TransferData: {
              ModifiedBy: user.userName,
              submitterName: user.userName,
              submitterEmail: user.userEmail,
              dateSubmitted: getFormattedDate(new Date()),
              actionComments: Transfer.AdditionalComments,
              newBusinessUnit: Transfer.BusinessUnit,
              newLocation: Transfer.EmployeeFutureLocation,
              payRate: Transfer.payRate,
              bonusFrequency: Transfer.BonusFrequency,
              bonusPotential: Transfer.AnnualizedBonusPotential,
              submitterPhone: Transfer.PhoneNumber,
              Modified: getFormattedDate(new Date()),
              effectiveDate: moment(Transfer.EffectiveDate).format("M/D/YYYY"),
              compensationType: Transfer.compensationType ? "Salary" : "Hourly",
              actionType: "Transfer",
              actionReason: "Intercompany Transfer",
              emailAccountChangeRequiredPTtoFTorFTtoPT: Transfer.emailAccountChangeRequiredPTtoFTorFTtoPT ? "No" : "Yes",
              programName: Transfer.DepartmentName,
              requestStatus: RqStatus,
              newSupervisorName: supervisorData.selectedSupervisor.Name,
              newSupervisorId: supervisorData.selectedSupervisor.EmployeeID,

              // Employee Info
              empBusinessUnit: selectedEmployee.ProductID,
              empClass: selectedEmployee.EmployeeClass,
              empCompany: selectedEmployee.CompanyID,
              empHireDate: selectedEmployee.HireDate,
              empId: selectedEmployee.EmployeeID,
              empName: selectedEmployee.Name,
              empPosition: selectedEmployee.JobDescription,
              empState: selectedEmployee.HomeState,
              empSupervisor: supervisorData.selectedSupervisor.EmployeeID,
              empSupervisorEmail:
                supervisorData.selectedSupervisor.EmailPreferred,
              empEmail: selectedEmployee.EmailPreferred,
              empLocation: selectedEmployee.OfficeLocationID,
              empProgram: `${selectedEmployee.DepartmentDescription} - ${selectedEmployee.DepartmentID}`,
              newJobTitle: Transfer.NewJobTitle,
              isSupervisor: selectedEmployee.isSupervisor,
              requestId: reqID,
              businessTitle: Transfer.businessTitle,
              programDepartmentCompany: Transfer.programDepartmentCompany,
              programDepartmentCompanyCode: Transfer.programDepartmentCompanyCode
            },
            requestID: reqID,
          }),
          formName: "Transfer",
        }
      );

      if (appInsights) {
        appInsights.trackEvent({
          name: `Page Submit Transfer`,
          properties: {
            TimeTaken: `${(new Date().getTime() - start) / 1000} s`,
          },
        });
      }

      let creationStatus = await creationResponse.json();

      if (creationStatus.ResultSets?.Table1?.[0]?.CREATEspRESULT) {
        Swal.fire({
          title: "Something went wrong...",
          html: creationStatus.ResultSets?.Table1?.[0]?.CREATEspRESULT,
          icon: "error",
        });
        console.error(creationStatus.error);
      }
      else {
        if (!Object.keys(creationStatus).includes('error')) {
          Swal.fire({
            title: "Submitted Successfully",
            icon: "success",
            html: `<a href="${redirectUrl(user.roles)}" target="_blank">Go to Home</a>`,
            confirmButtonText: "View Details",
          }).then(async (result) => {
            setIsFormComplete(false);
            if (result.isConfirmed) {
              navigate(`/transfer/view/${reqID}`);
            }
          });
        }

      }


    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const start = new Date().getTime();
    getDropdownOptions();
    let employeeId = "";

    const account = instance.getActiveAccount();
    let roles = [];
    const userAccess = JSON.parse(localStorage.getItem("UserAccess"));
    if (userAccess) {
      roles = userAccess.roles;
      employeeId = userAccess.employeeID[0].employeeId;
    }
    setUser({ ...user, userName: account.name, userEmail: account.username, roles: roles });
    if (getTemporaryEmpId(account.username)) {
      employeeId = getTemporaryEmpId(account.username);
    }
    //employeeId = "07180029"
    if (requestId) {
      const TransferHistory = postApi(
        process.env.REACT_APP_ESF_WORKFLOW_HISTORY,
        {
          formName: "Transfer",
          requestID: requestId,
        }
      );
      const requestDetails = postApi(
        process.env.REACT_APP_ESF_TRASFER,
        {
          Method: "READ",
          requestId,
        }
      );

      Promise.all([TransferHistory, requestDetails]).then(
        ([resTransferHistory, resRequestDetails]) => {
          if (
            !Object.keys(resTransferHistory).includes("error") &&
            !Object.keys(resRequestDetails).includes("error")
          ) {
            if (resRequestDetails.ResultSets.Table1?.[0]) {
              const jsonValue = resRequestDetails.ResultSets.Table1[0];
              cancelVisibility(roles, jsonValue.requestStatus);
              const historyResult = resTransferHistory.ResultSets.Table1;
              if (historyResult && historyResult.length > 0) {
                const headers = Object.keys(historyResult[0]).map((col) => {
                  return {
                    field: col,
                    filter: true,
                    floatingFilter: true,
                    sortable: true,
                  };
                });
                setGridRows(historyResult);
                setGridColumns(headers);
              }
              if (
                jsonValue.bonusPotential &&
                jsonValue.bonusPotential !== "Not Eligible"
              ) {
                setShowBonusPotential(true);
              }

              payRateValidation(
                jsonValue.compensationType,
                jsonValue.payRate
              );
              bonusPotentialValidation(jsonValue.bonusPotential);
              let esgStatus = jsonValue.esgApproval;
              let empBU = jsonValue.empBusinessUnit;
              let EmpClass = jsonValue.empClass;
              const empCompany = jsonValue.empCompany;
              setgetClass(EmpClass);
              let reqStatus = jsonValue.requestStatus;
              setGetRqStatus(reqStatus);

              if (reqStatus === requestStatus.pendingCommercialHR) {
                setshowHideCommercialHrControls(true);
              }
              else if (reqStatus === requestStatus.pendingHr) {
                setshowHideBillableHrControls(true);
              }
              else if (reqStatus === requestStatus.pendingFinance) {//Soma
                setshowHideFinanceControls(true);
                if (empBU === "83" && ( EmpClass === "CNS"||  EmpClass === "EXT")) {
                  setshowHideCommercialHrControls(true);
                } else if(EmpClass !== "INT") {
                  setshowHideBillableHrControls(true);
                }
              }
              else if (reqStatus === requestStatus.pendingAccounting) { // Soma
                setshowHideAccountingControls(true);
                if (EmpClass !== "INT" && empBU === "83" && ( EmpClass === "CNS"||  EmpClass === "EXT")) {
                  setshowHideCommercialHrControls(true);
                } else if(EmpClass !== "INT") {
                  setshowHideBillableHrControls(true);
                }
              }
              else if (reqStatus === requestStatus.pendingESG) {
                setShowHideEsgControls(true);

                if (empBU === "81" || empBU === "1") {
                  setshowHideAccountingControls(true);
                } else {
                  setshowHideFinanceControls(true);
                }
                if (EmpClass !== "INT" && empBU === "83" && ( EmpClass === "CNS"||  EmpClass === "EXT")) {
                  setshowHideCommercialHrControls(true);
                } else if(EmpClass !== "INT") {
                  setshowHideBillableHrControls(true);
                }
              }

              else if (reqStatus === requestStatus.pendingPayroll) {
                setShowHidePayrollControls(true);
                if (empCompany !== "MSC") {
                  setShowHideEsgControls(true);
                }

                if (EmpClass !== "INT" && empBU === "83" && ( EmpClass === "CNS"||  EmpClass === "EXT")) {
                  setshowHideCommercialHrControls(true);
                } else if(EmpClass !== "INT") {
                  setshowHideBillableHrControls(true);
                }

                if (empBU === "81" || empBU === "1") {
                  setshowHideAccountingControls(true);
                } else {
                  setshowHideFinanceControls(true);
                }
              }

              else if (reqStatus === requestStatus.rejectToSubmitter) { //Soma
                if (jsonValue.commercialhrApproval) {
                  setshowHideCommercialHrControls(true);
                } if (jsonValue.billablehrApproval) {
                  setshowHideBillableHrControls(true);
                } if (jsonValue.accountingApproval) {
                  setshowHideAccountingControls(true);
                } if (jsonValue.financeApproval) {
                  setshowHideFinanceControls(true);
                }
              }

              else if (reqStatus === requestStatus.complete) {

                if (jsonValue.payrollApproval) {
                  setShowHidePayrollControls(true);
                }

                if (jsonValue.esgApproval) {
                  setShowHideEsgControls(true);
                }
                if (EmpClass !== "INT" && empBU === "83" && ( EmpClass === "CNS"||  EmpClass === "EXT")) {
                  setshowHideCommercialHrControls(true);
                } else if(EmpClass !== "INT") {
                  setshowHideBillableHrControls(true);
                }

                if (empBU === "81" || empBU === "1") {
                  setshowHideAccountingControls(true);
                } else {
                  setshowHideFinanceControls(true);
                }
              }

              setTransfer({
                ...Transfer,
                EffectiveDate: moment(jsonValue.effectiveDate).format(
                  "YYYY-MM-DD"
                ),
                lblSunday:
                  moment(jsonValue.effectiveDate).day() === 0
                    ? "labelNormal"
                    : "labelError",
                submitButtonText:
                  moment(jsonValue.effectiveDate).day() === 0 ? (
                    "Please complete all required fields."
                  ) : (
                    <span>
                      Please complete all required fields.<br></br>Please
                      choose a Sunday for Effective date.
                    </span>
                  ),

                ["PhoneNumber"]: jsonValue.submitterPhone,
                submitterName: jsonValue.submitterName,
                submitterEmail: jsonValue.submitterEmail,
                EmployeeFutureLocation: jsonValue.newLocation,
                ["AdditionalComments"]: jsonValue.actionComments,
                BusinessUnit: jsonValue.newBusinessUnit,
                ["NewJobTitle"]: jsonValue.newJobTitle,
                dateSubmitted: jsonValue.dateSubmitted,
                ["payRate"]: jsonValue.payRate,
                ["BonusFrequency"]: jsonValue.bonusFrequency,
                ["AnnualizedBonusPotential"]: jsonValue.bonusPotential,
                ["compensationType"]:
                  jsonValue.compensationType === "Salary" ? true : false,

                ["DepartmentName"]: jsonValue.programName,
                emailAccountChangeRequiredPTtoFTorFTtoPT: jsonValue.emailAccountChangeRequiredPTtoFTorFTtoPT === "No" ? true : false,
                businessTitle: jsonValue.businessTitle,
                programDepartmentCompany: jsonValue.programDepartmentCompany,
                programDepartmentCompanyCode: jsonValue.programDepartmentCompanyCode,
                financeLevel: jsonValue.financeLevel
                  ? jsonValue.financeLevel
                  : Transfer.financeLevel,
              });

              setSelectedEmployee({
                // HomeState: jsonValue.empState,

                ProductID: jsonValue.empBusinessUnit,
                EmployeeClass: jsonValue.empClass,
                CompanyID: jsonValue.empCompany,
                HireDate: jsonValue.empHireDate,
                EmployeeID: jsonValue.empId,
                Name: jsonValue.empName,
                JobDescription: jsonValue.empPosition,
                HomeState: jsonValue.empState,
                SupervisorID: jsonValue.empSupervisor,
                EmailPreferred: jsonValue.empEmail,
                OfficeLocationID: jsonValue.empLocation,
                DepartmentDescription: jsonValue.empProgram.split(" - ")?.[0],
                DepartmentID: jsonValue.empProgram.split(" - ")?.[1],
              });
              if (
                jsonValue.compensationType === compensationType.hourly &&
                parseFloat(jsonValue.payRate) > parseFloat("999.99")
              ) {
                setShowPayrateError(true);
              }

              if (
                jsonValue.requestStatus === requestStatus.pendingCommercialHR &&
                (roles.some(role => role.name === userRoles.esfOwners) ||
                  roles.some(role => role.name === userRoles.adminBillable) ||
                  roles.some(role => role.name === userRoles.adminInternal) ||
                  roles.some((role) => role.name === userRoles.commercialHR))
              ) {
                setDisableSection({ ...disableSection, commercialHR: false });
              } else if (
                jsonValue.requestStatus === requestStatus.pendingHr &&
                (roles.some(role => role.name === userRoles.esfOwners) ||
                  roles.some(role => role.name === userRoles.adminBillable) ||
                  roles.some(role => role.name === userRoles.adminInternal) ||
                  roles.some((role) => role.name === userRoles.hrBillable) ||
                  roles.some((role) => role.name === userRoles.hrInternal))
              ) {
                setDisableSection({ ...disableSection, billableHR: false });
              } else if (
                jsonValue.requestStatus === requestStatus.pendingAccounting &&
                (roles.some(role => role.name === userRoles.esfOwners) ||
                  roles.some(role => role.name === userRoles.adminBillable) ||
                  roles.some(role => role.name === userRoles.adminInternal) ||
                  roles.some((role) => role.name === userRoles.accounting))
              ) {
                setDisableSection({ ...disableSection, accounting: false });
              } else if (
                jsonValue.requestStatus === requestStatus.pendingFinance &&
                (roles.some(role => role.name === userRoles.esfOwners) ||
                  roles.some(role => role.name === userRoles.adminBillable) ||
                  roles.some(role => role.name === userRoles.adminInternal) ||

                  roles.some((role) => role.name === userRoles.finance))
              ) {
                setDisableSection({ ...disableSection, finance: false });
              } else if (
                jsonValue.requestStatus === requestStatus.pendingESG &&
                (roles.some(role => role.name === userRoles.esfOwners) ||
                  roles.some(role => role.name === userRoles.adminBillable) ||
                  roles.some(role => role.name === userRoles.adminInternal) ||
                  roles.some(
                    (role) =>
                      role.name === userRoles.esgBillable ||
                      role.name === userRoles.esgInternal
                  ))
              ) {
                setDisableSection({ ...disableSection, esg: false });
              } else if (
                jsonValue.requestStatus === requestStatus.pendingPayroll &&
                (roles.some(role => role.name === userRoles.esfOwners) ||
                  roles.some(role => role.name === userRoles.adminBillable) ||
                  roles.some(role => role.name === userRoles.adminInternal) ||
                  roles.some(
                    (role) =>
                      role.name === userRoles.payrollCanada ||
                      role.name === userRoles.payrollInternal ||
                      role.name === userRoles.payrollBillable
                  ))
              ) {
                setDisableSection({ ...disableSection, payroll: false });
              }
              setSupervisorInfo(jsonValue.newSupervisorId);
              setJobTitle(jsonValue.empBusinessUnit)
              setDepartments(jsonValue.programDepartmentCompanyCode);
              setcommercialhrData({

                commercialhrApproval: jsonValue.commercialhrApproval
                  ? jsonValue.commercialhrApproval
                  : commercialhrData.commercialhrApproval,
                commercialhrAssignedTo: jsonValue.commercialhrAssignedTo,
                commercialhrAssignedToEmail:
                  jsonValue.commercialhrAssignedToEmail,
                commercialhrAssignedToEmailDisplay:
                  jsonValue.commercialhrAssignedToEmail,
                commercialhrComments: jsonValue.commercialhrComments,
              });
              setbillablehrData({

                billablehrApproval: jsonValue.billablehrApproval
                  ? jsonValue.billablehrApproval
                  : billablehrData.billablehrApproval,
                billablehrAssignedTo: jsonValue.billablehrAssignedTo,
                billablehrAssignedToEmail:
                  jsonValue.billablehrAssignedToEmail,
                billablehrAssignedToEmailDisplay:
                  jsonValue.billablehrAssignedToEmail,
                billablehrComments: jsonValue.billablehrComments,
              });
              setEsgData({
                esgApproval: jsonValue.esgApproval
                  ? jsonValue.esgApproval
                  : esgData.esgApproval,

                // esgApproval: jsonValue.esgApproval,
                esgAssignedTo: jsonValue.esgAssignedTo,
                esgAssignedToEmail: jsonValue.esgAssignedToEmail,
                esgAssignedToEmailDisplay: jsonValue.esgAssignedToEmail,
                esgComments: jsonValue.esgComments,
                esgPayrollComments: jsonValue.esgPayrollComments,
              });

              setPayrollData({
                payrollApproval: jsonValue.payrollApproval
                  ? jsonValue.payrollApproval
                  : PayrollData.payrollApproval,

                // payrollApproval: jsonValue.payrollApproval,
                payrollAssignedTo: jsonValue.payrollAssignedTo,
                payrollAssignedToEmail: jsonValue.payrollAssignedToEmail,
                payrollAssignedToEmailDisplay: jsonValue.payrollAssignedToEmail,
                payrollComments: jsonValue.payrollComments,
              });

              setaccountingDataa({
                accountingApproval: jsonValue.accountingApproval
                  ? jsonValue.accountingApproval
                  : accountingData.accountingApproval,

                // accountingApproval: jsonValue.accountingApproval,
                accountingAssignedTo: jsonValue.accountingAssignedTo,
                accountingComments: jsonValue.accountingComments,
                accountingAssignedToEmail: jsonValue.accountingAssignedToEmail,
                accountingAssignedToEmailDisplay: jsonValue.accountingAssignedToEmail,
              });

              setfinanceData({
                financeApproval: jsonValue.financeApproval
                  ? jsonValue.financeApproval
                  : financeData.financeApproval,

                //financeApproval: jsonValue.financeApproval,
                financeAssignedTo: jsonValue.financeAssignedTo,
                financeAssignedToEmail: jsonValue.financeAssignedToEmail,
                financeAssignedToEmailDisplay: jsonValue.financeAssignedToEmail,
                financeComments: jsonValue.financeComments,
              });
            }
          } else {
            Swal.fire({
              title: "Something went wrong...",

              icon: "error",
            });
          }
        }
      );
    } else {
      setSupervisorInfo(employeeId);
    }
    if (appInsights) {
      appInsights.trackEvent({
        name: "Page Load Transfer",
        properties: {
          TimeTaken: `${(new Date().getTime() - start) / 1000} s`,
        },
      });
    }
  }, []);


  const resetApproval = (paramTransfer) => {

    paramTransfer.commercialhrApproval = null;
    paramTransfer.commercialhrAssignedTo = null;
    paramTransfer.commercialhrAssignedToEmail = null;
    paramTransfer.commercialhrComments = null;

    paramTransfer.billablehrApproval = null;
    paramTransfer.billablehrAssignedTo = null;
    paramTransfer.billablehrAssignedToEmail = null;
    paramTransfer.billablehrComments = null;

    paramTransfer.accountingApproval = null;
    paramTransfer.accountingAssignedTo = null;
    paramTransfer.accountingAssignedToEmail = null;
    paramTransfer.accountingComments = null;

    paramTransfer.financeApproval = null;
    paramTransfer.financeAssignedTo = null;
    paramTransfer.financeAssignedToEmail = null;
    paramTransfer.financeLevel = null;
    paramTransfer.financeComments = null;
  }

  const updateData = async () => {
    const start = new Date().getTime();
    let date = new Date();
    try {
      let newRqStatus = getRqStatus;
      let paramTransfer = {

        ModifiedBy: user.userName,
        submitterEmail: Transfer.submitterEmail,
        submitterName: Transfer.submitterName,
        actionComments: Transfer.AdditionalComments,
        newBusinessUnit: Transfer.BusinessUnit,
        newLocation: Transfer.EmployeeFutureLocation,
        payRate: Transfer.payRate,
        bonusFrequency: Transfer.BonusFrequency,
        bonusPotential: Transfer.AnnualizedBonusPotential,
        submitterPhone: Transfer.PhoneNumber,
        Modified: getFormattedDate(new Date()),
        dateSubmitted: Transfer.dateSubmitted,
        effectiveDate: moment(Transfer.EffectiveDate).format("M/D/YYYY"),
        compensationType: Transfer.compensationType ? "Salary" : "Hourly",

        emailAccountChangeRequiredPTtoFTorFTtoPT: Transfer.emailAccountChangeRequiredPTtoFTorFTtoPT ? "No" : "Yes",
        programName: Transfer.DepartmentName,
        //requestStatus: newRqStatus,
        newSupervisorName: supervisorData.selectedSupervisor.Name,
        newSupervisorId: supervisorData.selectedSupervisor.EmployeeID,
        // Employee Info
        empBusinessUnit: selectedEmployee.ProductID,
        empClass: selectedEmployee.EmployeeClass,
        empCompany: selectedEmployee.CompanyID,
        empHireDate: selectedEmployee.HireDate,
        empId: selectedEmployee.EmployeeID,
        empName: selectedEmployee.Name,
        empPosition: selectedEmployee.JobDescription,

        empState: selectedEmployee.HomeState,
        empSupervisor: selectedEmployee.SupervisorID,
        empSupervisorEmail: supervisorData.selectedSupervisor.EmailPreferred,
        empEmail: selectedEmployee.EmailPreferred,
        empLocation: selectedEmployee.OfficeLocationID,
        empProgram: `${selectedEmployee.DepartmentDescription} - ${selectedEmployee.DepartmentID}`,
        newJobTitle: Transfer.NewJobTitle,
        businessTitle: Transfer.businessTitle,
        requestId: requestId,
        actionType: "Transfer",
        actionReason: "Intercompany Transfer",
        programDepartmentCompany: Transfer.programDepartmentCompany,
        programDepartmentCompanyCode: Transfer.programDepartmentCompanyCode,

      };

      if (showHideCommercialHrControls) {
        paramTransfer.commercialhrApproval =
          commercialhrData.commercialhrApproval;
        paramTransfer.commercialhrAssignedTo =
          commercialhrData.commercialhrAssignedTo;
        paramTransfer.commercialhrAssignedToEmail =
          commercialhrData.commercialhrAssignedToEmail;
        paramTransfer.commercialhrComments =
          commercialhrData.commercialhrComments;
      }
      if (showHideBillableHrControls) {
        paramTransfer.billablehrApproval =
          billablehrData.billablehrApproval;
        paramTransfer.billablehrAssignedTo =
          billablehrData.billablehrAssignedTo;
        paramTransfer.billablehrAssignedToEmail =
          billablehrData.billablehrAssignedToEmail;
        paramTransfer.billablehrComments =
          billablehrData.billablehrComments;
      }
      if (showHideAccountingControls) {
        paramTransfer.accountingApproval = accountingData.accountingApproval;
        paramTransfer.accountingAssignedTo =
          accountingData.accountingAssignedTo;
        paramTransfer.accountingAssignedToEmail =
          accountingData.accountingAssignedToEmail;
        paramTransfer.accountingComments = accountingData.accountingComments;
        paramTransfer.dateApprovedByAccountingFinance = getFormattedDate(new Date())
      }

      if (showHideFinanceControls) {
        paramTransfer.financeLevel = Transfer.financeLevel
        paramTransfer.financeApproval = financeData.financeApproval;
        paramTransfer.financeAssignedTo = financeData.financeAssignedTo;
        paramTransfer.financeAssignedToEmail =
          financeData.financeAssignedToEmail;
        paramTransfer.financeComments = financeData.financeComments;
        paramTransfer.dateApprovedByAccountingFinance = getFormattedDate(new Date())
      }

      if (showHideEsgControls) {
        paramTransfer.esgApproval = esgData.esgApproval;
        paramTransfer.esgAssignedTo = esgData.esgAssignedTo;
        paramTransfer.esgAssignedToEmail = esgData.esgAssignedToEmail;
        paramTransfer.esgComments = esgData.esgComments;
        paramTransfer.esgPayrollComments= esgData.esgPayrollComments;
        paramTransfer.dateApprovedByESG = getFormattedDate(new Date())
      }
      if (showHidePayrollControls) {
        paramTransfer.payrollApproval = PayrollData.payrollApproval;
        paramTransfer.payrollAssignedTo = PayrollData.payrollAssignedTo;
        paramTransfer.payrollAssignedToEmail =
          PayrollData.payrollAssignedToEmail;
        paramTransfer.payrollComments = PayrollData.payrollComments;
        paramTransfer.completedDate = getFormattedDate(new Date())
      }
      if ( // Soma
        getRqStatus == requestStatus.pendingCommercialHR &&
        commercialhrData.commercialhrApproval === "Approve"
      ) {
        newRqStatus = requestStatus.pendingFinance;
        // if (getClass === "INT") {
        //   newRqStatus = requestStatus.pendingAccounting;
        // }
      } 
      else if ( 
        getRqStatus == requestStatus.pendingHr &&
        billablehrData.billablehrApproval === "Approve"
      ) {
        newRqStatus = requestStatus.pendingFinance;        
      } else if (
        getRqStatus == requestStatus.pendingCommercialHR &&
        commercialhrData.commercialhrApproval === requestStatus.rejectToSubmitter
      ) {
        newRqStatus = requestStatus.rejectToSubmitter;
      } else if (
        getRqStatus == requestStatus.pendingHr &&
        billablehrData.billablehrApproval === requestStatus.rejectToSubmitter
      ) {
        newRqStatus = requestStatus.rejectToSubmitter;
      }

      if (
        getRqStatus == requestStatus.pendingAccounting &&
        accountingData.accountingApproval === "Approve"
      ) {
        newRqStatus = requestStatus.pendingESG;
        if (selectedEmployee.CompanyID === "MSC") {
          newRqStatus = requestStatus.pendingPayroll
        }
      } else if (
        getRqStatus == requestStatus.pendingAccounting &&
        accountingData.accountingApproval === requestStatus.rejectToSubmitter
      ) {
        newRqStatus = requestStatus.rejectToSubmitter;
      }
      if (
        getRqStatus == requestStatus.pendingFinance &&
        financeData.financeApproval === "Approve"
      ) {

        newRqStatus = requestStatus.pendingESG;
        if (selectedEmployee.CompanyID === "MSC") {
          newRqStatus = requestStatus.pendingPayroll
        }


      } else if (
        getRqStatus == requestStatus.pendingFinance &&
        financeData.financeApproval === requestStatus.rejectToSubmitter
      ) {
        newRqStatus = requestStatus.rejectToSubmitter;
      } else if (
        getRqStatus === requestStatus.pendingESG &&
        esgData.esgApproval === "Approve & Complete"
      ) {
        newRqStatus = requestStatus.complete;
      } else if (
        getRqStatus === requestStatus.pendingESG &&
        esgData.esgApproval === "Approve & Submit to Payroll"
      ) {
        newRqStatus = requestStatus.pendingPayroll;
      } else if (
        getRqStatus == requestStatus.pendingPayroll &&
        PayrollData.payrollApproval === requestStatus.complete
      ) {
        newRqStatus = requestStatus.complete;
      } else if (getRqStatus == requestStatus.rejectToSubmitter) {
        resetApproval(paramTransfer)
        newRqStatus = requestStatus.pendingFinance;
        if (selectedEmployee.EmployeeClass !== "INT" && selectedEmployee.ProductID === "83" && ( selectedEmployee.EmployeeClass === "CNS"||  selectedEmployee.EmployeeClass === "EXT")) {
          newRqStatus = requestStatus.pendingCommercialHR;
        } else if(selectedEmployee.EmployeeClass !== "INT") {
          newRqStatus = requestStatus.pendingHr;
        }else if (selectedEmployee.ProductID === "1" || selectedEmployee.ProductID === "81") {
          newRqStatus = requestStatus.pendingAccounting;
        }
      }

      paramTransfer.requestStatus = newRqStatus;



      let updateResponse = await fetch(
        process.env.REACT_APP_ESF_TRASFER,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            Method: "UPDATE",

            TransferData: paramTransfer,
            requestID: requestId,
          }),
          formName: "Transfer",
        }
      );

      if (appInsights) {
        appInsights.trackEvent({
          name: `Page Update Transfer`,
          properties: {
            TimeTaken: `${(new Date().getTime() - start) / 1000} s`,
          },
        });
      }

      let updateStatus = await updateResponse.json();
      if (updateStatus.ResultSets?.Table1?.[0]?.CREATEspRESULT) {
        Swal.fire({
          title: "Something went wrong...",
          html: updateStatus.ResultSets?.Table1?.[0]?.CREATEspRESULT,
          icon: "error",
        });
        console.error(updateStatus.error);
      }
      else {
        if (!Object.keys(updateStatus).includes('error')) {
          Swal.fire({
            title: "Submitted Successfully",
            icon: "success",
            html: `<a href="${redirectUrl(user.roles)}" target="_blank">Go to Home</a>`,
            confirmButtonText: "View Details",
          }).then(async (result) => {
            setIsFormComplete(false);
            if (result.isConfirmed) {
              navigate(`/transfer/view/${requestId}`);
            }
          });
        }

      }
      // Swal.fire({
      //   title: "Submitted Successfully",
      //   icon: "success",
      //   html: `<a href="${redirectUrl(user.roles)}" target="_blank">Go to Home</a>`,
      //   confirmButtonText: "View Details",
      // }).then(async (result) => {
      //   setIsFormComplete(false);
      //   if (result.isConfirmed) {
      //     navigate(`/transfer/view/${requestId}`);
      //   }
      // });
    } catch (error) {
      console.error(error);
    }
  };

  const onClickCancel = async () => {
    const effectiveDateValue = Transfer.EffectiveDate?.replace('Z', '');
    const dateSubmittedValue = Transfer.dateSubmitted?.replace('Z', '');
    const request = {
      requestId: requestId,
      requestStatus: getRqStatus,
      dateSubmitted: dateSubmittedValue ? moment(dateSubmittedValue).format(
        "MM/DD/YY hh:mm A"
      ) : "",
      effectiveDate: effectiveDateValue ? moment(effectiveDateValue).format(
        "MM/DD/YY"
      ) : "",
      submitterName: Transfer.submitterName,
      submitterEmail: Transfer.submitterEmail,
      empId: selectedEmployee.EmployeeID,
      empName: selectedEmployee.Name,
      empPosition: selectedEmployee.JobDescription,
      empSupervisor: supervisorData.selectedSupervisor.EmployeeID,
      empProgram: `${selectedEmployee.DepartmentDescription} - ${selectedEmployee.DepartmentID}`,
      formName: 'Transfer'
    };
    const response = await cancelRequest(request);
    if (response) {
      navigate(`/approval-queue/`);
    }
  }

  const cancelVisibility = async (roles, status) => {
    setIsCancelVisible(false);
    if (status !== requestStatus.cancelled && status !== requestStatus.complete) {
      const rolesAllowed = process.env.REACT_APP_CANCELLATION_ROLES_ALLOWED;
      if (roles.some(role => rolesAllowed.includes(role.name))) {
        setIsCancelVisible(true);
      }
    }
  }

  const onChangeUser = (e) => {
    try {
      const name = e.target.name;

      const displayName = e?.detail?.[0]?.displayName;
      switch (name) {
        case "commercialhrAssignedTo":
          if (displayName) {
            const email = e?.detail?.[0]?.userPrincipalName;
            setcommercialhrData({
              ...commercialhrData,
              commercialhrAssignedTo: displayName,
              commercialhrAssignedToEmail: email,
            });
          } else {
            setcommercialhrData({
              ...commercialhrData,
              commercialhrAssignedTo: "",
              commercialhrAssignedToEmail: "",
              commercialhrAssignedToEmailDisplay: "",
            });
          }
          break;
          case "billablehrAssignedTo":
            if (displayName) {
              const email = e?.detail?.[0]?.userPrincipalName;
              setbillablehrData({
                ...billablehrData,
                billablehrAssignedTo: displayName,
                billablehrAssignedToEmail: email,
              });
            } else {
              setbillablehrData({
                ...billablehrData,
                billablehrAssignedTo: "",
                billablehrAssignedToEmail: "",
                billablehrAssignedToEmailDisplay: "",
              });
            }
            break;
        case "esgAssignedTo":
          if (displayName) {
            const email = e?.detail?.[0]?.userPrincipalName;
            setEsgData({
              ...esgData,
              esgAssignedTo: displayName,
              esgAssignedToEmail: email,
            });
          } else {
            setEsgData({
              ...esgData,
              esgAssignedTo: "",
              esgAssignedToEmail: "",
              esgAssignedToEmailDisplay: "",
            });
          }
          break;
        case "accountingAssignedTo":
          if (displayName) {
            const email = e?.detail?.[0]?.userPrincipalName;
            setaccountingDataa({
              ...accountingData,
              accountingAssignedTo: displayName,
              accountingAssignedToEmail: email,
            });
          } else {
            setaccountingDataa({
              ...accountingData,
              accountingAssignedTo: "",
              accountingAssignedToEmail: "",
              accountingAssignedToEmailDisplay: "",
            });
          }
          break;
        case "financeAssignedTo":
          if (displayName) {
            const email = e?.detail?.[0]?.userPrincipalName;
            setfinanceData({
              ...financeData,
              financeAssignedTo: displayName,
              financeAssignedToEmail: email,
            });
          } else {
            setfinanceData({
              ...financeData,
              financeAssignedTo: "",
              financeAssignedToEmail: "",
              financeAssignedToEmailDisplay: "",
            });
          }
          break;
        case "payrollAssignedTo":
          if (displayName) {
            const email = e?.detail?.[0]?.userPrincipalName;
            setPayrollData({
              ...PayrollData,
              payrollAssignedTo: displayName,
              payrollAssignedToEmail: email,
            });
          } else {
            setPayrollData({
              ...PayrollData,
              payrollAssignedTo: "",
              payrollAssignedToEmail: "",
              payrollAssignedToEmailDisplay: "",
            });
          }
          break;
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!searchQuery || searchQuery.length <= 2) {
      if (previousController.current) {
        previousController.current.abort();
      }
      setSupervisorData({ ...supervisorData, searchResults: [] });
      setLoadingSupervisor(false);
    }
    if (searchQuery?.length === 3) {
      const companyId = employeeCompanyLocations.filter(item => item.text === Transfer.supervisorCompany)[0]?.key;
      if (companyId) {
        getSupervisors(searchQuery, companyId);
      }
    }
  }, [searchQuery]);

  const getEmployees = async (searchTerm, companyId) => {
    try {
      setLoading(true);
      const controller = new AbortController();
      const { signal } = controller;
      previousControllerEmp.current = controller;
      const empResponse = await getEmployeesFromSearch(searchTerm, companyId, signal);
      if (empResponse) {
        setEmployeeData([...empResponse]);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const getSupervisors = async (searchTerm, companyId) => {
    try {
      setLoadingSupervisor(true);
      const controller = new AbortController();
      const { signal } = controller;
      previousController.current = controller;
      const empResponse = await getEmployeesFromSearch(searchTerm, companyId, signal);
      if (empResponse) {
        setSupervisorData({
          ...supervisorData,
          searchResults: [...empResponse],
        });
      }
      setLoadingSupervisor(false);
    } catch (error) {
      console.log(error);
      setLoadingSupervisor(false);
    }
  };

  const setJobTitle = async (productId) => {
    try {
      const response = await getJobTitle(productId);
      if (response.JobCodes?.length > 0) {
        const jobs = response.JobCodes.map((job) => {
          return { key: job.JobCode, text: job.DescriptionJobCode };
        });

        setOptionsJobTitle({
          JobTitle: [
            ...[{ key: "Choose Job Title", text: "Choose Job Title" }],
            ...jobs.sort((a, b) => (a.text > b.text ? 1 : -1)),
          ],
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const setSupervisorInfo = async (employeeId) => {
    try {
      setLoadingSupervisor(true);
      const supervisorInfo = await getEmployee(employeeId);
      if (supervisorInfo) {
        //setDepartments(supervisorInfo.CompanyID);
        //setJobTitle(supervisorInfo.ProductID);
        setSupervisorData({
          ...supervisorData,
          selectedSupervisor: supervisorInfo,
        });
      }
      setLoadingSupervisor(false);
    } catch (error) {
      console.log(error);
      setLoadingSupervisor(false);
    }
  };

  const handleSelectedSupervisor = (value) => {
    try {
      if (value && Object.keys(value).length > 0) {
        const selectedSupervisor = value;
       // setJobTitle(value.ProductID);
       // setDepartments(value.CompanyID);
        setSupervisorData({ ...supervisorData, selectedSupervisor });
      } else {
        setSupervisorData({
          ...supervisorData,
          selectedSupervisor: null,
          searchResults: [],
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const payRateValidation = (compType, payRate) => {
    if (payRate) {
      if (compType === compensationType.salary) {
        if (
          parseFloat(payRate) >= parseFloat("10000.00") &&
          parseFloat(payRate) <= parseFloat("999999.99")
        ) {
          setShowPayrateError(false);
        }
        else {
          setShowPayrateError(true);
        }
      } else {
        if (
          parseFloat(payRate) >= parseFloat("1") &&
          parseFloat(payRate) <= parseFloat("99.99")
        ) {
          setShowPayrateError(false);
        }
        else {
          setShowPayrateError(true);
        }
      }
    }
  };

  const handleToggleChange = (checked) => {
    const compType = checked
      ? compensationType.salary
      : compensationType.hourly;
    payRateValidation(compType, Transfer.payRate);
    setTransfer((state) => ({
      ...state,
      compensationType: checked,

    }));
  };


  const handleToggleEmailChange = (checked) => {
    setTransfer({
      ...Transfer,
      emailAccountChangeRequiredPTtoFTorFTtoPT: checked
    });
  };

  const bonusPotentialValidation = (bonusPotential) => {
    if (bonusPotential) {
      if (parseFloat(bonusPotential) <= parseFloat("9999999.99")) {
        setShowBonusPotentialError(false);
      }
      else {
        setShowBonusPotentialError(true);
      }
    }
  };

  return (
    <main>
      <Title title={title} color={"#8dc63f"} />
      <Note note={note} />
      <section>
        {isCancelVisible &&
          <>
            <br />
            <SubmitButton label="Cancel This Form" onClick={onClickCancel} color={"#A51D25"} />
            <br />
          </>
        }
        {!requestId && <EsfInput
          label="Employee's Location"
          name="employeeCompany"
          type="select"
          value={Transfer.employeeCompany}
          options={employeeCompanyLocations}
          onChange={handleInput}
          required
          disabled={requestId ? true : false}
        />
        }
        {(Transfer.employeeCompany && Transfer.employeeCompany !== 'Choose a Location' || requestId) &&
          <>
            <EmployeeSearch
              employees={employeeData}
              selectedEmployee={selectedEmployee}
              setSelectedEmployee={handleSelectedEmployee}
              searchQuery={searchQueryEmployee}
              setSearchQuery={setSearchQueryEmployee}
              loading={loading}
              headerExpand
              disabled={requestId ? true : false}
            />
            {!selectedEmployee && (
              <label className="labelNormal">{employeeSearchText.message}</label>
            )}
          </>
        }

        {!requestId && <EsfInput
          label="Supervisor's Location"
          name="supervisorCompany"
          type="select"
          value={Transfer.supervisorCompany}
          options={employeeCompanyLocations}
          onChange={handleInput}
          required
          disabled={requestId ? true : false}
        />
        }

        <EmployeeSearch
          employees={supervisorData.searchResults}
          selectedEmployee={supervisorData.selectedSupervisor}
          setSelectedEmployee={handleSelectedSupervisor}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          loading={loadingSupervisor}
          supervisor
          disabled={requestId ? true : false}
        />
        {!supervisorData.selectedSupervisor && (
          <label className="labelNormal">{employeeSearchText.message}</label>
        )}

        <EsfInput
          type="date"
          name="EffectiveDate"
          label="Effective Date"
          alert="Sundays only"
          required
          value={Transfer.EffectiveDate}
          onChange={handleInput}
        />
        <label className={Transfer.lblSunday}>Sundays only</label>

        {selectedEmployee && <EsfInput
          type="select"
          name="NewJobTitle"
          label="New Job Title"
          required
          value={Transfer.NewJobTitle}
          onChange={handleInput}
          options={optionsJobTitle.JobTitle}
        />}

        <EsfInput
          label="Company"
          name="programDepartmentCompany"
          type="select"
          value={Transfer.programDepartmentCompany}
          options={employeeCompanyLocations}
          onChange={handleInput}
          required
        />

        {((Transfer.programDepartmentCompanyCode && Transfer.programDepartmentCompanyCode !== 'Choose a Location') || requestId) && <EsfInput
          type="select"
          name="DepartmentName"
          label="Program/Department Name"
          required
          value={Transfer.DepartmentName}
          onChange={handleInput}
          options={deptOptions.Department}
        />}


        <EsfInput
          type="select"
          name="BusinessUnit"
          label="Business Unit"
          value={Transfer.BusinessUnit}
          onChange={handleInput}
          options={BusinessUnitoptions}
        />

        <EsfInput
          type="select"
          name="EmployeeFutureLocation"
          label="Employee's Future Location"
          required
          value={Transfer.EmployeeFutureLocation}
          onChange={handleInput}
          options={LocationOptions.empLocation}
        />



        <Toggle
          label="How is pay entered?"
          name="compensationType"
          onText="Salary"
          offText="Hourly"
          value={Transfer.compensationType}
          onChange={handleToggleChange}
        />

        <EsfInput
          type="currency"
          label="Pay Rate"
          name="payRate"
          required
          value={Transfer.payRate}
          onChange={handleInput}
        />
        {showPayrateError && (
          <label className="labelError">
            {Transfer.compensationType
              ? "Pay rate entered must have a minimum of 5 and a maximum of 6 digits before the decimal (10,000.00 - 999,999.99)."
              : "Pay rate entered may have up to 2 digits before and after the decimal (1 - 99.99)."}
          </label>
        )}
        <EsfInput
          type="select"
          name="BonusFrequency"
          label="Bonus Frequency"
          required
          value={Transfer.BonusFrequency}
          onChange={handleInput}
          options={BonusFrequency}
        />

        {showBonusPotential && (
          <div>
            <EsfInput
              type="currency"
              label="Annualized Bonus Potential"
              required
              name="AnnualizedBonusPotential"
              value={Transfer.AnnualizedBonusPotential}
              onChange={handleInput}
            />
            {showBonusPotentialError && (
              <>
                <label className="labelError">
                  Bonus Potential cannot be greater than seven digits (before
                  the decimal place).
                </label>
                <br />
                <br />
              </>
            )}


            <label className="labelNormal">
              Enter annualized bonus potential amount regardless of payout
              frequency.
            </label>
          </div>
        )}

        <Toggle
          label="Is the employee changing from PT to FT or FT to PT?"
          name="emailAccountChangeRequiredPTtoFTorFTtoPT"
          onText="No"
          offText="Yes"
          value={Transfer.emailAccountChangeRequiredPTtoFTorFTtoPT}
          onChange={handleToggleEmailChange}
        />

        <EsfInput
          label="Client Billing Title, if different from Job Title"
          name="businessTitle"
          type="text"
          value={Transfer.businessTitle}
          onChange={handleInput}
          placeHolder="Client Billing Title"
        />



        <EsfInput
          label="Phone Number:"
          name="PhoneNumber"
          type="text"
          value={Transfer.PhoneNumber}
          onChange={handleInput}
        />
        <EsfInput

          placeHolder="Add any additional comments..."
          name="AdditionalComments"
          type="textarea"
          value={Transfer.AdditionalComments}
          onChange={handleInput}
        />

        <>
          {isFormComplete && getRqStatus === requestStatus.rejectToSubmitter && getRqStatus !== requestStatus.complete && (
            <SubmitButton
              label="Submit"
              onClick={requestId ? updateData : InsertData}
            />
          )}
        </>

        {showHideCommercialHrControls && (
          <div className="outerDiv">
            <div>
              <p>{approvalSectionTitle.commercialHR}</p>
              <EsfInput
                type="select"
                name="commercialhrApproval"
                label="Approval"
                required
                value={commercialhrData.commercialhrApproval}
                onChange={handleInputCommercialHR}
                options={HRApprovalOptions}
                disabled={disableSection.commercialHR}
              />

              <User
                label="Assigned To"
                name="commercialhrAssignedTo"
                onChange={onChangeUser}
                defaultSelectedUser={
                  commercialhrData.commercialhrAssignedToEmailDisplay
                    ? [commercialhrData.commercialhrAssignedToEmailDisplay]
                    : []
                }
                required
                disabled={disableSection.commercialHR}
              />

              <EsfInput

                placeHolder="Comments"
                name="commercialhrComments"
                type="textarea"
                value={commercialhrData.commercialhrComments}
                onChange={handleInputCommercialHR}
                disabled={disableSection.commercialHR}
              />
            </div>
          </div>
        )}
        {showHideBillableHrControls && (
          <div className="outerDiv">
            <div>
              <p>{approvalSectionTitle.hr}</p>
              <EsfInput
                type="select"
                name="billablehrApproval"
                label="Approval"
                required
                value={billablehrData.billablehrApproval}
                onChange={handleInputBillableHR}
                options={HRApprovalOptions}
                disabled={disableSection.billableHR}
              />

              <User
                label="Assigned To"
                name="billablehrAssignedTo"
                onChange={onChangeUser}
                defaultSelectedUser={
                  billablehrData.billablehrAssignedToEmailDisplay
                    ? [billablehrData.billablehrAssignedToEmailDisplay]
                    : []
                }
                required
                disabled={disableSection.billableHR}
              />

              <EsfInput

                placeHolder="Comments"
                name="billablehrComments"
                type="textarea"
                value={billablehrData.billablehrComments}
                onChange={handleInputBillableHR}
                disabled={disableSection.billableHR}
              />
            </div>
          </div>
        )}
        {showHideAccountingControls && (
          <div className="outerDiv">
            <div>
              <p>{approvalSectionTitle.accounting}</p>
              <EsfInput
                type="select"
                name="accountingApproval"
                label="Approval"
                required
                value={accountingData.accountingApproval}
                onChange={handleInputAccounting}
                options={HRApprovalOptions}
                disabled={disableSection.accounting}
              />

              <User
                label="Assigned To"
                name="accountingAssignedTo"
                onChange={onChangeUser}
                defaultSelectedUser={
                  accountingData.accountingAssignedToEmailDisplay
                    ? [accountingData.accountingAssignedToEmailDisplay]
                    : []
                }
                required
                disabled={disableSection.accounting}
              />

              <EsfInput

                placeHolder="Comments"
                name="accountingComments"
                type="textarea"
                value={accountingData.accountingComments}
                onChange={handleInputAccounting}
                disabled={disableSection.accounting}
              />
            </div>
          </div>
        )}

        {showHideFinanceControls && (
          <div className="outerDiv">
            <div>
              <p>{approvalSectionTitle.finance}</p>
              <EsfInput
                type="select"
                name="financeApproval"
                label="Approval"
                required
                value={financeData.financeApproval}
                onChange={handleInputFinance}
                options={HRApprovalOptions}
                disabled={disableSection.finance}
              />

              <EsfInput
                label="Finance Level"
                name="financeLevel"
                type="select"
                value={Transfer.financeLevel}
                options={approvalOptions.financeLevel}
                onChange={handleInput}
                required
                disabled={disableSection.finance}
              />

              <User
                label="Assigned To"
                name="financeAssignedTo"
                onChange={onChangeUser}
                defaultSelectedUser={
                  financeData.financeAssignedToEmailDisplay
                    ? [financeData.financeAssignedToEmailDisplay]
                    : []
                }
                required
                disabled={disableSection.finance}
              />

              <EsfInput

                placeHolder="Comments"
                name="financeComments"
                type="textarea"
                value={financeData.financeComments}
                onChange={handleInputFinance}
                disabled={disableSection.finance}
              />
            </div>
          </div>
        )}

        {showHideEsgControls && (
          <div className="outerDiv">
            <div>
              <p>{approvalSectionTitle.esg}</p>
              <EsfInput
                type="select"
                name="esgApproval"
                required
                label="Approval"
                value={esgData.esgApproval}
                onChange={handleInputESG}
                options={ESGApprovalOptions}
                disabled={disableSection.esg}
              />

              <User
                label="Assigned To"
                name="esgAssignedTo"
                onChange={onChangeUser}
                defaultSelectedUser={
                  esgData.esgAssignedToEmailDisplay ? [esgData.esgAssignedToEmailDisplay] : []
                }
                required ={esgData.esgApproval!="Pending Review" ? true :false}
                disabled={disableSection.esg}
              />

              <EsfInput
                placeHolder="Comments"
                name="esgComments"
                type="textarea"
                value={esgData.esgComments}
                onChange={handleInputESG}
                disabled={disableSection.esg}
              />
              <EsfInput
                label={"Payroll Team Comments"}
                placeHolder="Comments"
                name="esgPayrollComments"
                type="textarea"
                value={esgData.esgPayrollComments}
                onChange={handleInputESG}
                disabled={disableSection.esg}
              />
            </div>
          </div>
        )}

        {showHidePayrollControls && (
          <div className="outerDiv">
            <div>
              <p>{approvalSectionTitle.payroll}</p>
              <EsfInput
                type="select"
                name="payrollApproval"
                label="Approval"
                required
                value={PayrollData.payrollApproval}
                onChange={handleInputPayroll}
                options={PayrollOptions}
                disabled={disableSection.payroll}
              />

              <User
                label="Assigned To"
                name="payrollAssignedTo"
                onChange={onChangeUser}
                defaultSelectedUser={
                  PayrollData.payrollAssignedToEmailDisplay
                    ? [PayrollData.payrollAssignedToEmailDisplay]
                    : []
                }
                required
                disabled={disableSection.payroll}
              />

              <EsfInput

                placeHolder="Comments"
                name="payrollComments"
                type="textarea"
                value={PayrollData.payrollComments}
                onChange={handleInputPayroll}
                disabled={disableSection.payroll}
              />
            </div>
          </div>
        )}

        {!isFormComplete && (!requestId || getRqStatus !== requestStatus.complete) && (
          <SubmitButton label={Transfer.submitButtonText} />
        )}
        {isFormComplete && getRqStatus !== requestStatus.rejectToSubmitter && getRqStatus !== requestStatus.complete && (
          <SubmitButton
            label="Submit"
            onClick={requestId ? updateData : InsertData}
          />
        )}

        <br />
        <br />
        {gridRows.length > 0 && (
          <div className="outerDiv">
            <div>
              <p>{workflowHistory.title}</p>
              <Grid rowData={gridRows} columnDefs={gridColumns} />
            </div></div>
        )}
      </section>
    </main>
  );
};

export default Transfer;
