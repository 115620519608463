import React, { useEffect, useState } from "react";
import "./styles.css";
import { NumericFormat } from "react-number-format";

const EsfInput = ({
  label,
  name,
  onChange,
  value,
  options,
  required,
  type,
  message,
  placeHolder,
  disabled,
}) => {
  const [currencyValue, setCurrencyValue] = useState(
    type === "currency" ? value : undefined
  );
  useEffect(() => {
    console.log("value changed", value);
    if (type === "currency") {
      if (value !== currencyValue) {
        setCurrencyValue(value);
      }
    }
  }, [value]);

  const handleCurrencyChange = (values) => {
    const { floatValue } = values;
    onChange({ target: { name, value: floatValue } }); // Pasa el evento sintético de React
  };

  return (
    <div className="esfInput">
      <div className="labelInput">
        <label htmlFor={name}>
          {label} {required && <span className="required">*</span>}
        </label>
        {type !== "select" && type !== "textarea" && type !== "currency" && (
          <input
            type={type}
            id={name}
            name={name}
            onChange={onChange}
            value={value}
            placeholder={placeHolder}
            disabled={disabled}
          />
        )}
        {type === "select" && (
          <select
            id={name}
            name={name}
            onChange={onChange}
            value={value}
            disabled={disabled}
          >
            {options.map((item) => {
              return (
                <option
                  key={item.key}
                  terminationtype={item.type}
                  terminationdisplaymessage={item.description}
                >
                  {item.text}
                </option>
              );
            })}
          </select>
        )}
        {type === "textarea" && (
          <textarea
            id={name}
            name={name}
            onChange={onChange}
            value={value}
            placeholder={placeHolder}
            disabled={disabled}
          ></textarea>
        )}
        {type === "currency" && (
          <NumericFormat
            thousandSeparator={true}
            decimalScale={2}
            fixedDecimalScale={true}
            prefix={"$"}
            name={name}
            value={value}
            onValueChange={handleCurrencyChange} // Usa 'handleCurrencyChange' en lugar de 'onChange'
            className="form-control"
          />
        )}
      </div>
      {message && <p>{message}</p>}
    </div>
  );
};

export default EsfInput;
