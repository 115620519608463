import React from "react";
import "./styles.css";

const Title = ({ title, color }) => {
  return (
    <div style={{ backgroundColor: color }} className='title'>
      <h1>{title}</h1>
    </div>
  );
};

export default Title;
