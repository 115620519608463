import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { Providers, ProviderState, SimpleProvider } from "@microsoft/mgt-react";
import { InteractionRequiredAuthError, } from "@azure/msal-browser";
import { msalConfig, loginRequest } from "./utils/authConfig";

const msalInstance = new PublicClientApplication(msalConfig);
// Uses MSAL as custom provider for connecting to MS Graph using MS Graph Toolkit.
// @microsoft/mgt-react is used here for PeoplePicker component.
// Microsoft Graph Toolkit is a set of web components that simplify connecting to Microsoft Graph.
Providers.globalProvider = new SimpleProvider(async function (scopes) {
    var request = { scopes: scopes };

    try {
        let response = await msalInstance.acquireTokenSilent(request);
        return response.accessToken;
    } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
            msalInstance.acquireTokenRedirect(request);
        }
    }
});

// Handles authN automatically initiated by MsalProvider template.
msalInstance
    .handleRedirectPromise()
    .then((tokenResponse) => {
        if (tokenResponse !== null) {
            const accounts = msalInstance.getAllAccounts();
            if (accounts.length > 0) {
                msalInstance.setActiveAccount(accounts[0]);
                Providers.globalProvider.setState(ProviderState.SignedIn);
            }
        } else {
            const currentAccounts = msalInstance.getAllAccounts();
            if (!currentAccounts || currentAccounts.length < 1) {
                msalInstance.loginRedirect(loginRequest);
            } else if (currentAccounts.length >= 1) {
                msalInstance.setActiveAccount(currentAccounts[0]);
                Providers.globalProvider.setState(ProviderState.SignedIn);
            }
        }
    })
    .catch((err) => {
        // Handle error
        console.error(err);
    });

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <React.StrictMode>
        <MsalProvider instance={msalInstance}>
            <App />
        </MsalProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
