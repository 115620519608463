import { typeOfRequest, userRoles } from "../../utils/constants";
export const approvalQueues = {
  Accounting: {
    name: "Accounting",
    requestStatus: "Pending Accounting",
    forms: [
      {
        title: "Compensation Changes",
        name: "CompensationChange",
        url: "compensation-change"
      },
      {
        title: "Position Changes",
        name: "PositionChange",
        url: "position-change"
      },
      {
        title: "Transfers",
        name: "Transfer",
        url: "transfer"
      },
    ],
  },
  Finance: {
    name: "Finance",
    requestStatus: "Pending Finance",
    forms: [
      {
        title: "Compensation Changes",
        name: "CompensationChange",
        url: "compensation-change"
      },
      {
        title: "Position Changes",
        name: "PositionChange",
        url: "position-change"
      },
      {
        title: "Transfers",
        name: "Transfer",
        url: "transfer"
      },
    ],
  },
  [userRoles.hrBillable]: {
    name: "HR - Billable",
    parent: "Human Resources",
    requestStatus: "Pending HR",
    forms: [
      {
        title: "Terminations",
        name: "Termination",
        url: "termination",
      },
      {
        title: "Position Changes",
        name: "PositionChange",
        url: "position-change",
      },
      {
        title: "Compensation Changes",
        name: "CompensationChange",
        url: "compensation-change"
      },
      {
        title: "Transfers",
        name: "Transfer",
        url: "transfer"
      }
    ],
  },
  [userRoles.hrInternal]: {
    name: "HR - Internal",
    parent: "Human Resources",
    requestStatus: "Pending HR",
    forms: [
      {
        title: "Terminations",
        name: "Termination",
        url: "termination",
      },
    ],
  },
  [userRoles.commercialHR]: {
    name: "Commercial - HR",
    parent: "Human Resources",
    requestStatus: "Pending Commercial HR",
    forms: [
      {
        title: "Terminations",
        name: "Termination",
        url: "termination",
      },
      {
        title: "Compensation Changes",
        name: "CompensationChange",
        url: "compensation-change"
      },
      {
        title: "Position Changes",
        name: "PositionChange",
        url: "position-change",
      },
      {
        title: "Transfers",
        name: "Transfer",
        url: "transfer"
      }
    ]
  },
  [userRoles.esgBillable]: {
    name: "ESG-Billable",
    parent: "ESG",
    requestStatus: "Pending ESG",
    forms: [
      {
        title: "Terminations",
        name: "Termination",
        url: "termination",
      },
      {
        title: "Supervisor Changes",
        name: "SupervisorChange",
        url: "supervisor-change"
      },
      {
        title: "Compensation Changes",
        name: "CompensationChange",
        url: "compensation-change"
      },
      {
        title: "Position Changes",
        name: "PositionChange",
        url: "position-change"
      },
      {
        title: "Add Program Request",
        name: "AddProgram",
        url: "add-program",
      },
      {
        title: "Transfers",
        name: "Transfer",
        url: "transfer"
      },
      {
        title: "New Hires",
        name: "NewHire",
        url: "onboarding-queue"
      }
    ]
  },
  [userRoles.esgInternal]: {
    name: "ESG-Internal",
    parent: "ESG",
    requestStatus: "Pending ESG",
    forms: [
      {
        title: "Terminations",
        name: "Termination",
        url: "termination",
      },
      {
        title: "Supervisor Changes",
        name: "SupervisorChange",
        url: "supervisor-change"
      },
      {
        title: "Compensation Changes",
        name: "CompensationChange",
        url: "compensation-change"
      },
      {
        title: "Position Changes",
        name: "PositionChange",
        url: "position-change"
      },
      {
        title: "Transfers",
        name: "Transfer",
        url: "transfer"
      },
      {
        title: "New Hires",
        name: "NewHire",
        url: "onboarding-queue"
      }
    ]
  },

  [userRoles.payrollBillable]: {
    name: "Payroll - Billable",
    parent: "Payroll",
    requestStatus: "Pending Payroll",
    forms: [
      {
        title: "Terminations",
        name: "Termination",
        url: "termination",
      },
      {
        title: "Compensation Changes",
        name: "CompensationChange",
        url: "compensation-change"
      },
      {
        title: "Position Changes",
        name: "PositionChange",
        url: "position-change"
      },
      {
        title: "Transfers",
        name: "Transfer",
        url: "transfer"
      }
    ]
  },

  [userRoles.payrollCanada]: {
    name: "Payroll - Canada",
    parent: "Payroll",
    requestStatus: "Pending Payroll",
    forms: [
      {
        title: "Terminations",
        name: "Termination",
        url: "termination",
      },
      {
        title: "Supervisor Changes",
        name: "SupervisorChange",
        url: "supervisor-change"
      },
      {
        title: "Compensation Changes",
        name: "CompensationChange",
        url: "compensation-change"
      },
      {
        title: "Position Changes",
        name: "PositionChange",
        url: "position-change"
      },
      {
        title: "Add Program Request",
        name: "AddProgram",
        url: "add-program",
      },
      {
        title: "Transfers",
        name: "Transfer",
        url: "transfer"
      },
      {
        title: "New Hires",
        name: "NewHire",
        url: "onboarding-queue"
      },
    ],
  },
  [userRoles.payrollInternal]: {
    name: "Payroll - Internal",
    parent: "Payroll",
    requestStatus: "Pending Payroll",
    forms: [
      {
        title: "Terminations",
        name: "Termination",
        url: "termination",
      },
      {
        title: "Compensation Changes",
        name: "CompensationChange",
        url: "compensation-change"
      },
      {
        title: "Position Changes",
        name: "PositionChange",
        url: "position-change"
      },
      {
        title: "Transfers",
        name: "Transfer",
        url: "transfer"
      }
    ]
  },
  [typeOfRequest.active]: {
    name: "ESF 3.0 - Active",
  },
  [typeOfRequest.archive]: {
    name: "ESF 3.0 - Archive",
  },
};
