import React from "react";
import "./styles.css";
import { AiOutlineBarcode } from "react-icons/ai";
import { MdLocationOn, MdEmail, MdBusiness,  } from "react-icons/md";
import { FcDepartment } from "react-icons/fc";

const EmployeeDetails = ({ employee, onRemove }) => {
  return (
    <div className={`employee-details${onRemove ? " multiple" : ""}`}>
      <div className="details-row">
        <span className="blue">{employee.Name}</span>
        <span>{employee.JobDescription}</span>
        {employee.EmailPreferred && (
          <div>
            <MdEmail />
            <a
              className="blue"
              target="_blank"
              rel="noreferrer"
              href={`mailto:$employee.EmailPreferred}`}
            >
              {employee.EmailPreferred}
            </a>
          </div>
        )}
      </div>
      <div className="details-row">
        <div>
          <AiOutlineBarcode /> <span>{employee.EmployeeID}</span>
        </div>
        {employee.HomeState && (
          <div>
            <MdLocationOn /> <span>{employee.HomeState}</span>
          </div>
        )}
        {employee.CompanyID && (
          <div>
            <MdBusiness />
            <span>{employee.CompanyID}</span>
          </div>
        )}
        {employee.DepartmentDescription && (
          <div>
            <FcDepartment />
            <span>{employee.DepartmentDescription}</span>
          </div>
        )}
      </div>
      {onRemove && (
        <button className="selected-employee-remove-btn" onClick={onRemove}>
          x
        </button>
      )}
    </div>
  );
};


export default EmployeeDetails;