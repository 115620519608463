import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./styles.css";
import MenuIcon from "../../assets/images/menu.svg";
import { useMsal } from "@azure/msal-react";

const Nav = () => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const { instance } = useMsal();
  const account = instance.getActiveAccount();
  const logoutRequest = {
    account: instance.getAccountByHomeId(instance.getActiveAccount()),
  };
  useEffect(() => {
    const closeDropdown = (e) => {
      if (!e.target.closest(".dropdown") && !e.target.closest(".menu-icon")) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener("mousedown", closeDropdown);

    return () => {
      document.removeEventListener("mousedown", closeDropdown);
    };
  }, []);

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  return (
    <nav>
      <img
        src={MenuIcon}
        alt="menu"
        onClick={toggleDropdown}
        className="menu-icon"
      />
      {dropdownVisible && (
        <div className="dropdown">
          <span>{account?.name}</span>
          <Link to="/request-history" onClick={toggleDropdown}>
            Request History
          </Link>
          <Link onClick={() => {
            if (localStorage.getItem("UserAccess")) {
              localStorage.removeItem("UserAccess");
            }
            instance.logoutRedirect(logoutRequest)
          }}>
            Sign Out
          </Link>
        </div>
      )}
    </nav>
  );
};

export default Nav;
