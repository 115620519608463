import { getApi, postApi, postValuesApi } from "./fetchApi";

async function getJobTitle(productId = 1) {
  try {
    const tokenResponse = await postApi(
      process.env.REACT_APP_ESF_BEARERTOKEN,
      { ResourceID: process.env.REACT_APP_ESF_JOBCODE_RESOURCEID }
    );
    if (tokenResponse) {
      const bearerToken = "Bearer " + tokenResponse.access_token;
      const jobCodes = await getApi(
        process.env.REACT_APP_ESF_JOBCODES.replace(
          "{productId}",
          productId
        ),
        bearerToken
      );
      return jobCodes;
    } else {
      console.error("Token response is not valid");
    }
  } catch (error) {
    console.log(`Exception occured while fetching job title: ${error}`);
  }
}

async function getDepartments(companyId) {
  try {
    const tokenResponse = await postApi(process.env.REACT_APP_ESF_BEARERTOKEN, { ResourceID: process.env.REACT_APP_ESF_DEPT_RESOURCEID });
    if (tokenResponse) {
      const bearerToken = "Bearer " + tokenResponse.access_token;
      const departments = await getApi(process.env.REACT_APP_ESF_DEPTDETAILS.replace('{companyID}', companyId), bearerToken);
      return departments;
    } else {
      console.error("Token response is not valid");
    }
  } catch (error) {
    console.log(`Exception occured while fetching Departments: ${error}`);
  }
}

async function getReportees(employeeId) {
  let employeesAndSubordinates = [];
  try {
    const tokenResponse = await postValuesApi(process.env.REACT_APP_ESF_BEARERTOKEN, { ResourceID: process.env.REACT_APP_ESF_EMP_RESOURCEID });
    if (tokenResponse) {
      const bearerToken = "Bearer " + tokenResponse.access_token;
      const directReportee = await getApi(
        process.env.REACT_APP_ESF_DIRECT_REPORTEES.replace(
          "{supervisorId}",
          employeeId
        ),
        bearerToken
      );
      if (directReportee) {
        const promiseSubReportees = [];
        employeesAndSubordinates = directReportee?.employees?.map((item) => {
          return {
            Name: item.Name,
            JobDescription: item.JobDescription,
            HomeState: item.HomeState,
            EmployeeID: item.EmployeeID,
            EmployeeClass: item.EmployeeClass,
            CompanyID: item.CompanyID,
            EmailPreferred: item.EmailPreferred,
            HireDate: item.HireDate,
            SupervisorID: item.SupervisorID,
            isSupervisor: item.IsSupervisor,
            OfficeLocationID: item.OfficeLocationID,
            ProductID: item.ProductID,
            DepartmentDescription: item.DepartmentDescription,
            DepartmentID: item.DepartmentID,
          };
        });
        employeesAndSubordinates.forEach((employee) => {
          if (employee.isSupervisor === "Y") {
            const callsubReporteesUrl =
              process.env.REACT_APP_ESF_DIRECT_REPORTEES.replace(
                "{supervisorId}",
                employee.EmployeeID
              );
            promiseSubReportees.push(getApi(callsubReporteesUrl, bearerToken));
          }
        });
        if (promiseSubReportees.length > 0) {
          return Promise.all(promiseSubReportees).then((resSubReportees) => {
            resSubReportees.forEach((reportees) => {
              var employeeSubordinates = undefined;
              if (reportees) {

                employeeSubordinates = reportees.employees.map((reportee) => {
                  return {
                    Name: reportee.Name,
                    JobDescription: reportee.JobDescription,
                    HomeState: reportee.HomeState,
                    EmployeeID: reportee.EmployeeID,
                    EmployeeClass: reportee.EmployeeClass,
                    CompanyID: reportee.CompanyID,
                    EmailPreferred: reportee.EmailPreferred,
                    HireDate: reportee.HireDate,
                    requestStatus: reportee.requestStatus,
                    SupervisorID: reportee.SupervisorID,
                    isSupervisor: reportee.IsSupervisor,
                    ProductID: reportee.ProductID,
                    DepartmentDescription: reportee.DepartmentDescription,
                  };
                });
              }
              if (employeeSubordinates?.length > 0) {
                const filteredEmployee = employeesAndSubordinates.find(
                  (employeeToFilter) =>
                    employeeToFilter.EmployeeID ===
                    employeeSubordinates[0].SupervisorID
                );
                filteredEmployee.subordinates = employeeSubordinates;
              }
            });
            return employeesAndSubordinates;
          });
        }
        return employeesAndSubordinates;
      }
    } else {
      console.error("Token response is not valid");
    }
  } catch (error) {
    console.log(`Exception occured while fetching reportees: ${error}`);
  }
}

async function getSupervisor(employeeId) {
  try {
    const tokenResponse = await postValuesApi(process.env.REACT_APP_ESF_BEARERTOKEN, { ResourceID: process.env.REACT_APP_ESF_EMP_RESOURCEID });
    if (tokenResponse) {
      const bearerToken = "Bearer " + tokenResponse.access_token;
      const empInfo = await getApi(
        process.env.REACT_APP_ESF_EMPDETAILS.replace(
          "{employeeId}",
          employeeId
        ),
        bearerToken
      );
      if (empInfo) {
        const supervisorId = empInfo.employees[0].SupervisorID;
        const supervisorInfo = await getApi(
          process.env.REACT_APP_ESF_EMPDETAILS.replace(
            "{employeeId}",
            supervisorId
          ),
          bearerToken
        );
        return supervisorInfo?.employees[0];
      }
    } else {
      console.error("Token response is not valid");
    }
  } catch (error) {
    console.log(`Exception occured while fetching supervisor: ${error}`);
  }
}

async function getEmployee(employeeId) {
  try {
    const tokenResponse = await postValuesApi(process.env.REACT_APP_ESF_BEARERTOKEN, { ResourceID: process.env.REACT_APP_ESF_EMP_RESOURCEID });
    if (tokenResponse) {
      const bearerToken = "Bearer " + tokenResponse.access_token;
      const empInfo = await getApi(
        process.env.REACT_APP_ESF_EMPDETAILS.replace(
          "{employeeId}",
          employeeId
        ),
        bearerToken
      );
      return empInfo?.employees[0];
    } else {
      console.error("Token response is not valid");
    }
  } catch (error) {
    console.log(`Exception occured while fetching employee info: ${error}`);
  }
}

async function getEmployeesFromSearch(searchTerm, companyId, signal = null) {
  try {
    const tokenResponse = await postApi(process.env.REACT_APP_ESF_BEARERTOKEN, { ResourceID: process.env.REACT_APP_ESF_EMP_RESOURCEID }, signal)
    if (tokenResponse) {
      const bearerToken = "Bearer " + tokenResponse.access_token;
      let promiseMSZ = null;
      let promiseOthers = null;
      const callURL = process.env.REACT_APP_ESF_EMP_SEARCH.replace('{searchTerm}', searchTerm).replace('{companyID}', companyId);
      promiseOthers = getApi(callURL, bearerToken);
      /*
      if (companyId === 'MSU') {
        const callURLMSZ = process.env.REACT_APP_ESF_EMP_SEARCH.replace('{searchTerm}', searchTerm).replace('{companyID}', 'MSZ');
        promiseMSZ = getApi(callURLMSZ, bearerToken);
      }
      */
      return Promise.all([promiseOthers, promiseMSZ])
        .then(([resOthers, resMSZ]) => {
          if (resOthers) {
            let finalResponse = [...resOthers.employees];
            if (resMSZ) {
              finalResponse = [...finalResponse, ...resMSZ.employees];
            }
            return finalResponse;
          }
        })
        .catch((err) => {
          console.error(" MuleSoft Employee Search API Fetch aborted " + err);
        });
    }
    else {
      console.error("Token response is not valid");
    }
  } catch (error) {
    console.error(error);
  }
};

export { getJobTitle, getReportees, getSupervisor, getEmployee, getDepartments, getEmployeesFromSearch };
