import React, { useState, useEffect } from "react";
import "./styles.css";
import { useParams, useNavigate } from "react-router-dom";
import EsfInput from "../../components/EsfInput";
import { AiOutlineBarcode } from "react-icons/ai";
import { FaCalendarCheck, FaUserAlt } from "react-icons/fa";
import { postApi, postValuesApi } from "../../utils/fetchApi";
import { useMsal } from "@azure/msal-react";
import { bgColorsTitle, formRoutes, requestStatus } from "../../utils/constants";
import Swal from "sweetalert2";
const colors = {
  "Pending ESG": "orange",
};

const statuses = [
  { text: "No Filter", key: "no-filter" },
  { text: "Completed", key: "completed" },
  { text: "Cancelled", key: "cancelled" },
  { text: "Rejected to Submitted", key: "rejected-to-submitted" },
  { text: "Pending Existing Manager", key: "pending-existing-manager" },
  { text: "Pending HR", key: "pending-HR" },
  { text: "Pending ESG", key: "pending-ESG" },
];

const types = [
  { text: "No Filter", key: "no-filter" },
  { text: "Termination", key: "termination" },
  { text: "Supervisor Change", key: "supervisor-change" },
  { text: "Compensation Change", key: "compensation-change" },
  { text: "Position Change", key: "position-change" },
  { text: "Add Program", key: "add-program" },
  { text: "Transfer", key: "transfer" },
];

const RequestHistory = () => {
  const navigate = useNavigate();
  const { instance } = useMsal();
  const { status, type } = useParams();
  const [currentStatus, setCurrentStatus] = useState(null);
  const [currentType, setCurrentType] = useState(null);
  const [RequestHistory, setRequestHistory] = useState([]);

  const [FilterData, setFilterData] = useState({
    filterValues: [{ key: "No Filter", text: "No Filter" }],
  });
  const [filteredData, setFilteredData] = useState([]);
  const [user, setUser] = useState({
    userName: "",
    userEmail: "",
  });
  const [isCancelVisible, setIsCancelVisible] = useState(false);

  useEffect(() => {
    if (status) {
      setCurrentStatus(
        statuses.find((filteredStatus) => filteredStatus.key === status).text
      );
    } else {
      setCurrentStatus(statuses[0].text);
    }
    if (type) {
      const filteredType = types.find(
        (filteredType) => filteredType.key === type
      );
      if (filteredType) {
        setCurrentType(filteredType.text);
      }
    } else {
      setCurrentType(types[0].text);
    }
  }, []);

  useEffect(() => {
    console.log(currentStatus);
    filterRequests();
    cancelVisibility();
  }, [currentStatus]);

  useEffect(() => {
    console.log(currentType);
    filterRequests();
  }, [currentType]);

  useEffect(() => {
    filterRequests();
  }, [RequestHistory]);

  const filterRequests = () => {
    console.log(RequestHistory);
    if (
      (!currentStatus || currentStatus === "No Filter") &&
      (!currentType || currentType === "No Filter")
    ) {
      console.log("no filters");
      setFilteredData(RequestHistory);
      return;
    }
    if (!currentType || currentType === "No Filter") {
      const filteredRequests = RequestHistory.filter((request) => {
        return request.requestStatus === currentStatus;
      });
      console.log("filteredRequests 1", filteredRequests);
      setFilteredData(filteredRequests);
      return;
    }
    if (!currentStatus || currentStatus === "No Filter") {
      const filteredRequests = RequestHistory.filter((request) => {
        return request.formName === currentType;
      });
      console.log("filteredRequests 2", filteredRequests);
      setFilteredData(filteredRequests);
      return;
    }
    const filteredRequests = RequestHistory.filter((request) => {
      return (
        request.formName === currentType &&
        request.requestStatus === currentStatus
      );
    });

    setFilteredData(filteredRequests);
  };
  const handleStatusChange = (event) => {
    setCurrentStatus(event.target.value);
  };
  const handleTypeChange = (event) => {
    setCurrentType(event.target.value);
  };

  const getRequestHistory = async (userEmail) => {
    const callUrl = process.env.REACT_APP_ESF_ACTIVE_REQUEST;
    const requestDetails = await postApi(callUrl, {
      Method: "READ",
      activeUserDetail: "DetailPage",
      currentUserEmail: userEmail,
    });

    setRequestHistory(requestDetails.ResultSets.Table1);
  };

  useEffect(() => {
    getDropdownOptions();
    const account = instance.getActiveAccount();
    if (account) {
      setUser({ ...user, userName: account.name, userEmail: account.username });
      getRequestHistory(account.username);

    }
  }, []);

  const CancelRequest = async (requestData) => {
    const reqId = requestData.requestId;
    const formName = requestData.formName;
    if (reqId != null && reqId != "") {
      const selectedRequest = filteredData.filter(
        (item) => item.requestId === reqId
      );
      selectedRequest[0].requestStatus = "Cancelled";

      setFilteredData([...filteredData]);

      let callUrl = process.env.REACT_APP_ESF_TERMINATION;
      let body = {}

      switch (formName) {
        case 'Add Program':
          callUrl = process.env.REACT_APP_ESF_ADDPROGRAM;
          body.AddProgramData = requestData;
          break;
        case 'Compensation Change':
          callUrl = process.env.REACT_APP_ESF_COMPENSATIONCHANGE;
          body.CompensationChangeData = requestData;
          break;
        case 'Transfer':
          callUrl = process.env.REACT_APP_ESF_TRASFER;
          body.TransferData = requestData;
          break;
        case 'Position Change':
          callUrl = process.env.REACT_APP_ESF_POSITIONCHANGE;
          body.PositionChangeData = requestData;
          break;
        case 'Supervisor Change':
          callUrl = process.env.REACT_APP_ESF_SUPERVISORCHANGE;
          body.SupervisorChangeData = requestData;
          break;
        default:
          body.TerminationData = requestData;
          break;
      }
      body.Method = "CANCEL";
      body.requestID = reqId ? reqId : "";
      const response = await fetch(callUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      const data = await response.json();
    }
  };

  const cancelPopUp = (request) => {
    Swal.fire({
      html: `Are you sure you want to cancel ${request.formName} Request ${request.requestId}?`,
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: "No"
    }).then((result) => {
      if (result.isConfirmed) {
        CancelRequest(request);
      }
    });
  };

  const getDropdownOptions = async () => {
    postValuesApi(
      process.env.REACT_APP_ESF_GETCHOICEVALUES,
      {
        RelatedProcess: "Transfer",
      }
    ).then((result) => {
      const filters = result.ResultSets.Table5?.map((val) => {
        return { key: val.requestStatus, text: val.requestStatus };
      });

      setFilterData({
        ...FilterData,

        filterValues: [...FilterData.filterValues, ...filters],
      });
    });
  };

  const cancelVisibility = async () => {
    const userAccess = JSON.parse(localStorage.getItem("UserAccess"));
    let roles = [];
    roles = userAccess.roles;
    setIsCancelVisible(false);
    //if (status !== requestStatus.cancelled && status !== requestStatus.complete) {
    const rolesAllowed = process.env.REACT_APP_CANCELLATION_ROLES_ALLOWED;
    if (roles.some(role => rolesAllowed.includes(role.name))) {
      setIsCancelVisible(true);
    }
    //}
  }

  return (
    <main className="requestHistory">
      <h3>Request History</h3>
      <div className="filters">
        <EsfInput
          type="select"
          name="status"
          label="Status"
          options={FilterData.filterValues}
          value={currentStatus}
          onChange={handleStatusChange}
        />
        <EsfInput
          type="select"
          name="type"
          label="Type"
          options={types}
          value={currentType}
          onChange={handleTypeChange}
        />
      </div>
      <div className="requests">
        <ul>
          {filteredData &&
            filteredData.map((request) => (
              <li className="request" key={request.requestId}>
                <div className="requestInfo">
                  <div>
                    <span className="bold">{<a href={request.routing} target="_blank">{request.requestId}</a>}</span>
                    <span style={{ color: colors[request.requestStatus] }}>
                      {request.requestStatus}
                    </span>
                  </div>

                  <div>
                    <span>
                      <FaUserAlt /> {request.submitterName}
                    </span>
                    <span>
                      <AiOutlineBarcode /> {request.empId}
                    </span>
                  </div>
                  <div>
                    <span>{request.formName}</span>
                    <span>
                      <FaCalendarCheck />
                      {new Date(request.dateSubmitted).toLocaleString()}
                    </span>
                  </div>
                </div>
                {(request.requestStatus !== "Cancelled" && request.requestStatus !== "Complete" && isCancelVisible) && (
                  <div className="requestActions">
                    <span
                      className="closeButton"
                      onClick={() => {
                        cancelPopUp(request);
                      }}
                    >
                      x
                    </span>
                  </div>
                )}
              </li>
            ))}
        </ul>
      </div>
    </main>
  );
};

export default RequestHistory;
