import React from "react";
import { Route, Routes } from "react-router-dom";
import Layout from "./components/Layout";
import AddProgram from "./containers/AddProgram";
import CompensationChange from "./containers/CompensationChange";
import PositionChange from "./containers/PositionChange";
import SupervisorChange from "./containers/SupervisorChange";
import Termination from "./containers/Termination";
import Transfer from "./containers/Transfer";
import NotFound from "./components/404";
import LandingPage from "./containers/LandingPage";
import SubmittedDetails from "./containers/SubmittedDetails";
import ApprovalQueue from "./containers/ApprovalQueue";
import { formNotes, formTitles } from "./utils/constants";
import AppovalQueueLayout from "./components/ApprovalQueueLayout";
import RequestHistory from "./containers/RequestHistory";
import OnboardingQueue from "./containers/OnboardingQueue";
import SearchForms from "./containers/SearchForms";
//import SuccessError from "./containers/CompensationChange/SuccessError";

const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="/" element={<LandingPage />} />
        <Route
          path="/add-program"
          element={
            <AddProgram
              title={formTitles.addProgram}
              note={formNotes.addProgram}
            />
          }
        />
        <Route
          path="/compensation-change"
          element={
            <CompensationChange
              title={formTitles.compesnation}
              note={formNotes.compesnation}
            />
          }
        />
        <Route
          path="/compensation-change/:requestId"
          element={
            <CompensationChange
              title={formTitles.compesnation}
              note={formNotes.compesnation}
            />
          }
        />
        <Route
          path="/position-change"
          element={
            <PositionChange
              title={formTitles.position}
              note={formNotes.position}
            />
          }
        />
        <Route
          path="/position-change/:requestId"
          element={
            <PositionChange
              title={formTitles.position}
              note={formNotes.position}
            />
          }
        />
        <Route
          path="/supervisor-change"
          element={
            <SupervisorChange
              title={formTitles.supervisor}
              note={formNotes.supervisor}
            />
          }
        />
        <Route
          path="/supervisor-change/:requestId"
          element={
            <SupervisorChange
              title={formTitles.supervisor}
              note={formNotes.supervisor}
            />
          }
        />
        <Route
          path="/termination"
          element={
            <Termination
              title={formTitles.termination}
              note={formNotes.termination}
            />
          }
        />
        <Route
          path="/termination/:requestId"
          element={
            <Termination
              title={formTitles.termination}
              note={formNotes.termination}
            />
          }
        />
        <Route
          path="/add-program/:requestId"
          element={
            <AddProgram
              title={formTitles.addProgram}
              note={formNotes.addProgram}
            />
          }
        />
        <Route
          path="/transfer"
          element={
            <Transfer title={formTitles.transfer} note={formNotes.transfer} />
          }
        />
        <Route
          path="/transfer/:requestId"
          element={
            <Transfer title={formTitles.transfer} note={formNotes.transfer} />
          }
        />
        <Route
          path="/request-history/:status?/:type?"
          element={<RequestHistory />}
        />
        <Route
          path="/onboarding-queue/:candidateId"
          element={
            <OnboardingQueue />
          }
        />
        <Route path="/:form/view/:requestId" element={<SubmittedDetails />} />
      </Route>
      <Route path="/approval-queue" element={<AppovalQueueLayout />}>
        <Route
          path="/approval-queue/:userRole/:form?"
          element={<ApprovalQueue />}
        />
        <Route
          path="/approval-queue/search-forms/:requestType"
          element={<SearchForms />}
        />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default Router;
