import React, { Component } from "react";
import Title from "../../components/Title";
import Note from "../../components/Note";
import EmployeeSearch from "../../components/EmployeeSearch";
import EsfInput from "../../components/EsfInput";
import SubmitButton from "../../components/SubmitButton";
import { getApi, getTemporaryEmpId, postApi, getImpersonatedUserRoles } from "../../utils/fetchApi";
import moment from "moment";
import Swal from "sweetalert2";
import { Navigate } from "react-router-dom";
import Approval from "../CompensationChange/approval";
import Grid from "../../components/Grid";
import { userRoles, employeeSearchText, workflowHistory, employeeCompanyLocations, requestStatus } from "../../utils/constants";
/*Auth*/
import { MsalContext } from "@azure/msal-react";
import { appInsights } from "../../components/AppInsights";
import { cancelRequest, getFormattedDate, redirectUrl } from "../../utils/globalUtils";
import { getEmployeesFromSearch } from "../../utils/employeeService";
//import { PublicClientApplication } from "@azure/msal-browser";
//import { msalConfig, loginRequest, masterTokenRequest } from "./utils/authConfig";
// This should be the same instance you pass to MsalProvider
//const msalInstance = new PublicClientApplication(msalConfig);
import LoginAs from "../LoginAs";

class SupervisorChange extends Component {
  static contextType = MsalContext;

  constructor(props) {
    super(props);

    this.state = {
      requestId: undefined,
      isEmployeeSelected: false,
      Employees: [],
      EmployeesNewSupervisors: [],
      goToView: false,
      searchQuery: "",
      isEmployeeLoading: false,
      validationErros: "",
      isUpdateFormValid: false,
      isSubmitFormValid: false,

      /*Form Values*/
      SelectedEmployee: null,
      SelectedSuperVisor: null,
      SelectedSuperVisorCompanyID: null,
      SelectedSuperVisorCountry: null,
      EffectiveDate: null,
      PhoneNo: "",
      submitterEmail: "",
      AdditionalComments: "",
      /*Approval States*/
      requestStatus: null,

      dateApprovedByESG: null,
      esgApproval: null,
      esgAssignedTo: null,
      esgComments: null,
      esgAssignedToName: null,
      esgAssignedToEmail: null,
      esgAssignedToEmailDisplay: null,

      payrollApproval: null,
      payrollAssignedToName: null,
      payrollAssignedToEmail: null,
      payrollAssignedToEmailDisplay: null,
      payrollComments: null,
      /*Approval workflow*/
      gridRows: [],
      gridColumns: [],

      roles: '',
      isCancelVisible: false,
      isNavigateToApprovalQueue: false,
      loggedInAs: { EmpId: '', DisplayName: '', Email: '' }

    };
    this.RequestStatus = {
      PendingESG: "Pending ESG",
      PendingPayroll: "Pending Payroll",
      CompleteESG: "Complete",
      CompletePayRoll: "Complete",
    };
    this.LoggedInUser = {}
    this.roles = {}
    this.userRoles = userRoles;
    this.EmployeeCompanyLocations = employeeCompanyLocations;

  }

  componentDidMount = () => {
    const start = new Date().getTime();
    const autAccount = this.context.accounts[0];
    if (autAccount && autAccount.name)
      this.LoggedInUser = { DisplayName: autAccount.name, UserName: autAccount.username, IsSubmitter: false, IsInAnyRole: false, IsAdminRole: false }
    try {
      let roles = [];
      const userAccess = JSON.parse(localStorage.getItem("UserAccess"));
      if (userAccess) {
        var isESFOwner = false;
        if (userAccess.roles.some(role => (role.name === this.userRoles.esfOwners))) { isESFOwner = true; }
        this.setState({ roles: userAccess.roles, isESFOwner: isESFOwner });
        this.roles = userAccess.roles;
        console.log("My Roles", this.roles);
        if (userAccess.employeeID && userAccess.employeeID.length > 0 && userAccess.employeeID[0].employeeId) {
          this.LoggedInUser.EmployeeID = userAccess.employeeID[0].employeeId;
        }
      }
    } catch (err) {
      console.log(err);
    }

    this.getBarerToken().then((token) => {
      var empID = this.LoggedInUser.EmployeeID;//? this.LoggedInUser.EmployeeID : "02507999" //For Production "02507999"
      //empID = "05378281";//Non MSC "03806371", MSC "05378281"// For Dev Only remove for prod
      if (getTemporaryEmpId(this.LoggedInUser.UserName)) {
        empID = getTemporaryEmpId(this.LoggedInUser.UserName);
      }
      //empID = "03806371";
      /* Login as other user*/
      if (this.state.loggedInAs && this.state.loggedInAs.EmpId) {
        empID = this.state.loggedInAs.EmpId;
      }
      this.getSubordinates(empID, token).then((empArr) => {
        empArr.forEach(emp => {
          if (emp.isSupervisor === "Y") {
            this.getSubordinates(emp.EmployeeID, token).then((empArr) => {
              emp.subordinates = empArr;
            })
          }
        });
        this.setState({ Employees: empArr });
      });

      if (window.location.href.indexOf('/supervisor-change/') > 0) {
        var reqid = window.location.href.toString().substring(window.location.href.lastIndexOf('/supervisor-change/') + '/supervisor-change/'.length);
        if (reqid) {


          this.getRequestByRequestId(token, reqid).then(() => {
            // if (this.LoggedInUser.IsSubmitter || this.LoggedInUser.IsInAnyRole || this.LoggedInUser.IsAdminRole) {
            //   this.getWorkflowHistory(reqid);
            // }
          });


        }
      }
    })

    if (appInsights) {
      appInsights.trackEvent({
        name: `Page Load Supervisor Change`,
        properties: {
          TimeTaken: `${(new Date().getTime() - start) / 1000} s`,
        },
      });
    }

  };

  /*API calls*/
  getBarerToken = async () => {
    const response = await postApi(process.env.REACT_APP_ESF_BEARERTOKEN, { ResourceID: process.env.REACT_APP_ESF_EMP_RESOURCEID });
    return "Bearer " + response.access_token;

  }

  validateUserAccess = (reqJsonValues) => {
    var IsSubmitter = false, IsInAnyRole = false, IsAdminRole = false;
    /* Is Logged in User is Submitter */
    if (this.LoggedInUser.UserName.trim().toLowerCase() === reqJsonValues.submitterEmail.trim().toLowerCase()) {
      IsSubmitter = true;
    }
    /* Is user is in any of the approval role*/
    if (this.roles.some(role => (role.name === this.userRoles.esgInternal)) || this.roles.some(role => (role.name === this.userRoles.esgBillable)) ||
      this.roles.some(role => (role.name === this.userRoles.payrollCanada))
    ) {
      IsInAnyRole = true;
    }
    if (this.roles.some(role => (role.name === this.userRoles.esfOwners)) || this.roles.some(role => (role.name === this.userRoles.adminInternal))
      || this.roles.some(role => (role.name === this.userRoles.adminBillable))) {
      IsAdminRole = true;
    }
    this.LoggedInUser.IsSubmitter = IsSubmitter;
    this.LoggedInUser.IsInAnyRole = IsInAnyRole;
    this.LoggedInUser.IsAdminRole = IsAdminRole;
    console.log("LoggedInUser", this.LoggedInUser);
  }

  getSubordinates = async (employeeID, BearerToken) => {
    //00201436
    //var callURL = `https://allegismulesoft.allegisgroup.com/allegis-prod-employeesearch/v7/employees/search?SupervisorID=${employeeID}&EmployeeHRStatus=A&PersonOrganizationRelationship=EMP&Limit=350`;
    let employeeArr = [];
    try {
      var callURL = process.env.REACT_APP_ESF_DIRECT_REPORTEES.replace("{supervisorId}", employeeID);
      var response = await fetch(callURL, {
        method: "GET",
        crossDomain: true,
        headers: {
          authorization: BearerToken,
          accept: "application/json",
          "cache-control": "no-cache",
        },
      });

      var empsJson = await response.json();
      empsJson.employees.map((item) => {
        let obj = {
          empID: item.EmployeeID,
          EmployeeID: item.EmployeeID, // For Employee Searcg Drop down that was created later and needs different values
          businessTitle: item.JobDescription,
          JobDescription: item.JobDescription, // For Employee Searcg Drop down that was created later and needs different values
          //empBusinessUnit: item.DepartmentID, //It's not available in Response??
          empClass: item.EmployeeClass,//"TST",//
          EmployeeClass: item.EmployeeClass,//For Employee Control //"TST",//
          empBusinessUnit: item.ProductID, //83,//item.ProductID, //item.ProductID,//
          CompanyID: item.CompanyID,//"MSC",//
          EmailPreferred: item.EmailPreferred,
          HireDate: item.HireDate,
          empId: item.EmployeeID,
          HomeState: item.HomeState,
          DepartmentDescription: item.DepartmentDescription,
          Name: item.Name,
          empPosition: "", //Not found
          empProgram: `${item.DepartmentDescription} - ${item.DepartmentID}`, //Not found
          empSupervisor: item.SupervisorID,

          SupervisorID: item.SupervisorID,
          isSupervisor: item.IsSupervisor,
          subordinates: null
        };
        employeeArr.push(obj);

      });
    } catch (err) {
      console.log("getSubordinates - No  subordinates found", err);
    }

    return employeeArr;

  }
  /*
    getNewSupervisors = (searchTerm, companyID) => {
      try {
        this.setState({ isEmployeeLoading: true });
        const controller = new AbortController();
        const { signal } = controller;
        //previousController.current = controller;
        postApi(process.env.REACT_APP_ESF_BEARERTOKEN, { ResourceID: process.env.REACT_APP_ESF_EMP_RESOURCEID }, signal)
          .then((tokenResponse) => {
            if (tokenResponse) {
              const bearerToken = "Bearer " + tokenResponse.access_token;
              const callURL = process.env.REACT_APP_ESF_EMP_SEARCH.replace('{searchTerm}', searchTerm).replace('{companyID}', companyID);
              //const callURL = `https://allegismulesoft.allegisgroup.com/allegis-prod-employeesearch/v7/employees/search?LastName={searchTerm}&EmployeeHRStatus=A&PersonOrganizationRelationship=EMP&Limit=350`.replace('{searchTerm}', searchTerm);
              getApi(callURL, bearerToken)
                .then((empResponse) => {
                  console.log(empResponse);
                  this.setState({ isEmployeeLoading: false });
                  this.setState({ EmployeesNewSupervisors: empResponse.employees });
                })
                .catch((err) => {
                  this.setState({ isEmployeeLoading: false });
                });
            }
            else {
              this.setState({ isEmployeeLoading: false });
              console.log("Token response is not valid");
              Swal.fire({
                title: "Invalid token, try relogin to the portal",
                icon: "error",
              });
            }
          });
      } catch (error) {
        this.setState({ isEmployeeLoading: false });
        console.log(error);
        Swal.fire({
          title: "Something went wrong, please try after sometime",
          icon: "error",
        });
      }
    };*/

  onClickCancel = async () => {
    const effectiveDateValue = this.state.EffectiveDate?.replace('Z', '');
    const dateSubmittedValue = this.state.dateSubmitted?.replace('Z', '');
    const request = {
      requestId: this.state.requestId,
      requestStatus: this.state.requestStatus,
      dateSubmitted: dateSubmittedValue ? moment(dateSubmittedValue).format(
        "MM/DD/YY hh:mm A"
      ) : "",
      effectiveDate: effectiveDateValue ? moment(effectiveDateValue).format(
        "MM/DD/YY"
      ) : "",
      submitterName: this.state.submitterName,
      submitterEmail: this.state.submitterEmail,
      empId: this.state.SelectedEmployee?.[0]?.EmployeeID,
      empName: this.state.SelectedEmployee?.[0]?.Name,
      empPosition: this.state.SelectedEmployee?.[0]?.JobDescription,
      empSupervisor: this.state.SelectedEmployee?.[0]?.SupervisorID,
      empProgram: this.state.SelectedEmployee?.[0]?.empProgram,
      formName: 'Supervisor Change'
    };
    const response = await cancelRequest(request);
    if (response) {
      this.setState({ isNavigateToApprovalQueue: true });
    }
  }

  cancelVisibility = async (roles, status) => {
    this.setState({ isCancelVisible: false });
    if (status !== requestStatus.cancelled && status !== requestStatus.complete) {
      const rolesAllowed = process.env.REACT_APP_CANCELLATION_ROLES_ALLOWED;
      if (roles.some(role => rolesAllowed.includes(role.name))) {
        this.setState({ isCancelVisible: true });
      }
    }
  }

  getWorkflowHistory = async (requestId) => {

    try {
      if (requestId) {
        let reqResponse = await fetch(process.env.REACT_APP_ESF_WORKFLOW_HISTORY, {
          method: "POST",
          headers:
          {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(
            {
              Method: "READ",
              requestID: requestId,
              formName: "SupervisorChange"
            }
          ),
        });
        let reqResponseJson = await reqResponse.json();
        console.log(reqResponseJson);
        const historyResult = reqResponseJson.ResultSets.Table1;
        if (historyResult && historyResult.length > 0) {
          const headers = Object.keys(historyResult[0]).map((col) => {
            return { field: col, filter: true, floatingFilter: true, sortable: true }
          });
          this.setState({ gridRows: historyResult, gridColumns: headers });
        }

      }
    }
    catch (err) {
      console.log(err)
    };

  }

  /*DB Calls*/
  handleSubmitToSave = async (emp) => {
    const start = new Date().getTime();
    //var temp = this.isValidForm();
    //alert(temp);
    if (this.isValidForm()) {

      var requestId = this.state.SelectedSuperVisor
        ? Math.floor(Math.random() * 1677721555).toString(16)
        : "";
      var reqIDcounter = -1;
      this.state.SelectedEmployee.forEach(async emp => {
        reqIDcounter = parseInt(reqIDcounter) + parseInt(1);
        let supChangeData = {
          Created: getFormattedDate(new Date()),//new Date(),
          dateSubmitted: getFormattedDate(new Date()),//moment(new Date()).format("M/D/YYYY"),
          effectiveDate: this.state.EffectiveDate,
          empBusinessUnit: emp.empBusinessUnit,
          empClass: emp.EmployeeClass,
          empCompany: emp.CompanyID,
          empEmail: emp.EmailPreferred,
          empHireDate: emp.HireDate,
          empId: emp.EmployeeID,
          empLocation: emp.HomeState,
          empName: emp.Name,
          empPosition: emp.JobDescription,
          empProgram: emp.empProgram,
          empState: emp.HomeState,
          empSupervisor: emp.SupervisorID,
          newSupervisorId: this.state.SelectedSuperVisor.EmployeeID,
          newSupervisorName: this.state.SelectedSuperVisor.Name,

          //actionReason: this.state.AdditionalComments,
          actionComments: this.state.AdditionalComments,
          submitterEmail: this.LoggedInUser.UserName,
          submitterName: this.LoggedInUser.DisplayName,
          submitterPhone: this.state.PhoneNo,
          CreatedBy: this.LoggedInUser.DisplayName,
          ModifiedBy: this.LoggedInUser.DisplayName,
          Title: emp.JobDescription,

          requestId: requestId + "-" + reqIDcounter.toString(),
          /*Login AS*/
          loginAsEmpId: this.state.loggedInAs.EmpId,
          loggedInAsDisplayName: this.state.loggedInAs.DisplayName,
          loggedInAsEmail: this.state.loggedInAs.Email
        };

        /* if Company code MSC got o Payroll otherwise ESG*/
        if (supChangeData.empCompany && supChangeData.empCompany.trim().toLowerCase() === "msc") {
          supChangeData.payrollApproval = this.RequestStatus.PendingPayroll;
          supChangeData.requestStatus = this.RequestStatus.PendingPayroll;

        }
        else {
          supChangeData.esgApproval = this.RequestStatus.PendingESG;
          supChangeData.requestStatus = this.RequestStatus.PendingESG;
        }
        await this.createSupervisorChangeRepord(supChangeData);
      });

      this.setState({
        requestId: requestId + "-" + reqIDcounter.toString()
      });
      document.getElementById("lblError").innerHTML = "";

      if (appInsights) {
        appInsights.trackEvent({
          name: `Page Submit Supervisor Change`,
          properties: {
            TimeTaken: `${(new Date().getTime() - start) / 1000} s`,
          },
        });
      }


    }

  }

  createSupervisorChangeRepord = async (supChangeData) => {


    try {
      var SaveURL = process.env.REACT_APP_ESF_SUPERVISORCHANGE;
      let supCreationResponse = await fetch(SaveURL, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          Method: "CREATE",
          SupervisorChangeData: supChangeData,
          requestId: supChangeData.requestId,
        }),
      });
      let creationStaus = await supCreationResponse.json();
      console.log(creationStaus);
      if (creationStaus.ResultSets?.Table1?.[0]?.CREATEspRESULT) {
        Swal.fire({
          title: "Something went wrong...",
          html: creationStaus.ResultSets?.Table1?.[0]?.CREATEspRESULT,
          icon: "error",
        });
        console.error(creationStaus.error);
      } else {
        if (!Object.keys(creationStaus).includes('error')) {
          Swal
            .fire({
              title: "Submitted Successfully",
              text: "Your request has been submitted and is pending for applicable approvals",
              icon: "success",
              html: `<a href="/" >Go to Home</a>`,
              confirmButtonText: "View Details",
            })
            .then(async (result) => {
              if (result.isConfirmed) {
                this.setState({
                  goToView: true

                });
              }
            });
        }

      }

    } catch (err) {
      console.log(err);
      document.getElementById("lblError").innerHTML = "Technical issue, please try after sometime or contact administrator";
      this.setState({
        requestId: 0,
      });
    }


  }

  getRequestByRequestId = async (token, requestid) => {

    try {
      let reqResponse = await fetch(process.env.REACT_APP_ESF_SUPERVISORCHANGE, {
        method: "POST",
        headers:
        {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(
          {
            Method: "READ",
            requestID: requestid,
          }
        ),
      });
      let reqResponseJson = await reqResponse.json();

      let reqJsonValues = reqResponseJson.ResultSets.Table1[0];
      console.log(reqJsonValues);
      //
      var newSuperVisor = await this.getEmployeeByEmployeeID(reqJsonValues.newSupervisorId, token);

      /* new super visor data is just supervisor id so need to retrive it's details*/

      /* */
      var selEmp = [];
      if (reqJsonValues && Object.keys(reqJsonValues).length > 0) {
        this.getEmployeeByEmployeeID(reqJsonValues.empId, token)
          .then((tempEmpRec) => {
            selEmp.push(tempEmpRec);
            console.log("Selected User", tempEmpRec);
          }).then(() => {

            this.validateUserAccess(reqJsonValues);
            if (this.LoggedInUser.IsSubmitter || this.LoggedInUser.IsInAnyRole || this.LoggedInUser.IsAdminRole) {
              this.cancelVisibility(this.state.roles, reqJsonValues.requestStatus);
              this.setState({
                requestId: requestid,
                validationErros: "",

                /*Form Values*/
                SelectedEmployee: selEmp,
                SelectedSuperVisor: newSuperVisor,
                EffectiveDate: reqJsonValues.effectiveDate,
                PhoneNo: reqJsonValues.submitterPhone,
                submitterName: reqJsonValues.submitterName,
                submitterEmail: reqJsonValues.submitterEmail,
                dateSubmitted: reqJsonValues.dateSubmitted,
                AdditionalComments: reqJsonValues.actionComments,
                SelectedSuperVisorCompanyID: newSuperVisor.CompanyID,
                SelectedSuperVisorCountry: this.EmployeeCompanyLocations.filter(item => item.key === newSuperVisor.CompanyID)?.[0]?.text,
                /*Approval States*/
                requestStatus: reqJsonValues.requestStatus,
                dateApprovedByESG: reqJsonValues.dateApprovedByESG,
                esgApproval: reqJsonValues.esgApproval,
                esgAssignedTo: reqJsonValues.esgAssignedTo,
                esgComments: reqJsonValues.esgComments,
                esgAssignedToName: reqJsonValues.esgAssignedToName,
                esgAssignedToEmail: reqJsonValues.esgAssignedToEmail,
                esgAssignedToEmailDisplay: reqJsonValues.esgAssignedToEmail,

                payrollApproval: reqJsonValues.payrollApproval,
                payrollAssignedToName: reqJsonValues.payrollAssignedToName,
                payrollAssignedToEmail: reqJsonValues.payrollAssignedToEmail,
                payrollAssignedToEmailDisplay: reqJsonValues.payrollAssignedToEmail,
                payrollComments: reqJsonValues.payrollComments,
                //payrollComments: null, // field missing, needs to add in DB

                /*Approval workflow*/
                workflowHistory: []
                /*Loggin In As information*/
                , loggedInAs: {
                  EmpId: reqJsonValues.loginAsEmpId ?? undefined, DisplayName: reqJsonValues.loggedInAsDisplayName ?? undefined,
                  Email: reqJsonValues.loggedInAsEmail ?? []
                }
              }, this.getFormValidationStatus());
            }
            this.getWorkflowHistory(requestid);
          });
      }


      console.log("Wait here");
    }
    catch (err) {
      console.log(err);
    }



  }

  handleSubmitToUpdate = async () => {
    const start = new Date().getTime();
    if (this.isValidForm() && this.state.isUpdateFormValid) {

      var requestId = this.state.requestId;
      var esgApprovalDate = null, payrollApprovalDate = null, requestStatus = null, completedDate = null;
      if (this.state.requestStatus === this.RequestStatus.PendingESG && this.state.esgApproval === "Approve & Complete") {
        esgApprovalDate = getFormattedDate(new Date());//new Date();
        requestStatus = this.RequestStatus.CompleteESG;
        completedDate = getFormattedDate(new Date());//new Date();
      }
      if (this.state.requestStatus === this.RequestStatus.PendingPayroll && this.state.payrollApproval === "Complete") {
        payrollApprovalDate = getFormattedDate(new Date());//new Date();
        requestStatus = this.RequestStatus.CompletePayRoll;
        completedDate = getFormattedDate(new Date());//new Date();
      }
      if (requestStatus === null || requestStatus === undefined || requestStatus === "")
        requestStatus = this.state.requestStatus;

      this.state.SelectedEmployee.forEach(async emp => {
        let supChangeData = {
          effectiveDate: this.state.EffectiveDate,
          empBusinessUnit: emp.empBusinessUnit,
          empClass: emp.EmployeeClass,
          empCompany: emp.CompanyID,
          empEmail: emp.EmailPreferred,
          empHireDate: emp.HireDate,
          empId: emp.EmployeeID,
          empLocation: emp.HomeState,
          empName: emp.Name,
          empPosition: emp.JobDescription,
          empProgram: emp.empProgram,
          empState: emp.HomeState,
          empSupervisor: emp.empSupervisor,
          newSupervisorId: this.state.SelectedSuperVisor.empID,
          newSupervisorName: this.state.SelectedSuperVisor.Name,

          actionReason: this.state.AdditionalComments,
          actionComments: this.state.AdditionalComments,
          submitterPhone: this.state.PhoneNo,
          submitterName: this.state.submitterName,
          submitterEmail: this.state.submitterEmail,
          dateSubmitted: this.state.dateSubmitted,
          Title: this.state.SelectedEmployee.JobDescription,
          /* Approval Status*/

          dateApprovedByESG: esgApprovalDate,
          esgApproval: this.state.esgApproval,
          esgAssignedTo: this.state.esgAssignedToName,//this.state.esgAssignedTo,
          esgComments: this.state.esgComments,
          esgAssignedToName: this.state.esgAssignedToName,
          esgAssignedToEmail: this.state.esgAssignedToEmail,
          dateApprovedByESG: esgApprovalDate,

          payrollApproval: this.state.payrollApproval,
          payrollAssignedToName: this.state.payrollAssignedToName,
          payrollAssignedToEmail: this.state.payrollAssignedToEmail,
          payrollComments: this.state.payrollComments,

          requestStatus: requestStatus,

          completedDate: completedDate,

          requestId: requestId,
          Modified: getFormattedDate(new Date()),//new Date(),
          ModifiedBy: this.LoggedInUser.DisplayName
        };
        await this.updateSupervisorChangeRepord(supChangeData);
        console.log(supChangeData);
      });

      document.getElementById("lblError").innerHTML = "";

      if (appInsights) {
        appInsights.trackEvent({
          name: `Page Update Supervisor Change`,
          properties: {
            TimeTaken: `${(new Date().getTime() - start) / 1000} s`,
          },
        });
      }



      // Swal
      //   .fire({
      //     title: "Submitted Successfully",
      //     text: "Your request has been submitted and is pending for applicable approvals",
      //     icon: "success",
      //     html: `<a href="${redirectUrl(this.state.roles)}" target="_blank">Go to Home</a>`,
      //     confirmButtonText: "View Details",
      //   })
      //   .then(async (result) => {
      //     if (result.isConfirmed) {
      //       this.setState({
      //         goToView: true

      //       });
      //     }
      //   });
      this.setState({
        requestId: requestId,
      });

    }
  }

  updateSupervisorChangeRepord = async (supChangeData) => {

    try {
      var SaveURL = process.env.REACT_APP_ESF_SUPERVISORCHANGE;
      let supCreationResponse = await fetch(SaveURL, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          Method: "UPDATE",
          SupervisorChangeData: supChangeData,
          requestId: supChangeData.requestId,
        }),
      });

      let creationStaus = await supCreationResponse.json();
      console.log(creationStaus);
      if (creationStaus.ResultSets?.Table1?.[0]?.CREATEspRESULT) {
        Swal.fire({
          title: "Something went wrong...",
          html: creationStaus.ResultSets?.Table1?.[0]?.CREATEspRESULT,
          icon: "error",
        });
        console.error(creationStaus.error);
      } else {
        if (!Object.keys(creationStaus).includes('error')) {
          Swal
            .fire({
              title: "Submitted Successfully",
              text: "Your request has been submitted and is pending for applicable approvals",
              icon: "success",
              html: `<a href="/" >Go to Home</a>`,
              confirmButtonText: "View Details",
            })
            .then(async (result) => {
              if (result.isConfirmed) {
                this.setState({
                  goToView: true

                });
              }
            });
        }

      }
    } catch (err) {
      console.log(err);
      document.getElementById("lblError").innerHTML = "Technical issue, please try after sometime or contact administrator";

    }

  }

  getEmployeeByEmployeeID = async (empID, BearerToken) => {
    var employee = null;
    try {
      const apiURL = process.env.REACT_APP_ESF_EMPDETAILS.replace('{employeeId}', empID);
      let resEmployee = await fetch(apiURL, {
        method: "GET",
        crossDomain: true,
        headers: {
          authorization: BearerToken,
          accept: "application/json",
          "cache-control": "no-cache",
        },
      });
      let respJson = await resEmployee.json();
      if (respJson && respJson.employees && respJson.employees.length > 0) {
        employee = {
          JobDescription: respJson.employees[0].JobDescription,
          CompanyID: respJson.employees[0].CompanyID,
          EmailPreferred: respJson.employees[0].EmailPreferred,
          EmployeeClass: respJson.employees[0].EmployeeClass,
          EmployeeID: respJson.employees[0].EmployeeID,
          HireDate: respJson.employees[0].HireDate,
          HomeState: respJson.employees[0].HomeState,
          Name: respJson.employees[0].Name,
          SupervisorID: respJson.employees[0].SupervisorID,
          DepartmentDescription: respJson.employees[0].DepartmentDescription,
          businessTitle: respJson.employees[0].JobDescription,
          empBusinessUnit: respJson.employees[0].ProductID,
          empClass: respJson.employees[0].EmployeeClass,
          empID: respJson.employees[0].EmployeeID,
          empId: respJson.employees[0].EmployeeID,
          empPosition: "",//respJson.employees[0].,
          empProgram: `${respJson.employees[0].DepartmentDescription} - ${respJson.employees[0].DepartmentID}`,
          empSupervisor: respJson.employees[0].SupervisorID,
          isSupervisor: respJson.employees[0].IsSupervisor,
          subordinates: null,
        };
      }
      console.log(respJson);
    }
    catch (err) {
      console.log(err);
    }
    return employee;
  }

  getFormValidationStatus = () => {
    var isSubmitFormValid = false, isUpdateFormValid = false;
    /* Mendatory for insert and update both */
    if (this.state.SelectedEmployee === undefined || this.state.SelectedEmployee === null || this.state.SelectedEmployee.length <= 0
      || this.state.SelectedSuperVisor === undefined || this.state.SelectedSuperVisor === null || this.state.SelectedSuperVisor === ""
      || this.state.EffectiveDate === undefined || this.state.EffectiveDate === null || this.state.EffectiveDate === ""
    ) {
      isSubmitFormValid = false;
    } else {
      isSubmitFormValid = true;
    }
    /* Mendatory for Status update only */
    if (this.state.requestId) {
      /* ESG */
      if (this.state.esgApproval === this.RequestStatus.PendingESG || this.state.esgApproval === "Pending Review") {
        isUpdateFormValid = true;
      }
      else if (
        this.state.esgAssignedTo && this.state.esgAssignedTo !== null && this.state.esgAssignedTo !== undefined && this.state.esgAssignedTo !== ""
        && this.state.esgApproval && this.state.esgApproval !== null && this.state.esgApproval !== undefined && this.state.esgApproval !== ""
        //&& this.state.esgApproval !== "Pending Review" && this.state.esgApproval !== "Pending ESG"
      ) {
        isUpdateFormValid = true;
      }
      /* Payroll */
      if (this.state.payrollApproval === this.RequestStatus.PendingPayroll || this.state.payrollApproval === "Pending Review") {
        isUpdateFormValid = true;
      }
      else if (
        this.state.payrollAssignedToName !== null && this.state.payrollAssignedToName !== undefined && this.state.payrollAssignedToName !== ""
        && this.state.payrollApproval !== null && this.state.payrollApproval !== undefined && this.state.payrollApproval !== ""
        //&& this.state.payrollApproval !== "Pending Review" && this.state.payrollApproval !== "Pending Payroll"
      ) {
        isUpdateFormValid = true;
      }

    }
    this.setState({ isSubmitFormValid: isSubmitFormValid, isUpdateFormValid: isUpdateFormValid })
  }

  /*handle events*/
  handleEmployeeSelected = (employee) => {
    this.setState({
      SelectedEmployee: employee,
    }, () => this.getFormValidationStatus());
  };

  handleCompanyCountryChange(e) {

    this.setState({
      SelectedSuperVisorCompanyID: this.EmployeeCompanyLocations.filter(item => item.text === e.target.value)?.[0]?.key,
      SelectedSuperVisorCountry: e.target.value
    })

  }

  handleEmployeeSelectedSuperVisor = (employee) => {
    this.setState({
      SelectedSuperVisor: employee,
    }, () => this.getFormValidationStatus());
  };

  handleSetSearchQuery = async (val) => {
    this.setState({ searchQuery: val });
    if (val !== undefined && val !== null && val.length > 2) {

      try {
        this.setState({ isEmployeeLoading: true });
        const controller = new AbortController();
        const { signal } = controller;
        //this.getNewSupervisors(val, this.state.SelectedSuperVisorCompanyID);
        const empResponse = await getEmployeesFromSearch(val, this.state.SelectedSuperVisorCompanyID, signal);
        if (empResponse) {
          console.log(empResponse);
          this.setState({ EmployeesNewSupervisors: empResponse });
        }
        this.setState({ isEmployeeLoading: false });
      } catch (err) {
        console.log(err);
        this.setState({ isEmployeeLoading: false });
      }

    }

  }

  handleFieldValueChange = (e) => {
    switch (e.target.name) {

      case "effectiveDate":
        if (e.target.value === "")
          this.setState({ EffectiveDate: null }, () => this.getFormValidationStatus());
        else
          this.setState({ EffectiveDate: moment(e.target.value).format("M/D/YYYY") }, () => this.getFormValidationStatus());
        break;
      case "submitterPhone":
        this.setState({ PhoneNo: e.target.value.trim() }, () => this.getFormValidationStatus());
        break;
      case "additionalComments":
        this.setState({ AdditionalComments: e.target.value }, () => this.getFormValidationStatus());
        break;
      case "esgassignto":
        var email = e.detail.length > 0 ? (e.detail[0].mail ? e.detail[0].mail : e.detail[0].userPrincipalName) : undefined;
        if (!email) {
          this.setState({ esgAssignedToEmailDisplay: undefined }, () => this.getFormValidationStatus())
        }
        this.setState({
          esgAssignedTo: e.detail.length > 0 ? (e.detail[0].mail ? e.detail[0].mail : e.detail[0].userPrincipalName) : undefined,
          esgAssignedToEmail: e.detail.length > 0 ? (e.detail[0].mail ? e.detail[0].mail : e.detail[0].userPrincipalName) : "",
          esgAssignedToName: e.detail.length > 0 ? (e.detail[0].displayName ? e.detail[0].displayName : "") : undefined,
        }, () => this.getFormValidationStatus());
        break;
      case "esgapprovalstatus":
        this.setState({ esgApproval: e.target.value }, () => this.getFormValidationStatus());
        break;
      case "esgaddcomm":
        this.setState({ esgComments: e.target.value }, () => this.getFormValidationStatus());
        break;
      case "payrollassignto":
        var email = e.detail.length > 0 ? (e.detail[0].mail ? e.detail[0].mail : e.detail[0].userPrincipalName) : undefined;
        if (!email) {
          this.setState({ payrollAssignedToEmailDisplay: undefined }, () => this.getFormValidationStatus())
        }
        this.setState({
          payrollAssignedToEmail: e.detail.length > 0 ? (e.detail[0].mail ? e.detail[0].mail : e.detail[0].userPrincipalName) : undefined,
          payrollAssignedToName: e.detail.length > 0 ? (e.detail[0].displayName ? e.detail[0].displayName : "") : undefined,
        }, () => this.getFormValidationStatus());
        break;
      case "payrollapprovalstatus":
        this.setState({ payrollApproval: e.target.value }, () => this.getFormValidationStatus());
        break;
      case "payrolladdcomm":
        this.setState({ payrollComments: e.target.value }, () => this.getFormValidationStatus());
        break;
    }

  }

  isValidForm = () => {
    var errorMsg = "";
    if (this.state.SelectedEmployee === undefined || this.state.SelectedEmployee === null || this.state.SelectedEmployee.length <= 0) {
      errorMsg = errorMsg + "Please select employee <br/>";
    }
    if (this.state.SelectedSuperVisor === undefined || this.state.SelectedSuperVisor === null || this.state.SelectedSuperVisor === "") {
      errorMsg = errorMsg + "Please select new supervisor <br/>";
    }
    if (this.state.EffectiveDate === undefined || this.state.EffectiveDate === null || this.state.EffectiveDate === "") {
      errorMsg = errorMsg + "Please select effective date <br/>";
    }
    document.getElementById("lblError").innerHTML = errorMsg;


    if (errorMsg !== "") {
      this.setState({ validationErros: errorMsg, isSubmitFormValid: false });
      return false;
    }
    else {
      this.setState({ validationErros: errorMsg, isSubmitFormValid: true });
      return true;
    }
  }

  getImpersonated = (e) => {
    if (e !== null && e.detail !== null && e.detail.length > 0 && e.detail[0].userPrincipalName !== null &&
      (this.state.requestId == '' || this.state.requestId == undefined || this.state.requestId == null)) {
      this.setState({ loading: true });
      getImpersonatedUserRoles(e.detail[0].userPrincipalName).then(() => {
        var temp = localStorage.getItem("LoggedInAsUserAccess");
        if (temp) {
          temp = JSON.parse(temp);
          console.log(temp);
          this.setState({
            loggedInAs: { EmpId: temp.employeeID[0].employeeId, DisplayName: e.detail[0].displayName, Email: e.detail[0].userPrincipalName, LoginAsError: false },
            loading: false
          }, () => { this.componentDidMount() });
        } else {
          this.setState({ LoginAsError: true });
          localStorage.removeItem("LoggedInAsUserAccess");
          this.setState({
            loggedInAs: { EmpId: '', DisplayName: '', Email: '' },
            loading: false
          })
        }


      });

    } else if (!this.state.requestId) {
      localStorage.removeItem("LoggedInAsUserAccess");
      this.setState({
        loggedInAs: { EmpId: '', DisplayName: '', Email: '' },
        loading: false
      })
    }


  }

  clearImpersonation = (e) => {
    localStorage.removeItem("LoggedInAsUserAccess");
    this.setState({
      loggedInAs: { EmpId: '', DisplayName: '', Email: '', },
      Employees: [],
      selectedEmployee: null
    }, () => { this.componentDidMount() });

  }

  render() {
    var effectiveDat = this.state.EffectiveDate ? moment(this.state.EffectiveDate).format("YYYY-MM-DD") : null;
    var isESGEnabled = false, isPayRollHREnabled = false, showUpdateButton = false;
    var approvalFor = null;
    /* ESG */
    var checkUserRole = undefined;
    if (this.state.SelectedEmployee && this.state.SelectedEmployee.length > 0 && this.state.SelectedEmployee[0].empClass && this.state.SelectedEmployee[0].empClass.trim().toLowerCase() === "int") {
      checkUserRole = userRoles.esgInternal;
    }
    else {
      checkUserRole = userRoles.esgBillable;
    }
    if (this.state.requestStatus === this.RequestStatus.PendingESG && this.roles.length > 0 && this.roles[0].name && (this.roles.some(role => (role.name === checkUserRole)) || this.LoggedInUser.IsAdminRole)) {
      isESGEnabled = true;
      approvalFor = "esg";
      showUpdateButton = true;
    }
    /* Payroll Canada */
    if (this.state.requestStatus === this.RequestStatus.PendingPayroll && this.roles.length > 0 && this.roles[0].name && (this.roles.some(role => (role.name === userRoles.payrollCanada)) || this.LoggedInUser.IsAdminRole)) {
      isPayRollHREnabled = true;
      approvalFor = "payroll";
      showUpdateButton = true;
    }
    return (
      <main>
        <Title title={this.props.title} color={"#021a32"} />
        <Note note={this.props.note} />
        {this.state.isCancelVisible &&
          <>
            <br></br>
            {this.state.isNavigateToApprovalQueue && (<Navigate to={`/approval-queue/`} replace={true}></Navigate>)}
            <SubmitButton label="Cancel This Form" onClick={this.onClickCancel} color={"#A51D25"} />
          </>
        }
        {(this.state.isESFOwner || (this.state.loggedInAs.EmpId)) && (
          <LoginAs
            onChange={(e) => this.getImpersonated(e)}
            clearLoginAs={(e) => this.clearImpersonation(e)}
            showError={this.state.LoginAsError}
            empId={this.state.loggedInAs.EmpId}
            displayName={this.state.loggedInAs.DisplayName}
            email={this.state.loggedInAs.Email}
            requestId={this.state.requestId}
            loading={this.state.loading}
          //defaultSelectedUser={this.state.loggedInAs.DisplayEmail}
          //loginAsThisUser={() => this.loginAsOtherUser()}

          ></LoginAs>)}
        <label name="lblError" id="lblError"></label>
        <section>
          <EmployeeSearch
            employees={this.state.Employees}
            selectedEmployees={this.state.SelectedEmployee}
            setSelectedEmployees={this.handleEmployeeSelected}
            multiSelect={true}
            disabled={this.state.requestId ? true : false}
          />
          <EsfInput
            label="Employee's Location"
            name="employeeCompany"
            type="select"
            value={this.state.SelectedSuperVisorCountry ? this.state.SelectedSuperVisorCountry : ""}
            options={this.EmployeeCompanyLocations}
            onChange={(e) => this.handleCompanyCountryChange(e)}
            required
            disabled={this.state.requestId ? true : false}
          />
          {this.state.SelectedSuperVisorCountry && this.state.SelectedSuperVisorCountry !== 'Choose a Location' &&
            (<>
              <EmployeeSearch
                employees={this.state.EmployeesNewSupervisors}
                selectedEmployee={this.state.SelectedSuperVisor}
                setSelectedEmployee={this.handleEmployeeSelectedSuperVisor}

                searchQuery={this.state.searchQuery}
                setSearchQuery={this.handleSetSearchQuery}
                loading={this.state.isEmployeeLoading}
                supervisor
              />{!this.state.SelectedSuperVisor && <label className="labelNormal">{employeeSearchText.message}</label>}
            </>)
          }


          <EsfInput
            type="date"
            name="effectiveDate"
            label="Effective Date"
            alert="Sundays only"
            required
            value={effectiveDat}
            onChange={(e) => this.handleFieldValueChange(e)}
          />
          <EsfInput
            label="Your Phone Number:"
            name="submitterPhone"
            type="text"
            value={this.state.PhoneNo}
            onChange={this.handleFieldValueChange}
          />
          <EsfInput
            placeHolder="Add any additional comments..."
            name="additionalComments"
            type="textarea"
            value={this.state.AdditionalComments}
            onChange={this.handleFieldValueChange}
          />
          {!(this.state.requestId) && (<SubmitButton
            onClick={(this.state.isSubmitFormValid && this.state.Employees && this.state.Employees.length > 0) ? () => this.handleSubmitToSave() : null}
            label={(this.state.Employees && this.state.Employees.length > 0) ?
              (this.state.isSubmitFormValid ?
                "Submit" :
                "Please complete all required fields."
              ) : "No direct reports returned."
            }
            color={(this.state.Employees && this.state.Employees.length <= 0) ? "red" : ""}
          />)}

          {this.state.goToView && (<Navigate to={`/supervisor-change/view/${this.state.requestId}`} replace={true}></Navigate>)}

          {this.state.esgApproval && (<div>
            <Approval approvalFor="supesg" isDisabled={!isESGEnabled} assignToLabel="Assigned To" assignToName="esgassignto" assignToValue={this.state.esgAssignedToEmailDisplay} assignToOnChange={(e) => this.handleFieldValueChange(e)}
              statusName="esgapprovalstatus" statusLabel="Approval" statusValue={this.state.esgApproval} statusOnChange={(e) => this.handleFieldValueChange(e)}
              additionalCommName="esgaddcomm" additionalCommValue={this.state.esgComments} additionalCommOnChange={(e) => this.handleFieldValueChange(e)}
            ></Approval></div>)}

          {this.state.payrollApproval && (<div>
            <Approval approvalFor="subpayroll" isDisabled={!isPayRollHREnabled} assignToLabel="Assigned To" assignToName="payrollassignto" assignToValue={this.state.payrollAssignedToEmailDisplay} assignToOnChange={(e) => this.handleFieldValueChange(e)}
              statusName="payrollapprovalstatus" statusLabel="Approval" statusValue={this.state.payrollApproval} statusOnChange={(e) => this.handleFieldValueChange(e)}
              additionalCommName="payrolladdcomm" additionalCommValue={this.state.payrollComments} additionalCommOnChange={(e) => this.handleFieldValueChange(e)}
            ></Approval></div>)}

          {(showUpdateButton && this.state.requestStatus.indexOf("Complete") < 0 && this.state.requestStatus.indexOf("Cancel") < 0) && (<SubmitButton
            onClick={(this.state.isSubmitFormValid && this.state.isUpdateFormValid) ? () => this.handleSubmitToUpdate(approvalFor) : null}
            label={(this.state.isSubmitFormValid && this.state.isUpdateFormValid) ? "Submit" : "Please complete all required fields."}

          />)}

          <br />
          <br />
          {this.state.gridRows.length > 0 &&
            <div className="outerDiv">
              <div>
                <p>{workflowHistory.title}</p>
                <Grid
                  rowData={this.state.gridRows}
                  columnDefs={this.state.gridColumns}
                />
              </div>
            </div>
          }

        </section>
      </main>
    );

  }
}

export default SupervisorChange;

