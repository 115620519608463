import React from "react";
import User from "../../components/User";
import { ClipLoader } from "react-spinners";
import { MdEmail } from "react-icons/md";
import { AiOutlineBarcode } from "react-icons/ai";
const LoginAs = ({ onChange, clearLoginAs, showError, empId, displayName, email, requestId, loading }) => {

    return (

        <div className="employee-search-container" style={{ marginTop: "5px" }}>
            {showError && (
                <span style={{ padding: "20px", color: 'red' }}>Plesae Refresh the page or relogin</span>)
            }
            {(!showError && !empId && !displayName && !email) && (

                <div style={{ width: "100%" }}>
                    <div style={{ width: "80%", display: "inline-block" }}>
                        < User
                            label="Submitted on Behalf of"
                            name="Submitted on Behalf of"
                            required={false}
                            onChange={onChange}
                            disabled={false}
                        // defaultSelectedUser={[defaultSelectedUser]}
                        ></User >
                    </div>
                    <div style={{ width: "20%", display: "inline-block" }}>
                        {loading && <ClipLoader color="#3f51b5" />}
                    </div>

                </div >

            )
            }
            {//&& requestId
                (empId && displayName && email) && (

                    <div style={{ width: "100%" }}>
                        <div className="employee-search-header">Submitted on Behalf of</div>
                        <div className="details-row">
                            <span className="blue">{displayName}</span>
                            <AiOutlineBarcode /><span>{empId}</span>
                            {email && (
                                <div>
                                    <MdEmail />
                                    <a
                                        className="blue"
                                        target="_blank"
                                        rel="noreferrer"
                                        href={`mailto:$email}`}
                                    >
                                        {email}
                                    </a>
                                </div>
                            )}
                            {!requestId && (<button onClick={clearLoginAs} className="loginLogOut" >Logout</button>)}
                        </div>
                    </div>



                )
            }

        </div >


    )
}
export default LoginAs;