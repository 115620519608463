import React, { useEffect } from "react";
import "./styles.css";
import { NavLink } from "react-router-dom";

const Tabs = ({ data, tabHeaders, userRole }) => {
  useEffect(() => {
    console.log("data", data);
    console.log("tabHeaders", tabHeaders);
    console.log("userRole", userRole);
  }, []);

  return (
    <div>
      <div className="tabs-wrapper">
        {tabHeaders.map((tab, index) => (
          <NavLink
            key={index}
            to={`/approval-queue/${userRole}/${tab.name}`}
            activeclassname="active"
            className="tab"
          >
            {tab.title}
          </NavLink>
        ))}
      </div>
      <div className="tab-content">{data.content}</div>
    </div>
  );
};

export default Tabs;
